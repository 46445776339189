import React, { useState, useEffect } from "react";

const CountDownComponent: React.FC<{
    resend: boolean;
    setResend: React.Dispatch<React.SetStateAction<boolean>>;
    otpReset?: boolean;
}> = ({ resend, setResend, otpReset }) => {
    const countdownTime = 180; // Set the countdown time to 3 minutes (180 seconds)
    const [timeLeft, setTimeLeft] = useState(countdownTime);

    let worker: Worker;

    useEffect(() => {
        worker = new Worker(new URL("./CountdownWorker.ts", import.meta.url));

        worker.addEventListener("message", (event: MessageEvent) => {
            if (event.data === "tick") {
                setTimeLeft((prevTime) => {
                    if (prevTime > 0) {
                        return prevTime - 1;
                    } else {
                        setResend(true);
                        worker.postMessage("stop");
                        return 0;
                    }
                });
            }
        });

        if (!resend) {
            setTimeLeft(countdownTime);
            worker.postMessage("start");
        }

        return () => {
            worker.terminate();
        };
    }, [resend, setResend, countdownTime, otpReset]);

    const formatTime = (timeInSeconds: number): string => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    };

    return (
        <div>
            {resend ? (
                <div className="text-error font-semibold">Time Has Expired</div>
            ) : (
                <div className="text-primary font-semibold">{formatTime(timeLeft)}</div>
            )}
        </div>
    );
};

export default CountDownComponent;

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PARSE_CONTEXT } from "../provider/Provider";
import { ChildrenProps } from "../type/Types";

function RequireAuth({ children }: ChildrenProps) {
    const parseContext = useContext(PARSE_CONTEXT);
    const { Parse } = parseContext || {};
    const currentUser = Parse.User.current()?.attributes?.sessionToken;
    const location = useLocation();

    if (!currentUser) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return <>{children}</>;
    }
}

export default RequireAuth;

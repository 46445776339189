import React, { useContext, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { PARSE_CONTEXT } from "../../provider/Provider";
import Loading from "../../components/Common/Loading/Loading";
import CandidateInformation from "../../components/Common/CandidateInformation/CandidateInformation";
import RefereeQuestionForm from "../../components/Referee/RefereeQuestionForm";
import { BiArrowBack } from "react-icons/bi";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import { RefereeType } from "../../type/RefereeType";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import ErrorPage from "../ErrorPage/ErrorPage";

interface RefereeQuestions {
    totalCount: number;
    result: RefereeType;
}

const RefereeQuestionPage = () => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const adminTurnedOn = adminModeData?.adminMode;

    const { id } = useParams();

    const [data, loading, refetch] = useFetch({
        Parse: Parse,
        dataClass: "Referee",
        id: id
    });

    if (loading) {
        return <Loading />;
    }
    const { email } = userDataManage.user.attributes;
    const { result } = data as RefereeQuestions;

    const {
        requestorFirstname,
        requestorLastname,
        requestorEmail,
        requestorPhoneNumber,
        candidateFirstname,
        candidateLastname,
        status,
        response
    } = result.attributes;

    if (
        (isAdmin && adminTurnedOn) ||
        (result.attributes.refereeEmail === email && result.attributes.status === "pending")
    ) {
        return (
            <div>
                <div className=" rounded dark:bg-dark p-0">
                    {result ? (
                        <div className="p-4  lg:px-6">
                            <div className="relative flex flex-col lg:flex-row lg:items-center justify-center gap-6">
                                <Link
                                    to="/referee"
                                    className="lg:absolute left-2 flex  gap-x-2 items-center text-lg font-semibold"
                                >
                                    <BiArrowBack />
                                    <span>Back</span>
                                </Link>

                                <div className="lg:text-center">
                                    <h1 className="font-medium text-xl pb-2">Provide Reference.</h1>
                                    <p>Please fill up the form and hit submit.</p>
                                </div>
                            </div>

                            <>
                                <CandidateInformation
                                    requestorName={requestorFirstname + " " + requestorLastname}
                                    requestorEmail={requestorEmail}
                                    requestorPhone={requestorPhoneNumber}
                                    candidateName={candidateFirstname + " " + candidateLastname}
                                    status={status}
                                />
                                <RefereeQuestionForm formRef={formRef} data={result} response={response} />
                            </>
                        </div>
                    ) : (
                        <div className="p-4">
                            <Link to="/give" className=" flex  gap-x-2 items-center text-lg font-semibold">
                                <BiArrowBack />
                                <span>Back</span>
                            </Link>
                        </div>
                    )}
                </div>
                {Array.isArray(data) && data.length === 0 && <RetryModal refetch={refetch} loading={loading} />}
            </div>
        );
    } else {
        return <ErrorPage />;
    }
};

export default RefereeQuestionPage;

import React, { useContext, useRef } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { updateData } from "../../../hooks/update";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import CustomSelect from "../../Common/CustomSelect/CustomSelect";
import CountryDropdown from "../../Common/CountryDropdown/CountryDropdown";
import { IoCloseOutline } from "react-icons/io5";

const UpdateReferenceModal = ({ refetch, item, questionData }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { candidateFirstname, candidateLastname, candidateEmail, candidatePhoneNumber, minRefereeCount } =
        item.attributes;
    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>(`[for="${item.id + "update"}"]`);
        if (label) {
            label.click();
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = formRef.current;
        if (form) {
            const data = {
                candidateFirstname: form.candidateFirstname.value,
                candidateLastname: form.candidateLastname.value,
                candidateEmail: form.candidateEmail.value,
                candidatePhoneNumber: form.candidatePhoneNumber.value,
                minRefereeCount: parseInt(form.minRefereeCount.value, 10),
                questionTemplate: questionData.find((item: any) => item.id === form.questionTemplates.value).attributes
                    .questionTemplates
            };
            updateData({
                Parse: Parse,
                parseItem: item,
                data: data,
                message: "Reference Requested Updated!",
                refetch: refetch,
                form: form,
                closeModal: closeModal
            });
        }
    };
    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.id + "update"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box rounded dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>
                    <div>
                        <h1 className="font-medium text-xl pb-2">Update Reference.</h1>
                        <p>Please fill up the form and hit submit.</p>
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4 ">
                            <CustomInputField
                                name="candidateFirstname"
                                title="Candidate First Name"
                                type="text"
                                value={candidateFirstname}
                                required
                            />
                            <CustomInputField
                                name="candidateLastname"
                                title="Candidate Last Name"
                                type="text"
                                value={candidateLastname}
                                required
                            />
                            <CustomInputField
                                name="candidateEmail"
                                title="Candidate Email Address"
                                type="email"
                                value={candidateEmail}
                                required
                            />

                            <CountryDropdown
                                title="Candidate Phone Number"
                                name="candidatePhoneNumber"
                                phoneNumber={candidatePhoneNumber}
                                required
                                mini
                            />
                            <CustomInputField
                                name="minRefereeCount"
                                title="Minimum Referee Count"
                                type="number"
                                value={minRefereeCount}
                                min={1}
                                max={5}
                                required
                            />
                            <CustomSelect name={"questionTemplates"} title="Question Template" data={questionData} />
                        </div>

                        <div className="flex justify-end items-center gap-4">
                            <button type="submit" className="btn bg-primary text-white hover:bg-success border-0">
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UpdateReferenceModal;

import React from "react";
import image from "../../../assets/images/header/person.jpg";
import { ProfileComponent } from "../Table/UtilityComponent";
import { statusColorCheck } from "../../../utils/statusColor";

const CandidateInformation = ({
    requestorName,
    requestorEmail,
    requestorPhone,
    candidateName,
    candidateEmail,
    candidatePhone,
    refereeCount,
    status
}: {
    requestorName: string;
    requestorEmail: string;
    requestorPhone: string;
    candidateName: string;
    candidateEmail?: string;
    candidatePhone?: string;
    refereeCount?: number;
    status: string;
}) => {
    return (
        <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 rounded-lg border my-8 gap-4">
            <div className="">
                <h1 className="font-semibold pb-3">Requestor Information</h1>
                <ProfileComponent image={image} name={requestorName} email={requestorEmail} phone={requestorPhone} />
            </div>

            <div className="">
                <h1 className="font-semibold pb-3">Candidate Information</h1>
                <ProfileComponent image={image} name={candidateName} email={candidateEmail} phone={candidatePhone} />
            </div>

            {refereeCount && (
                <div>
                    <div className="w-fit">
                        <h1 className="font-semibold pb-3">Minimum Referee</h1>
                        <p className="text-center font-bold">{refereeCount}</p>
                    </div>
                </div>
            )}
            <div className="w-[8.5rem]">
                <h1 className="font-semibold pb-3 "> Status</h1>
                <div className="">
                    <h2
                        className={`${statusColorCheck(
                            status
                        )} px-2 py-1 rounded capitalize text-white font-semibold w-fit `}
                    >
                        {status}
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default CandidateInformation;

import React from "react";
import showdown from "showdown";
const CoverLetter = ({ coverLetter }: { coverLetter: string }) => {
    const converter = new showdown.Converter();
    const morphedData = converter.makeHtml(coverLetter);
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: converter.makeHtml(morphedData)
            }}
        />
    );
};

export default CoverLetter;

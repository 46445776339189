import React, { useContext, ReactNode } from "react";
import { DARKMODE_CONTEXT } from "../provider/DarkModeProvider";
import useFetchWebsite from "../hooks/useFetchWebsite";
import Loading from "../components/Common/Loading/Loading";

import { testimonialData } from "../content/testimonialData";
import { brandsData } from "../content/brandData";
import Toggler from "../components/Toggler/Toggler";
import Marketing from "../components/LoginRegister/Marketing";

interface LayoutProps {
    children: ReactNode;
}

const MarketingLayout: React.FC<LayoutProps> = ({ children }) => {
    const { darkMode, setDarkMode } = useContext(DARKMODE_CONTEXT) || {};

    const [testimonials, testimonialsLoading] = useFetchWebsite({
        endPoint: "testimonials",
        page: 1,
        limit: 5,
        defaultValue: testimonialData
    });
    const [brands, brandsLoading] = useFetchWebsite({
        endPoint: "brands",
        page: 1,
        limit: 10,
        defaultValue: brandsData
    });
    if (testimonialsLoading || brandsLoading) {
        return <Loading full />;
    }
    const toggleDarkMode = () => {
        setDarkMode((prevDarkMode: any) => !prevDarkMode);
    };
    return (
        <div className="grid grid-cols-1 lg:grid-cols-5 xl:grid-cols-3  dark:!bg-dark relative">
            <div className="col-span-3 xl:col-span-2  hidden lg:block w-full min-h-screen ">
                <Marketing testimonials={testimonials} brands={brands} />
            </div>
            <div className="col-span-2 xl:col-span-1 relative mx-auto bg-primary/10 signInBG w-full min-h-screen">
                <div className="absolute top-1 right-1 lg:top-4 lg:right-4 flex justify-end p-2 lg:p-4 z-[999]">
                    <Toggler darkMode={darkMode} handleSwitch={toggleDarkMode} />
                </div>
                <div className="w-full h-full ">
                    <div className="h-full flex items-center justify-center flex-col w-full px-6 bg-white/80 dark:bg-darkBG/95">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketingLayout;

import React, { useEffect } from "react";
interface TimerState {
    elapsedTime: number;
    setElapsedTime: React.Dispatch<React.SetStateAction<number>>;
    isTimerRunning: boolean;
}

const RecordingTimer = ({ elapsedTime, setElapsedTime, isTimerRunning }: TimerState) => {
    useEffect(() => {
        let timerInterval: NodeJS.Timeout | null = null;

        if (isTimerRunning) {
            timerInterval = setInterval(() => {
                setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
            }, 1000);
        }

        return () => {
            if (timerInterval) {
                clearInterval(timerInterval);
            }
        };
    }, [isTimerRunning]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
    };

    return (
        <div>
            <h1> {formatTime(elapsedTime)}</h1>
        </div>
    );
};

export default RecordingTimer;

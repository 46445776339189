import React from "react";
import { TbProgress } from "react-icons/tb";
import { BsCalendar2Check } from "react-icons/bs";
import { dateFormatter } from "../../../utils/dateFormat";
import { IoCloseOutline } from "react-icons/io5";

const SupportViewModal = ({ item }: any) => {
    const { issueSubject, issueMessage, updatedAt, createdAt, status } = item.attributes;

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.id + "view"} className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box rounded dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <label htmlFor={item.id + "view"} className="px-2 py-1 block cursor-pointer">
                            <IoCloseOutline />
                        </label>
                    </div>
                    <div>
                        <h1 className="font-medium text-xl pb-2">Reference #{item?.id}</h1>
                    </div>
                    <div className="flex gap-x-2">
                        <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full px-4 capitalize dark:text-black">
                            <TbProgress /> {status}
                        </div>
                        <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full px-4 dark:text-black">
                            <span className="text-lg">
                                <BsCalendar2Check />
                            </span>
                            {dateFormatter(createdAt)}
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-4 pt-6">
                        <div>
                            <h1 className="font-bold">Subject</h1>
                            <h1>{issueSubject}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Description</h1>
                            <p className="break-all">{issueMessage}</p>
                        </div>

                        <div>
                            <h1 className="font-bold">Last Updated </h1>

                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(updatedAt)}
                            </div>
                        </div>
                        <div>
                            <h1 className="font-bold">Created At </h1>

                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(createdAt)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupportViewModal;

import React from "react";
import { FaWandMagicSparkles } from "react-icons/fa6";
import { GrRevert } from "react-icons/gr";
import ReactQuill from "react-quill";

const TextEditor = ({
    textEditor,
    setTextEditor,
    title,
    magicButton,
    handleMagicButton,
    handleRevert,
    error,
    disabled
}: {
    textEditor: string;
    setTextEditor: any;
    title: string;
    magicButton?: boolean;
    handleMagicButton?: any;
    handleRevert?: any;
    error?: boolean;
    disabled?: boolean;
}) => {
    const modules = {
        toolbar: [[{ size: [] }], ["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]]
    };
    return (
        <div className="flex flex-col gap-y-2 min-h-full relative">
            {error && (textEditor.length === 0 || textEditor === "<p><br></p>") && (
                <div className="w-full flex justify-center absolute -bottom-[3.4rem] z-20">
                    <div className="bg-white dark:border-primaryBG/8 p-2 shadow-md rounded w-fit relative">
                        <div className="w-5 h-5 bg-white rotate-45 absolute -top-2 left-4 -z-10 rounded" />
                        <p className="text-sm  flex items-center gap-x-2 dark:text-black z-10 ">
                            <span className="bg-[#FF8C00] text-white flex justify-center items-center font-bold w-6 h-6 rounded">
                                !
                            </span>{" "}
                            Please Fill out this field.
                        </p>
                    </div>
                </div>
            )}
            <div className="flex gap-x-2 items-center">
                <h1 className="capitalize">{title}</h1>
                {magicButton && (
                    <div className="tooltip" data-tip="Magic Wand">
                        <button
                            type="button"
                            onClick={handleMagicButton}
                            className=" px-4 py-3 rounded-md bg-[#E5E7EB] dark:bg-dark "
                        >
                            <FaWandMagicSparkles />
                        </button>
                    </div>
                )}
                {handleRevert && (
                    <div className="tooltip" data-tip="Reverse Changes">
                        <button
                            type="button"
                            onClick={handleRevert}
                            className=" px-4 py-3 rounded-md dark:bg-dark bg-[#E5E7EB] "
                        >
                            <GrRevert />
                        </button>
                    </div>
                )}
            </div>
            <div
                className={`textEditor  textEditorLight dark:textEditorDark col-span-2 w-full overflow-hidden rounded-md  bg-inherit dark:border-primaryBG/80  border-secondary/50 border-2 h-full ${disabled && "cursor-not-allowed opacity-50"}`}
            >
                <ReactQuill
                    modules={modules}
                    theme="snow"
                    value={textEditor}
                    readOnly={disabled}
                    onChange={setTextEditor}
                    className="h-full"
                />
            </div>
        </div>
    );
};

export default TextEditor;

import React from "react";
import { FaRegImage } from "react-icons/fa6";

const PhotoUploader = ({ handleLabelClick, handleFileChange, fileInputRef, photoUrl }: any) => {
    return (
        <div className="flex flex-col  gap-y-4 justify-center items-center rounded-lg bg-inherit  w-full md:col-span-2 border-2 dark:border-primaryBG/80  border-secondary/50 overflow-hidden">
            <div className="flex justify-center items-center overflow-hidden w-full h-40 relative rounded-md ">
                {photoUrl ? (
                    <img
                        className=" w-full h-40 flex justify-center items-center  object-contain font-bold relative overflow-hidden  object-center"
                        src={photoUrl}
                    ></img>
                ) : (
                    <div className=" w-full h-40 flex justify-center items-center  object-cover font-bold relative overflow-hidden  object-center text-7xl text-black/50 dark:text-white/50">
                        <FaRegImage />
                    </div>
                )}

                <span
                    className="text-base text-black absolute bottom-0 capitalize bg-gray-200/80 dark:bg-dark/95 dark:text-white w-full text-center h-20 pt-5 duration-300 cursor-pointer opacity-0 hover:opacity-100 font-bold "
                    onClick={handleLabelClick}
                >
                    upload image
                </span>
                <label className="cursor-pointer bg-error">
                    <input
                        type="file"
                        accept="image/*,.svg"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                </label>
            </div>
        </div>
    );
};

export default PhotoUploader;

import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import { Link, useNavigate } from "react-router-dom";

import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { login } from "../../hooks/login";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";

const Login = ({ setLogin, isLogin }: any) => {
    const [loading, setLoading] = useState(false);
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement | null>(null);

    interface FormData {
        // Define your form data fields here
        username: string;
        password: string;
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const fingerPrintData = await getUserFingerPrint();
        if (formRef.current) {
            const data: FormData = {
                username: formRef.current.username.value,
                password: formRef.current.password.value
            };

            login({
                Parse: Parse, // Make sure Parse is defined or imported
                data: data,
                refetch: userDataManage.userRefetch,
                fingerPrintData: fingerPrintData,
                navigate: navigate,
                loading: setLoading
            });
        }
    };

    return (
        <div className=" text-white relative">
            <HelmetConfiguration seoData={SeoContent["loginSeo"] || SeoContent.baseSeo} />
            <div className="w-[20rem] sm:w-[22rem] 2xl:w-[25rem] mx-auto  flex flex-col justify-center gap-y-4">
                <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-y-4  pt-8">
                    <CustomInputField name="username" title="Email Address" type="text" required />
                    <CustomInputField name="password" title="Password" type="password" required min={8} />
                    <Link to="/forgot-password" className="font-medium text-sm  text-start w-full  text-primary">
                        forgot password?
                    </Link>
                    <button
                        type="submit"
                        className="bg-primary/90 text-white uppercase font-medium py-4 hover:bg-primary  duration-300 rounded flex items-center justify-center gap-x-2"
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        login
                    </button>

                    <p className="font-medium text-black dark:text-white text-start w-full">
                        don't have an account?{" "}
                        <button onClick={() => setLogin(!isLogin)} type="button" className="text-primary font-semibold">
                            Register
                        </button>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Login;

import { ApexOptions } from "apexcharts";
import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";

const ColumnChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const chartData = {
        series: data.series,
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false
                },
                type: "bar"
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: "top" // top, center, bottom
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val: any) {
                    return val + "%";
                },
                offsetY: -20,

                style: {
                    fontSize: "12px",
                    colors: darkMode ? ["white"] : ["black"]
                }
            },
            xaxis: {
                categories: data.categories,
                position: "top",
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                },
                crosshairs: {
                    fill: {
                        type: "gradient"
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    formatter: function (val: any) {
                        return val + "%";
                    }
                }
            },
            tooltip: {
                style: {
                    color: "black"
                },
                theme: darkMode ? "dark" : "light" // Explicitly set theme for tooltip
            },
            colors: ["#00A76F"]
        } as ApexOptions
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default ColumnChart;

export const testimonialData = [
    {
        id: 1,
        name: "Chaitanya",
        designation: "CTO @ Liquidonate",
        content:
            "This tool has significantly reduced the time and effort required to conduct reference checks, allowing us to streamline our hiring process and focus on other crucial tasks.",
        image: "/images/testimonials/chaitanya.jpg",
        star: 5
    },
    {
        id: 2,
        name: "Wristy",
        designation: "People Leader",
        content:
            "Cutting down hours on manual checks, this tool is the gold standard for modern-day recruiting. Taking the guesswork out of reference checks; this tool is a recruiter's best friend.",
        image: "/images/testimonials/wristy.png",
        star: 5
    },
    {
        id: 3,
        name: "Tianna",
        designation: "Talent Advisor (x-Notion, x-Lyft)",
        content:
            "I am always looking for innovative tools to enhance recruitment process, and this tool powered by machine learning has exceeded my expectations.",
        image: "/images/testimonials/tianna.jpeg",
        star: 5
    }
];

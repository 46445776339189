import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import { createData } from "../../hooks/create";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import CustomSelect from "../Common/CustomSelect/CustomSelect";
import CountryDropdown from "../Common/CountryDropdown/CountryDropdown";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";
import { IoCloseOutline } from "react-icons/io5";

const EmployerForm = ({ refetch, questionData, setUpload }: any) => {
    const [loading, setLoading] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="askReference"]');
        if (form && label) {
            form.reset();
            label.click();
        }
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        const fingerPrintData = await getUserFingerPrint();
        if (form) {
            const data = {
                requestorUserId: userDataManage.user?.id,
                requestorImage: userDataManage.user.attributes.userImage,
                requestorFirstname: userDataManage.user.attributes.firstname,
                requestorLastname: userDataManage.user.attributes.lastname,
                requestorEmail: userDataManage.user.attributes.email,
                requestorPhoneNumber: userDataManage.user.attributes.phoneNumber,
                candidateFirstname: form.candidateFirstname.value,
                candidateLastname: form.candidateLastname.value,
                candidateEmail: form.candidateEmail.value,
                candidatePhoneNumber: form.candidatePhoneNumber.value,
                minRefereeCount: parseInt(form.minRefereeCount.value, 10),
                questionTemplateId: "U5TYKc3mlb",
                questionTemplate: questionData.find((item: any) => item.id === form.questionTemplates.value).attributes
                    .questionTemplates,
                status: "started",
                fingerPrint: fingerPrintData
            };

            createData({
                Parse: Parse,
                dataClass: "Employer",
                data: data,
                message: "New Reference Requested Created!",
                refetch: refetch,
                closeModal: closeModal,
                loading: setLoading
            });
        }
    };
    return (
        <div>
            <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                <button type="button" onClick={closeModal} className="px-2 pt-1">
                    <IoCloseOutline />
                </button>
            </div>
            <div>
                <h1 className="font-medium text-xl pb-2">Ask for new Reference.</h1>
                <p>
                    Please fill up the form and hit submit or{" "}
                    <button onClick={() => setUpload(true)} className="capitalize text-primary">
                        upload resume
                    </button>
                    .
                </p>
            </div>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="py-6 grid grid-cols-1 lg:grid-cols-2 gap-4 ">
                    <CustomInputField name="candidateFirstname" title="Candidate First Name" type="text" required />
                    <CustomInputField name="candidateLastname" title="Candidate Last Name" type="text" required />
                    <CustomInputField name="candidateEmail" title="Candidate Email Address" type="email" required />
                    <CountryDropdown title="Candidate Phone Number" name="candidatePhoneNumber" required mini />
                    <CustomInputField
                        name="minRefereeCount"
                        title="Minimum Referee Count"
                        type="number"
                        min={1}
                        max={5}
                        value={3}
                        required
                    />
                    <CustomSelect name={"questionTemplates"} title="Question Template" data={questionData} />
                </div>

                <div className="flex justify-end items-center gap-4">
                    <button
                        type="submit"
                        className="btn bg-primary text-white hover:bg-success border-0 flex justify-center items-center gap-x-2"
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EmployerForm;

import React, { useState, useContext } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
const CustomInputField = ({
    name,
    title,
    type,
    required,
    min,
    max,
    value,
    disabled,
    textArea,
    updateData
}: {
    name?: string;
    title: string;
    type: string;
    required?: boolean;
    min?: number;
    max?: number;
    value?: string | number;
    disabled?: boolean;
    textArea?: boolean;
    updateData?: any;
}) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const [hidden, setHidden] = useState(true);
    const handleType = () => {
        setHidden(!hidden);
    };
    const today = new Date().toLocaleDateString("en-CA");

    return (
        <div className={`w-full h-full text-dark dark:text-white ${disabled && "!cursor-not-allowed"}`}>
            <fieldset
                className={`border-2 pl-3 !h-full rounded overflow-hidden relative dark:border-primaryBG/80  border-secondary/50 ${
                    disabled && "!border-primary "
                } `}
            >
                <legend
                    className={`px-2  text-sm font-medium dark:text-white text-secondary "  ${
                        disabled && "text-primary"
                    }`}
                >
                    {title}
                </legend>
                {textArea ? (
                    <textarea
                        rows={4}
                        name={name}
                        className={`w-full outline-none p-2 pb-3 bg-inherit ${disabled && "!cursor-not-allowed"}`}
                        required={required}
                        defaultValue={value}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={hidden ? type : "text"}
                        name={name}
                        className={`w-full outline-none  p-2 pb-3 bg-inherit dark:date-input ${
                            disabled && "!cursor-not-allowed"
                        }`}
                        required={required}
                        min={min}
                        max={max}
                        minLength={min}
                        maxLength={max}
                        defaultValue={value !== undefined && value !== null ? value : type === "date" ? today : ""}
                        disabled={disabled}
                        onChange={value ? (event) => updateData && updateData(event.target.value) : () => {}}
                    />
                )}
                {type === "password" && (
                    <button
                        type="button"
                        onClick={handleType}
                        className={`absolute right-4 top-1 text-2xl ${darkMode ? "text-white" : "text-secondary"} `}
                    >
                        {hidden ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </button>
                )}
            </fieldset>
        </div>
    );
};

export default CustomInputField;

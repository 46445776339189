import { toast } from "react-toastify";

export const RequestEmailVerificationOTP = async ({ Parse, email }: any) => {
    try {
        const result = await Parse.Cloud.run("RequestEmailVerificationOTP", {
            email: email
        });

        if (result.result) {
            toast.success(result.message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        } else {
            toast.error(result.message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    } catch (error: any) {
        toast.error(error, {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    }
};

import React, { useState } from "react";
import CameraRecorder from "./CameraRecorder";

import { MdClose } from "react-icons/md";

interface VideoModalProps {
    handleVideoQuestion: (answer: any) => void;
    responseVideo: any;
    setResponseVideo: any;
}

const VideoModal: React.FC<VideoModalProps> = ({ handleVideoQuestion, responseVideo, setResponseVideo }) => {
    const [cameraOpen, setCameraOpen] = useState(false);
    const [approved, setApproved] = useState(false);
    const closeModal = () => {
        const label = document.getElementById("videoModalClose");
        if (label) {
            setCameraOpen(false);
            label.click();
        }
        if (!approved) {
            setResponseVideo([]);
        }
    };

    return (
        <div>
            <input type="checkbox" id="videoModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl rounded flex justify-center flex-col dark:bg-dark">
                    <button
                        onClick={closeModal}
                        className="text-2xl text-white bg-error  p-1 cursor-pointer absolute top-0 right-0 rounded-bl-md"
                    >
                        <MdClose />
                    </button>

                    <div className="w-full flex flex-col">
                        <CameraRecorder
                            cameraOpen={cameraOpen}
                            setCameraOpen={setCameraOpen}
                            handleVideoQuestion={handleVideoQuestion}
                            responseVideo={responseVideo}
                            setResponseVideo={setResponseVideo}
                            setApproved={setApproved}
                        />
                    </div>

                    <label htmlFor="videoModal" id="videoModalClose" className="hidden">
                        close
                    </label>
                </div>
            </div>
        </div>
    );
};

export default VideoModal;

import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import { createData } from "../../hooks/create";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";
import { IoCloseOutline } from "react-icons/io5";

const SupportForm = ({ refetch }: any) => {
    const [loading, setLoading] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};

    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="newTicket"]');
        if (form && label) {
            form.reset();
            label.click();
        }
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        const fingerPrintData = await getUserFingerPrint();
        if (form) {
            const data = {
                userId: userDataManage.user?.id,
                issueSubject: form.issueSubject.value,
                issueMessage: form.issueMessage.value,
                status: "pending"
            };

            createData({
                Parse: Parse,
                dataClass: "SupportTicket",
                data: data,
                message: "New Ticket Created!",
                refetch: refetch,
                closeModal: closeModal,
                fingerPrint: fingerPrintData,
                loading: setLoading
            });
        }
    };
    return (
        <div>
            <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                <button type="button" onClick={closeModal} className="px-2 pt-1">
                    <IoCloseOutline />
                </button>
            </div>
            <div>
                <h1 className="font-medium text-xl pb-2">Submit new Ticket.</h1>
            </div>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="py-6 grid grid-cols-1 gap-4 ">
                    <CustomInputField name="issueSubject" title="Subject" type="text" required max={100} />
                    <CustomInputField name="issueMessage" title="Message" type="text" required textArea />
                </div>

                <div className="flex justify-end items-center gap-4">
                    <button
                        type="submit"
                        className="btn bg-success hover:bg-primary  text-white  font-semibold border-0  flex justify-center items-center gap-x-2"
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default SupportForm;

import React, { useState, useEffect } from "react";
import Loading from "../Loading/Loading";

const RetryModal = ({ refetch, loading }: any) => {
    const [modalOpen] = useState(true);

    useEffect(() => {
        const modal = document.getElementById("retryModal") as HTMLInputElement | null;
        if (modalOpen && modal) {
            const openModalLater = setTimeout(() => {
                modal.checked = true;
            }, 1000);
            return () => {
                clearTimeout(openModalLater);
            };
        }
    }, [modalOpen]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <input type="checkbox" id="retryModal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box dark:bg-dark">
                    <h3 className="font-bold text-2xl text-center">Request Timeout</h3>

                    <div className="flex justify-end items-center gap-4 pt-10">
                        <label htmlFor="retryModal" className="text-error cursor-pointer">
                            Cancel
                        </label>
                        <button onClick={refetch} className="btn bg-primary text-white hover:bg-success border-0">
                            Retry
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetryModal;

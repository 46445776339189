import React, { useContext } from "react";
import Swal from "sweetalert2";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { toast } from "react-toastify";
import { PARSE_CONTEXT } from "../../../provider/Provider";

const PasswordReset = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    const sendOTP = async () => {
        try {
            const result = await Parse.Cloud.run("RequestPasswordResetOTP", {
                email: userDataManage.user.attributes.email,
                passwordChange: true
            });

            if (result.result) {
                const label = document.querySelector<HTMLLabelElement>('[for="passwordChange"]');
                if (label) {
                    label.click();
                }
                toast.success(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else {
                toast.error(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error: any) {
            // Handle errors
            toast.error(error, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    };

    const triggerPasswordChange = async () => {
        Swal.fire({
            title: "Are you sure?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes",
            customClass: {
                popup: darkMode ? "swal-dark-mode" : "swal-light-mode",
                confirmButton: darkMode ? "swal-dark-mode-confirm" : "swal-light-mode-confirm",
                cancelButton: darkMode ? "swal-dark-mode-cancel" : "swal-light-mode-cancel"
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                await sendOTP();
            }
        });
    };

    return (
        <div className="shadow-lg  p-4 rounded-md bg-white dark:bg-darkSecondary py-10 w-full">
            <button
                onClick={triggerPasswordChange}
                className={` w-full px-4 py-4 hover:bg-dark/90 duration-300 bg-dark text-white dark:bg-white dark:text-dark dark:hover:bg-white-90 rounded-lg font-bold block text-center cursor-pointer`}
            >
                Change Password?
            </button>
            <label htmlFor="passwordChange" />
        </div>
    );
};

export default PasswordReset;

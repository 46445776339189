import React, { useContext } from "react";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import WelcomeBanner from "../../components/Dashboard/WelcomeBanner";
import CustomSlider from "../../components/Common/CustomSlider/CustomSlider";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import Analytics from "../../components/Dashboard/Analytics/Analytics";
import useFetchWebsite from "../../hooks/useFetchWebsite";
import Loading from "../../components/Common/Loading/Loading";
import { blogData } from "../../content/blogContent";

const DashboardPage = () => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const [blogs, loading] = useFetchWebsite({ endPoint: "blogs", page: 1, limit: 3, defaultValue: blogData });
    if (loading) {
        return <Loading />;
    }

    return (
        <section className="sm:px-4 xl:px-6 ">
            <HelmetConfiguration seoData={SeoContent["dashboardSeo"] || SeoContent.baseSeo} />
            <div className=" mx-auto py-16">
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                    <WelcomeBanner />
                    <CustomSlider blogData={blogs as any} />
                </div>
            </div>
            {userDataManage.user && <Analytics user={userDataManage.user} />}
        </section>
    );
};

export default DashboardPage;

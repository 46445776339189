import React, { useState } from "react";
import logo from "../../assets/images/logo/logo.svg";
import Login from "./Login";
import Register from "./Register";

const LoginRegisterToggle = () => {
    const [login, setLogin] = useState(true);
    return (
        <div>
            <div className="w-[20rem] sm:w-[22rem] 2xl:w-[25rem]">
                <div className="flex justify-center items-center gap-x-1">
                    <img src={logo} alt="" width="45rem" />
                    <span className="text-3xl font-extrabold dark:text-white">HRHouz</span>
                </div>
                <div className="grid grid-cols-2 w-full pt-6">
                    <button
                        onClick={() => setLogin(true)}
                        className={`text-primary font-semibold text-xl flex justify-center w-full border-primary pb-2 ${
                            login && "border-b-2"
                        }`}
                    >
                        Login
                    </button>
                    <button
                        onClick={() => setLogin(false)}
                        className={`text-primary font-semibold text-xl flex justify-center w-full border-primary pb-2 ${
                            !login && "border-b-2"
                        }`}
                    >
                        Register
                    </button>
                </div>
            </div>
            {login ? <Login isLogin={login} setLogin={setLogin} /> : <Register login={login} setLogin={setLogin} />}
        </div>
    );
};

export default LoginRegisterToggle;

import React, { useEffect, useState } from "react";

const CheckBlock = ({
    question,
    value,
    required,
    setRequired,
    result,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    value: any;
    required: boolean;
    setRequired: any;
    result?: any;
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [checked, setChecked] = useState(result && result.includes(value));

    useEffect(() => {
        setChecked(result && result.includes(value));
    }, [result, value]);

    const handleChecked = async (event: any) => {
        if (event.target.checked) {
            setRequired(false);
        }
        await new Promise<void>((resolve) => {
            setChecked(!checked);
            resolve();
        });
        if (handleAnswerUpdate) {
            await handleAnswerUpdate();
        }
    };

    return (
        <label
            className={`flex items-center capitalize justify-center p-2  text-center  rounded-md w-full  dark:bg-gray-100/20  ${
                checked ? "!bg-primary text-white" : "bg-gray-100"
            } ${!disabled ? "cursor-pointer" : "cursor-not-allowed"}`}
        >
            <input
                name={question.objectId}
                type="checkbox"
                value={checked ? value : ""}
                className="hidden "
                onChange={disabled ? () => {} : handleChecked}
                required={required}
            />
            <span className=" text-sm lg:text-base">{question.options[value]}</span>
        </label>
    );
};

export default CheckBlock;

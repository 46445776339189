import React from "react";
import { statusColor } from "../../utils/statusColor";
import { RiShareBoxLine } from "react-icons/ri";
import { Link } from "react-router-dom";

const SummaryCard = ({ name, data }: any) => {
    const bgColor = statusColor(data.title);
    const gradientColor1 = `${bgColor}80`;
    const gradientColor2 = `${bgColor}30`;

    const cardStyle = {
        background: `linear-gradient(to left, ${gradientColor1} 0%, ${gradientColor2} 100%)`
    };

    return (
        <Link
            to={`/${name}?status=${data.title}`}
            className={`flex flex-col items-center p-6 shadow-lg rounded-lg justify-center relative`}
            style={cardStyle}
        >
            <h2 className="text-5xl font-bold pb-2">{data.total}</h2>
            <h1 className="text-base font-medium capitalize">{data.title} Stage</h1>

            <div className="absolute top-2 right-2 text-2xl ">
                <RiShareBoxLine />
            </div>
        </Link>
    );
};

export default SummaryCard;

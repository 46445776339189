import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFGenerator from "../../PDFGenerator/PDFGenerator";
import { FaSpinner, FaRegFilePdf } from "react-icons/fa6";

const ResponseTab = ({ data }: any) => {
    const { candidateFirstname, candidateLastname, requestorFirstname, requestorLastname, response } = data.attributes;

    return (
        <div className="relative rounded dark:bg-dark p-0">
            {response && response.length !== 0 ? (
                <div className="p-2  lg:px-6">
                    <div className="flex justify-between gap-6 py-3">
                        <Link
                            to="/employer"
                            className="lg:absolute left-2 flex  gap-x-2 items-center text-lg font-semibold"
                        >
                            <BiArrowBack />
                            <span>Back</span>
                        </Link>
                    </div>
                    <div className="lg:text-center flex justify-center items-center gap-x-2">
                        <h1 className="font-semibold text-2xl pb-2">Reference Results</h1>
                        <PDFDownloadLink
                            document={<PDFGenerator data={data.attributes} />}
                            fileName={`HRHouz_Report_for_${candidateFirstname}-${candidateLastname}_by_${requestorFirstname}-${requestorLastname}`}
                            className="flex flex-col  gap-x-2 items-center text-lg font-semibold hover:text-info duration-300"
                        >
                            {({ loading, error }) => {
                                return (
                                    <div className="flex flex-col items-center">
                                        {loading ? <FaSpinner /> : <FaRegFilePdf />}
                                        <span className="text-[8px] leading-[.8rem]">
                                            {loading ? "loading" : `Results`}
                                        </span>
                                    </div>
                                );
                            }}
                        </PDFDownloadLink>
                    </div>
                </div>
            ) : (
                <div className="p-4">
                    <Link to="/give" className=" flex  gap-x-2 items-center text-lg font-semibold">
                        <BiArrowBack />
                        <span>Back</span>
                    </Link>
                </div>
            )}
        </div>
    );
};

export default ResponseTab;

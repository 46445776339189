import React from "react";
import AdminControl from "./AdminControl/AdminControl";
import { IoCloseOutline } from "react-icons/io5";

const AdminUserSwitchModal = () => {
    return (
        <div>
            <input type="checkbox" id="adminUserSwitch" className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box dark:bg-darkSecondary overflow-y-visible">
                    <AdminControl />
                    <label
                        htmlFor="adminUserSwitch"
                        className="absolute top-0 right-0 text-3xl bg-error text-white rounded-bl-xl rounded-tr-xl p-2 cursor-pointer"
                    >
                        <IoCloseOutline />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default AdminUserSwitchModal;

import React from "react";
import SingleChoiceComponent from "../../Common/QuestionComponents/SingleChoiceComponent";
import SingleBlockComponent from "../../Common/QuestionComponents/SingleBlockComponent";
import SingleSliderComponent from "../../Common/QuestionComponents/SingleSliderComponent";
import MultipleChoiceComponent from "../../Common/QuestionComponents/MultipleChoiceComponent";
import MultipleBlockComponent from "../../Common/QuestionComponents/MultipleBlockComponent";

const ObjectiveQuestionResponse = ({ question }: any) => {
    return (
        <div className="flex flex-col gap-y-3 ">
            {question.map(
                (item: any) =>
                    (item.type === "singleRegular" && (
                        <SingleChoiceComponent
                            key={item.objectId}
                            question={item}
                            result={item.response}
                            analytics={item.analytics}
                            disabled
                        />
                    )) ||
                    (item.type === "singleBlock" && (
                        <SingleBlockComponent
                            key={item.objectId}
                            question={item}
                            result={item.response}
                            analytics={item.analytics}
                            disabled
                        />
                    )) ||
                    (item.type === "singleSlider" && (
                        <SingleSliderComponent
                            key={item.objectId}
                            question={item}
                            result={item.response}
                            analytics={item.analytics}
                            disabled
                        />
                    )) ||
                    (item.type === "multipleRegular" && (
                        <MultipleChoiceComponent
                            key={item.objectId}
                            question={item}
                            result={item.response}
                            analytics={item.analytics}
                            disabled
                        />
                    )) ||
                    (item.type === "multipleBlock" && (
                        <MultipleBlockComponent
                            key={item.objectId}
                            question={item}
                            result={item.response}
                            analytics={item.analytics}
                            disabled
                        />
                    ))
            )}
        </div>
    );
};

export default ObjectiveQuestionResponse;

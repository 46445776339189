import { useEffect, useState } from "react";

const useCategoryCheck = ({ Parse, query, dataClass }: any) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refetchData, setRefetchData] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Parse.Cloud.run(dataClass, query);
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [refetchData, JSON.stringify(query)]);
    const refetch = () => setRefetchData((prevState) => !prevState);

    return [data, loading, refetch];
};

export default useCategoryCheck;

import React from "react";
import InputField from "../Common/QuestionComponents/InputField";

const SubjectiveComponents = ({ data, handleAnswerUpdate, questionSubjective }: any) => {
    return (
        <div className="flex flex-col gap-y-3 pt-3">
            {data.map((item: any, index: number) => (
                <InputField
                    questionItem={item}
                    key={item.objectId}
                    handleAnswerUpdate={handleAnswerUpdate}
                    result={questionSubjective ? questionSubjective[index]?.response : null}
                />
            ))}
        </div>
    );
};

export default SubjectiveComponents;

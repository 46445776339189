import React, { createContext, useState } from "react";
import { ChildrenProps } from "../type/Types";

export const SIDEBAR_CONTEXT = createContext<any | undefined>(undefined);

const SideBarProvider = ({ children }: ChildrenProps) => {
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const value: any = { sideBarOpen, setSideBarOpen };

    return <SIDEBAR_CONTEXT.Provider value={value}>{children}</SIDEBAR_CONTEXT.Provider>;
};

export default SideBarProvider;

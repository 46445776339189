import { View, Text } from "@react-pdf/renderer";
import React from "react";

const ScorePDF = ({ score }: { score: number }) => {
    let colorSelect = "";
    if (score >= 0 && score <= 33.33) {
        colorSelect = "#D32F2F";
    } else if (score >= 33.34 && score <= 66.67) {
        colorSelect = "#FABD23";
    } else {
        colorSelect = "#00A76F";
    }
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}
        >
            <View
                style={{
                    width: 35,
                    height: 35,
                    borderRadius: "100%",
                    border: 4,
                    borderColor: colorSelect,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Text style={{ color: "black", fontSize: 8, borderBottom: 1 }}>{Math.round(score)}</Text>
                <Text style={{ fontSize: 6 }}>100</Text>
            </View>
            <Text style={{ fontSize: 10 }}>score</Text>
        </View>
    );
};

export default ScorePDF;

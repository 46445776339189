import React, { useState, useContext } from "react";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { useLocation } from "react-router-dom";
import useFetch from "../../hooks/useFetch";

import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import Table from "../../components/Common/Table/Table";
import EmployerReferenceModal from "../../components/Employer/Modal/EmployerReferenceModal";
import ReferenceViewModal from "../../components/Employer/Modal/ReferenceViewModal";
import UpdateReferenceModal from "../../components/Employer/Modal/UpdateReferenceModal";

import { EmployerType } from "../../type/EmployerType";
import { TableData } from "../../type/Table";

import image from "../../assets/images/header/person.jpg";
import { FaPlus } from "react-icons/fa";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { DateComponent, ProfileComponent, StatusComponent } from "../../components/Common/Table/UtilityComponent";
import useCategoryCheck from "../../hooks/useCategoryCheck";
import { QuestionDataType } from "../../type/Types";
import { pageData } from "../../content/constantVariable";
import ResumeViewer from "../../components/Common/Table/ResumeViewer";

interface EmployerDataType {
    totalCount: number;
    result: EmployerType[];
}

const EmployerPage = () => {
    const statusItems = new Set(["all", "started", "candidate", "referee", "analyzing", "completed"]);
    const location = useLocation();
    const statusParam = new URLSearchParams(location.search).get("status");
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const [currentStatus, setCurrentStatus] = useState(
        statusParam ? (statusItems.has(statusParam) ? statusParam : "all") : "all"
    );
    const viewUser = userListData && userListData.usersList[0];
    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "Employer",
        dataQuery: [
            {
                key: "requestorUserId",
                item: adminModeData?.adminMode && isAdmin ? viewUser?.id : userDataManage.user?.id
            },
            { key: "status", item: currentStatus === "all" ? "" : currentStatus }
        ],
        total: { perPage: totalData, page: page },
        alert: true
    });

    const [questionData, questionLoading] = useFetch({
        Parse: Parse,
        dataClass: "QuestionTemplate"
    });

    const [statusData, statusLoading, filterRefetch] = useCategoryCheck({
        Parse: Parse,
        dataClass: "EmployerStatusCount",
        query: { requestorUserId: adminModeData?.adminMode && isAdmin ? viewUser?.id : userDataManage.user?.id }
    });

    if (loading || questionLoading || statusLoading) {
        return <Loading />;
    }

    const tableBody = (data as EmployerDataType).result.map((item) => {
        return {
            id: item.id,

            candidate: (
                <ProfileComponent
                    id={item.id}
                    image={image}
                    name={item.attributes.candidateFirstname + " " + item.attributes.candidateLastname}
                    email={item.attributes.candidateEmail}
                    phone={item.attributes.candidatePhoneNumber}
                    resumeUrl={item.attributes.resumeUrl}
                />
            ),
            createdAt: <DateComponent date={item.attributes.createdAt} />,
            updatedAt: <DateComponent date={item.attributes.updatedAt} />,
            question: item.attributes.questionTemplate.name,
            minRefereeCount: (
                <div className="flex justify-center w-[80%] lg:w-[50%]">{item.attributes.minRefereeCount}</div>
            ),
            status: (
                <StatusComponent
                    status={item.attributes.status}
                    id={item.id}
                    response={item.attributes.response}
                    minRefereeCount={item.attributes.minRefereeCount}
                />
            ),
            removeUpdate: item.attributes.status !== "started" ? false : true,
            removeDelete: item.attributes.status === "completed" ? false : true
        };
    });

    const tableData = {
        columnItems: ["Candidate", "Date Initiated", "Last Updated", "Question", "Referee Count", "Status", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as EmployerDataType).totalCount
        },
        refetch: refetch,
        dataClass: "Employer",
        filter: statusData,
        currentStatus: currentStatus,
        statusUpdate: setCurrentStatus,
        filterRefetch: filterRefetch
    };

    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["requestSeo"] || SeoContent.baseSeo} />
            <section className="py-10 ">
                <div className="flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="askReference"
                    >
                        <FaPlus /> Request Reference
                    </label>
                </div>

                <Table action tableData={tableData as TableData} />

                <div>
                    {(data as EmployerDataType).result.map((item: { id: string; attributes: any }, index: any) => (
                        <div key={index}>
                            <ReferenceViewModal item={item} />
                            <UpdateReferenceModal
                                questionData={(questionData as QuestionDataType).result}
                                refetch={refetch}
                                item={item}
                            />
                            <ResumeViewer id={item.id} resumeUrl={item.attributes.resumeUrl} />
                        </div>
                    ))}
                </div>

                <EmployerReferenceModal
                    refetch={[refetch, filterRefetch]}
                    questionData={(questionData as QuestionDataType).result}
                />
                {error && <RetryModal refetch={refetch} />}
            </section>
        </TableLayer>
    );
};

export default EmployerPage;

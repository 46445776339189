import { useState } from "react";
import RadialProgress from "./RadialProgress";
import SentimentAnalysisChart from "./SentimentAnalysisChart ";
import { TbReportSearch } from "react-icons/tb";
import PieChartMini from "./PieChartMini";
import { FaCheckSquare, FaWindowClose } from "react-icons/fa";
import VideoPlayer from "../QuestionComponents/VideoPlayer";
import { FaCheckDouble, FaFileCircleQuestion } from "react-icons/fa6";
import { IoWarningSharp } from "react-icons/io5";
import RadarChart from "./RadarChart";
import HeatMapChart from "./HeatMapChart";
import ColumnChart from "./ColumnChart";
import StackedChart from "./StackedChart";
import { IoIosVideocam } from "react-icons/io";
import { AnalyticsType } from "../../../type/AnalyticsType";

const AnalyticsSection = ({
    analytics,
    video,
    subjectiveQuestionCount,
    refereeCount,
    defaultOpen
}: {
    analytics: AnalyticsType;
    video?: { name: string; video: string }[] | null;
    subjectiveQuestionCount?: number;
    refereeCount?: number;
    defaultOpen?: boolean;
}) => {
    const [expand, setExpand] = useState(defaultOpen);
    const colorSelect = ({
        wordCount,
        questionCount = 1,
        refereeCount = 1
    }: {
        wordCount: number;
        questionCount?: number;
        refereeCount?: number;
    }) => {
        if (wordCount >= 0 && wordCount <= 30 * questionCount * refereeCount) {
            return "bg-error text-white";
        } else if (wordCount >= 31 * questionCount * refereeCount && wordCount <= 50 * questionCount * refereeCount) {
            return "bg-warning text-white";
        } else {
            return "bg-primary text-white";
        }
    };

    const plusIcon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1025_508)">
                <path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z" fill="currentColor"></path>
            </g>
            <defs>
                <clipPath id="clip0_1025_508">
                    <rect width="24" height="24" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    );

    const minusIcon = (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1025_479)">
                <rect x="5" y="11" width="14" height="2" fill="currentColor"></rect>
            </g>
            <defs>
                <clipPath id="clip0_1025_479">
                    <rect width="24" height="24" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    );

    let analyticsData = null;

    if (analytics.sentiment_analysis) {
        analyticsData = [
            analytics.sentiment_analysis?.positive,
            analytics.sentiment_analysis?.neutral,
            analytics.sentiment_analysis?.negative
        ];
    }

    return (
        <div className="max-h-fit text-base font-semibold  bg-gray-100 dark:bg-gray-100/10  mt-3 dark:text-white text-black rounded-lg">
            <button
                onClick={() => setExpand(!expand)}
                className="flex w-full justify-between px-2 lg:px-2 py-2 items-center"
            >
                <div className="flex items-center gap-x-1 sm:gap-x-2 h-full ">
                    <div className="flex flex-col items-center justify-between h-full">
                        <div
                            className={`h-[2.4rem] sm:h-[2.8rem] w-[2.4rem] sm:w-[2.8rem]  rounded-full flex justify-center items-center bg-blue-400`}
                        >
                            <span className="text-2xl text-center text-white">
                                <TbReportSearch />
                            </span>
                        </div>
                        <span className="text-[10px] sm:text-xs">analytics</span>
                    </div>

                    {typeof analytics?.overall_score === "number" && (
                        <div className="flex flex-col items-center justify-between h-full">
                            {<RadialProgress value={analytics.overall_score} />}
                            <span className="text-[10px] sm:text-xs">score</span>
                        </div>
                    )}

                    {analyticsData && (
                        <div className="flex flex-col items-center justify-between">
                            <PieChartMini data={analyticsData.reverse()} />
                            <span className="text-[10px] sm:text-xs ">sentiments</span>
                        </div>
                    )}
                    {typeof analytics.words_written === "number" && (
                        <div className="flex flex-col items-center justify-between h-full">
                            <div
                                className={`h-[2.4rem] sm:h-[2.8rem] w-[2.4rem] sm:w-[2.8rem] rounded-full flex justify-center items-center ${colorSelect(
                                    {
                                        wordCount: analytics.words_written,
                                        questionCount: subjectiveQuestionCount,
                                        refereeCount: refereeCount
                                    }
                                )}`}
                            >
                                <span className="text-xs text-center">{analytics.words_written}</span>
                            </div>
                            <span className="text-[10px] sm:text-xs">words</span>
                        </div>
                    )}
                    {typeof analytics.questions === "number" && (
                        <div className="relative">
                            <div className="chat chat-start  h-[2.4rem] sm:h-[2.8rem] w-[2.4rem] sm:w-[2rem]">
                                <div className="chat-bubble chat-bubble-primary text-sm min-h-[2.4rem] sm:min-h-[2.8rem] flex justify-center items-center -mt-1">
                                    <span>{analytics.questions}</span>
                                </div>
                            </div>
                            <span className="text-[10px] sm:text-xs block">questions</span>
                        </div>
                    )}
                </div>
                <div>{!expand ? plusIcon : minusIcon}</div>
            </button>
            <div
                className={` transition-all ${
                    expand ? "max-h-full px-2 md:px-6  pb-6 pt-0 opacity-100" : "h-[0px!important] opacity-0"
                } text-start text-body-color duration-300 bg-gray-50 dark:bg-gray-50/5`}
            >
                {expand && (
                    <div>
                        {analytics?.summary && (
                            <h2 className="font-bold pt-4 flex flex-col gap-y-1">
                                <span className="text-lg font-semibold">Summary</span>{" "}
                                <span className="font-normal">{analytics?.summary}</span>
                            </h2>
                        )}
                        {(analytics.positive_highlights || analytics.negative_highlights) && (
                            <Highlights analytics={analytics} />
                        )}
                        <div className={`grid grid-cols-1 ${analytics.questions && "lg:grid-cols-2"} gap-2`}>
                            <div>
                                {analytics?.sentiment_analysis && (
                                    <div className=" pt-4">
                                        <h1 className="text-lg">Sentiment Analysis</h1>
                                        <SentimentAnalysisChart sentimentAnalysis={analytics?.sentiment_analysis} />
                                    </div>
                                )}
                                {analytics.radar_chart && (
                                    <div className="pt-4">
                                        <h1 className="text-lg">Soft Skills</h1>
                                        <RadarChart data={analytics.radar_chart} />
                                    </div>
                                )}
                                {analytics.heatMap && (
                                    <div className="pt-4">
                                        <h1 className="text-lg">Sentiment Analysis</h1>
                                        <HeatMapChart data={analytics.heatMap} />
                                    </div>
                                )}
                                {analytics.columnChart && (
                                    <div className="pt-4">
                                        <h1 className="text-lg">Sentiment Analysis</h1>
                                        <ColumnChart data={analytics.columnChart} />
                                    </div>
                                )}
                                {analytics.stackedChart && (
                                    <div className="pt-4">
                                        <h1 className="text-lg">Sentiment Analysis</h1>
                                        <StackedChart data={analytics.stackedChart} />
                                    </div>
                                )}
                            </div>

                            <div>
                                {analytics?.reasoning && (
                                    <h2 className="font-bold pt-4 flex flex-col gap-y-3">
                                        <span className="text-lg">Reasoning</span>{" "}
                                        <span className="font-normal">{analytics?.reasoning}</span>
                                    </h2>
                                )}
                                {video && (
                                    <div className="pt-4">
                                        <span className="text-lg">Videos</span>
                                        <div className="flex items-center gap-x-2">
                                            {video.map((item, index) => (
                                                <label
                                                    htmlFor={"video" + index}
                                                    key={index}
                                                    className="text-sm px-2 py-1 rounded-md text-white bg-primary cursor-pointer flex items-center gap-x-1"
                                                >
                                                    <span>
                                                        <IoIosVideocam />
                                                    </span>
                                                    <span>{item.name}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {analytics?.fingerprint_result &&
                                    Array.isArray(analytics?.fingerprint_result) &&
                                    analytics?.fingerprint_result.length > 0 && (
                                        <FingerPrintResult analytics={analytics} />
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {video &&
                video.map((item, index) => <VideoPlayer key={index} response={item.video} id={"video" + index} />)}
        </div>
    );
};

export default AnalyticsSection;

const Highlights = ({ analytics }: { analytics: AnalyticsType }) => {
    return (
        <div className="pt-4">
            <div>
                <span className="text-lg">Positive Highlights</span>
                <div className="grid grid-cols-1">
                    <ul className="list-none text-base leading-tight font-normal list-outside mt-2 ">
                        {analytics.positive_highlights &&
                            analytics.positive_highlights?.map((item, index) => (
                                <li key={index} className="flex items-start gap-x-2">
                                    <span className="text-success mt-[.2rem] relative mr-4">
                                        <span className="absolute top-0 left-0 z-10 ">
                                            <FaCheckSquare className="" />
                                        </span>

                                        <div className="absolute w-2 h-2 top-1 left-1 bg-white"></div>
                                    </span>
                                    <span> {item}</span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <div className="pt-4">
                <span className="text-lg ">Negative Highlights</span>
                <div className="grid grid-cols-1">
                    <ul className="list-none text-base leading-tight font-normal list-outside mt-2 ">
                        {analytics.negative_highlights &&
                            analytics.negative_highlights?.map((item, index) => (
                                <li key={index} className="flex items-start gap-x-2">
                                    <span className="text-error mt-[.2rem] relative mr-4">
                                        <span className="absolute top-0 left-0 z-10 ">
                                            {" "}
                                            <FaWindowClose className="" />
                                        </span>

                                        <div className="absolute w-2 h-2 top-1 left-1 bg-white"></div>
                                    </span>
                                    <span> {item}</span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

const FingerPrintResult = ({ analytics }: { analytics: AnalyticsType }) => {
    return (
        <div className="pt-4">
            <span className="text-lg">Fingerprints</span>
            <ul className="list-none text-base leading-tight font-normal list-outside mt-2 ">
                {analytics.fingerprint_result?.map((item, index) => (
                    <li key={index} className="flex items-start gap-x-2 mb-1">
                        <span
                            className={`${item.resultBool === true && "bg-success"} ${
                                item.resultBool === false && "bg-error"
                            } ${
                                item.resultBool === null && "bg-warning"
                            } mt-[.1rem]  rounded text-white text-[14px] p-[2px]`}
                        >
                            {item.resultBool === true && <FaCheckDouble />}
                            {item.resultBool === false && <IoWarningSharp />}
                            {item.resultBool === null && <FaFileCircleQuestion />}
                        </span>
                        <span> {item.name}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { SIDEBAR_CONTEXT } from "../provider/SideBarProvider";
import "sweetalert2/dist/sweetalert2.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AdminUserSwitchModal from "../components/AdminUserSwitchModal/AdminUserSwitchModal";

const AppLayout = () => {
    const { sideBarOpen, setSideBarOpen } = useContext(SIDEBAR_CONTEXT) || {};
    const layoutClass = `w-full ${
        sideBarOpen
            ? "ml-[24rem] sm:ml-[10rem] md:ml-[12rem] lg:ml-[14rem] duration-300 "
            : "ml-[4.5rem] md:ml-[5rem] duration-300"
    }`;
    const mainClass = sideBarOpen ? "hidden sm:block  mt-[4rem] " : "mt-[4rem] ";

    return (
        <>
            <Header sideBarOpen={sideBarOpen} />

            <div className={`flex bg-primaryBG dark:bg-darkBG dark:text-white duration-300`}>
                <SideBar open={sideBarOpen} setOpen={setSideBarOpen} />

                <div className={layoutClass}>
                    <main className={mainClass + " min-h-screen dark:bg-dark"}>
                        <Outlet />
                    </main>
                </div>
            </div>
            <AdminUserSwitchModal />
        </>
    );
};

export default AppLayout;

import React, { useContext } from "react";
import Loading from "../../Common/Loading/Loading";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import AnalyticsComponents from "./AnalyticsComponents";
import useCategoryCheck from "../../../hooks/useCategoryCheck";
import { DATA_CONTEXT } from "../../../provider/DataProvider";

const Analytics = ({ user }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const viewUser = userListData && userListData.usersList[0];
    const [analytics, loading, refetch] = useCategoryCheck({
        Parse: Parse,
        dataClass: "Dashboard",
        query: {
            userId: adminModeData?.adminMode && isAdmin ? viewUser?.id : user?.id,
            userEmail: adminModeData?.adminMode && isAdmin ? viewUser?.email : user?.attributes.email
        }
    });

    if (loading) {
        return <Loading />;
    }
    return <section>{analytics && <AnalyticsComponents analytics={analytics} />}</section>;
};

export default Analytics;

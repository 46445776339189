import React from "react";

const CustomSelect = ({ name, title, data, disabled }: any) => {
    return (
        <div className="w-full h-full">
            <fieldset
                className={`border-2 pl-3 !h-full rounded overflow-hidden relative ${
                    disabled ? "border-primary" : "border-secondary/50 dark:border-primaryBG/80"
                } `}
            >
                <legend
                    className={`px-2  text-sm font-medium  ${
                        disabled ? "text-primary" : "text-secondary dark:text-white"
                    }`}
                >
                    {title}
                </legend>
                <select name={name} className=" w-full outline-none  p-2 pb-3 bg-inherit">
                    {data.length > 0 &&
                        data.map((item: { attributes: any; id: string }) => (
                            <option value={item.id} key={item.id}>
                                {item.attributes.questionTemplateName}
                            </option>
                        ))}
                </select>
            </fieldset>
        </div>
    );
};

export default CustomSelect;

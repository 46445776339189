import React, { useContext, useRef, useState } from "react";
import RequestPasswordResetOTPUI from "./RequestPasswordResetOTPUI";
import VerifyPasswordResetOTPUI from "./VerifyPasswordResetOTPUI";
import ResetPasswordUI from "./ResetPasswordUI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PARSE_CONTEXT } from "../../provider/Provider";

const ForgotPasswordFlow = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const [resetFlow, setResetFlow] = useState<string>();
    const [email, setEmail] = useState();
    const [otp, setOtp] = useState("");
    const formRef = useRef<HTMLFormElement | null>(null);
    const navigate = useNavigate();

    const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (formRef.current) {
            setEmail(formRef.current.email.value);
            try {
                const result = await Parse.Cloud.run("RequestPasswordResetOTP", {
                    email: formRef.current.email.value
                });

                if (result.result) {
                    setResetFlow("otp");
                    navigate("/forgot-password?flow=otp");
                    toast.success(result.message, {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                } else {
                    toast.error(result.message, {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            } catch (error: any) {
                // Handle errors
                toast.error(error, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    };
    return (
        <div>
            {!resetFlow && <RequestPasswordResetOTPUI formRef={formRef} handleResetPassword={handleResetPassword} />}
            {resetFlow === "otp" && (
                <VerifyPasswordResetOTPUI
                    otp={otp}
                    setOtp={setOtp}
                    email={email}
                    setResetFlow={setResetFlow}
                    handleResetPassword={handleResetPassword}
                />
            )}
            {resetFlow === "reset" && <ResetPasswordUI otp={otp} email={email} setResetFlow={setResetFlow} />}
        </div>
    );
};

export default ForgotPasswordFlow;

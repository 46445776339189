import React, { useEffect, useState } from "react";
import AnalyticsSection from "../Analytics/AnalyticsSection";

const SingleSliderComponent = ({
    question,
    result,
    analytics,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    result?: any;
    analytics?: {
        overall_score: number;
        reasoning: string;
    };
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [sliderValue, setSliderValue] = useState(result ? result : 25);
    useEffect(() => {
        if (result !== undefined) {
            setSliderValue(result);
        }
    }, [result]);

    const handleSliderChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSliderValue(parseInt(e.target.value, 10));
        if (handleAnswerUpdate) {
            await handleAnswerUpdate();
        }
    };

    return (
        <div className="overflow-hidden  shadow rounded-md">
            <div className=" bg-white dark:bg-darkSecondary  px-6 py-4 ">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold mb-3">{question.question}</h2>
                <input
                    type="range"
                    name={question.objectId}
                    min={0}
                    max={100}
                    value={sliderValue}
                    onChange={handleSliderChange}
                    className={`range range-success range-sm ${disabled && "cursor-not-allowed"}`}
                    step={25}
                    disabled={disabled}
                />
                <div className="w-full flex justify-between text-xs px-2">
                    {Object.keys(question.options).map((key, index) => (
                        <span key={index} className="ml-2 break-all text-sm lg:text-base">
                            {question.options[key]}
                        </span>
                    ))}
                </div>

                {analytics && (
                    <div>
                        <AnalyticsSection analytics={analytics} defaultOpen />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleSliderComponent;

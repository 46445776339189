import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const VideoPlayer = ({ response, id }: any) => {
    return (
        <div>
            <input type="checkbox" id={id} className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box  p-0 rounded-md relative">
                    <video width="640" height="360" controls>
                        <source src={response} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="absolute top-2 right-2">
                        <label
                            htmlFor={id}
                            className="block p-1 text-white rounded-full bg-error text-lg cursor-pointer"
                        >
                            <AiOutlineClose />
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;

import { ApexOptions } from "apexcharts";
import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";

const HeatMapChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const chartData = {
        series: data.series,

        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false
                },
                type: "heatmap"
            },

            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: data.categories,
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },
            tooltip: {
                style: {
                    color: "black"
                },
                theme: darkMode ? "dark" : "light" // Explicitly set theme for tooltip
            },
            colors: ["#00A76F"]
        } as ApexOptions
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="heatmap" height={350} />
        </div>
    );
};

export default HeatMapChart;

export const lineCartData = {
    title: "Line Chart",
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Nov", "Dec"],
    chartSeries: [
        {
            name: "Asia",
            data: [10, 41, 51, 49, 62, 69, 91, 148, 35, 51, 49],
            color: "#00A76F"
        },
        {
            name: "America",
            data: [10, 34, 56, 77, 88, 99, 77, 45, 13, 56, 77],
            color: "#FFAB00"
        }
    ]
};

export const groupChartData = [
    {
        id: 1,
        title: "Total Active User",
        total: "18,765",
        color: "#00A76F",
        data: [
            { x: "1", y: 10 },
            { x: "2", y: 60 },
            { x: "3", y: 20 },
            { x: "4", y: 100 },
            { x: "5", y: 300 },
            { x: "6", y: 900 },
            { x: "7", y: 400 },
            { x: "8", y: 600 },
            { x: "9", y: 100 }
        ]
    },
    {
        id: 2,
        title: "Total Installed",
        total: "18,765",
        color: "#5FF9FD",
        data: [
            { x: "1", y: 10 },
            { x: "2", y: 60 },
            { x: "3", y: 20 },
            { x: "4", y: 100 },
            { x: "5", y: 300 },
            { x: "6", y: 900 },
            { x: "7", y: 400 },
            { x: "8", y: 600 },
            { x: "9", y: 100 }
        ]
    },
    {
        id: 3,
        title: "Total Downloads",
        total: "18,765",
        color: "#FFAB00",
        data: [
            { x: "1", y: 10 },
            { x: "2", y: 60 },
            { x: "3", y: 20 },
            { x: "4", y: 100 },
            { x: "5", y: 300 },
            { x: "6", y: 900 },
            { x: "7", y: 400 },
            { x: "8", y: 600 },
            { x: "9", y: 100 }
        ]
    },
    {
        id: 4,
        title: "Total Upload",
        total: "18,765",
        color: "#FF5630",
        data: [
            { x: "1", y: 10 },
            { x: "2", y: 60 },
            { x: "3", y: 20 },
            { x: "4", y: 100 },
            { x: "5", y: 300 },
            { x: "6", y: 900 },
            { x: "7", y: 400 },
            { x: "8", y: 600 },
            { x: "9", y: 100 }
        ]
    }
];

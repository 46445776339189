import React, { useContext, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { createData } from "../../../hooks/create";
import { getUserFingerPrint } from "../../../utils/getUserFingerPrint";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import UserSearchField from "../../AdminUserSwitchModal/AdminControl/UserSearchField";
import PhotoUploader from "../../Common/PhotoUploader/PhotoUploader";
import { convertToHttps } from "../../../utils/convertUrl";

const AddOrganization = ({ refetch }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userListData } = useContext(DATA_CONTEXT) || {};
    const [loading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState<null | string>(null);
    const [organizationAdmin, setOrganizationAdmin] = useState<null | string>(null);
    const formRef = useRef<HTMLFormElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="addOrganization"]');
        if (form && label) {
            form.reset();
            label.click();
            setImageURL(null);
            userListData?.setUserSearchString("");
        }
    };
    const handleLabelClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const parseFile = new Parse.File(file.name, file);
            try {
                await parseFile.save();
                setImageURL(convertToHttps(parseFile.url()));
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        const fingerPrintData = await getUserFingerPrint();
        if (form) {
            const data = {
                organizationImage: imageURL,
                organizationName: form.organizationName.value,
                organizationEmailDomain: form.organizationEmailDomain.value,
                organizationRoutePath: form.organizationRoutePath.value,
                organizationAddress: form.organizationAddress.value,
                organizationMapLocation: form.organizationMapLocation.value,
                organizationWebsite: form.organizationWebsite.value,
                organizationDescription: form.organizationDescription.value,
                organizationAdminEmail: organizationAdmin,
                fingerPrint: fingerPrintData
            };

            createData({
                Parse: Parse,
                dataClass: "Organization",
                data: data,
                message: "New Organization Created!",
                refetch: [refetch],
                closeModal: closeModal,
                loading: setLoading
            });
        }
    };
    return (
        <>
            <input type="checkbox" id="addOrganization" className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box rounded dark:bg-darkBG ">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>

                    <div>
                        <h1 className="font-medium text-xl pb-2">Add New Organization.</h1>
                        <p>Please fill up the form and hit submit</p>
                    </div>

                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="py-6 grid grid-cols-1 md:grid-cols-2 gap-4 ">
                            <PhotoUploader
                                fileInputRef={fileInputRef}
                                handleFileChange={handleFileChange}
                                handleLabelClick={handleLabelClick}
                                photoUrl={imageURL}
                            />
                            <CustomInputField name="organizationName" title="Name" type="text" required />
                            <CustomInputField
                                name="organizationEmailDomain"
                                title="Email Domain"
                                type="text"
                                required
                            />
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationRoutePath"
                                    title="Route Path"
                                    type="text"
                                    required
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField name="organizationWebsite" title="Website" type="url" />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationDescription"
                                    title="Organization Description"
                                    type="url"
                                    textArea
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField name="organizationAddress" title="Address" type="text" />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField name="organizationMapLocation" title="Map Location" type="url" />
                            </div>
                            <div className="md:col-span-2">
                                <UserSearchField
                                    title="Admin"
                                    allUserData={userListData}
                                    setValue={setOrganizationAdmin}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end items-center gap-4">
                            <button
                                type="submit"
                                className="btn bg-primary text-white hover:bg-success border-0 flex justify-center items-center gap-x-2"
                            >
                                {loading && <span className="loading loading-spinner"></span>}
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AddOrganization;

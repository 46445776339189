import React from "react";
import Slider from "react-slick";
import TestimonialCard from "./TestimonialCard";

const Testimonial = ({ testimonials }: any) => {
    const settings = {
        arrows: false,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };
    return (
        <div>
            <div>
                <h1 className="text-white text-center pb-6 font-bold">Loved 💚 by Recruiters, HR Teams & Employers</h1>
            </div>

            <Slider {...settings}>
                {testimonials.map((item: any, index: number) => (
                    <div key={index} className="flex items-center justify-center">
                        <TestimonialCard data={item} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Testimonial;

import React from "react";
import { BsCalendar2Check } from "react-icons/bs";
import { dateFormatter } from "../../../utils/dateFormat";
import { IoCloseOutline } from "react-icons/io5";
import { OrganizationType } from "../../../type/OrganizationType";
import { LuImageOff } from "react-icons/lu";

const OrganizationView = ({ item }: { item: OrganizationType }) => {
    const {
        organizationImage,
        organizationName,
        organizationEmailDomain,
        organizationRoutePath,
        organizationAdminEmail,
        organizationAddress,
        organizationMapLocation,
        organizationWebsite,
        organizationDescription,
        updatedAt,
        createdAt
    } = item.attributes;

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.id + "view"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box rounded relative dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg z-10">
                        <label htmlFor={item.id + "view"} className="px-2 py-2 block cursor-pointer">
                            <IoCloseOutline />
                        </label>
                    </div>

                    <div>
                        {organizationImage ? (
                            <img
                                className=" w-full h-40 flex justify-center items-center  object-contain font-bold relative overflow-hidden  object-center rounded-lg"
                                src={organizationImage}
                            />
                        ) : (
                            <div className="border border-black opacity-50 dark:border-white w-full h-40 flex justify-center items-center font-bold rounded-lg text-7xl">
                                <LuImageOff />
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col gap-y-4 pt-6">
                        <div>
                            <h1 className="font-bold">Organization Name</h1>
                            <h1>{organizationName}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Organization Email Domain</h1>
                            <h1>{organizationEmailDomain}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Organization Route Path</h1>
                            <h1>{organizationRoutePath}</h1>
                        </div>
                        {organizationWebsite && (
                            <div>
                                <h1 className="font-bold">Organization Website</h1>
                                <a target="__blank" href={organizationWebsite} className="text-blue-500">
                                    {organizationWebsite}
                                </a>
                            </div>
                        )}
                        <div>
                            <h1 className="font-bold">Organization Address</h1>
                            <h1>{organizationAddress}</h1>
                        </div>
                        {organizationMapLocation && (
                            <div>
                                <h1 className="font-bold">Organization Map Location</h1>
                                <a target="__blank" href={organizationMapLocation} className="text-blue-500">
                                    {organizationMapLocation}
                                </a>
                            </div>
                        )}
                        {organizationDescription && (
                            <div>
                                <h1 className="font-bold">Organization Description</h1>
                                <p className="">{organizationDescription}</p>
                            </div>
                        )}
                        {organizationAdminEmail && (
                            <div>
                                <h1 className="font-bold">Organization Admin</h1>
                                <h1>{organizationAdminEmail}</h1>
                            </div>
                        )}
                        <div>
                            <h1 className="font-bold">Last Updated</h1>
                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(updatedAt)}
                            </div>
                        </div>
                        <div>
                            <h1 className="font-bold">Created At</h1>

                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(createdAt)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrganizationView;

import React, { useContext, useRef, useState } from "react";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { updateData } from "../../../hooks/update";
import { IoMdAdd } from "react-icons/io";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { urlCheck } from "../../../utils/urlCheck";

const LinkFormModal = () => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const formRef = useRef<HTMLFormElement | null>(null);
    const allSocialLinks = userDataManage?.user?.attributes?.social_links || [];
    const [otherLinks, setOthersLinks] = useState(
        allSocialLinks.filter((item: any) => item.type !== "twitter" && item.type !== "linkedin")
    );

    const addMoreLink = () => {
        setOthersLinks([...otherLinks, { type: "", url: "" }]);
    };
    const removeLink = (e: any, indexToRemove: number) => {
        e.preventDefault();
        const updatedLinks = otherLinks.filter((item: any, index: number) => index !== indexToRemove);
        setOthersLinks(updatedLinks);
    };
    const onChangeLink = (e: any, indexToModify: number) => {
        e.preventDefault();

        const updatedLinks = otherLinks.map((item: any, index: number) => {
            if (index === indexToModify) {
                item = { type: urlCheck(e.target.value || "").name, url: e.target.value.toLowerCase() };
            }
            return item;
        });

        setOthersLinks(updatedLinks);
    };
    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="linkModal"]');
        if (form && label) {
            form.reset();
            label.click();
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const data: any = [
            { url: event.target.linkedin.value.toLowerCase(), type: "linkedin" },
            { url: event.target.twitter.value.toLowerCase(), type: "twitter" }
        ];

        updateData({
            Parse: Parse,
            parseItem: userDataManage.user,
            data: { social_links: [...data, ...otherLinks] },
            message: "User Information Updated!",
            refetch: userDataManage.userRefetch,
            closeModal: closeModal
        });
    };
    return (
        <div>
            <input type="checkbox" id="linkModal" className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box bg-white dark:bg-dark">
                    <form ref={formRef} onSubmit={handleSubmit} className=" items-center">
                        <div className="flex flex-col gap-y-3 w-full">
                            <div className="flex items-center border rounded-lg  w-full overflow-hidden">
                                <div className="h-full px-4 text-dark dark:text-white  flex justify-center text-xl scale-[1.2]">
                                    <FaLinkedin />
                                </div>
                                <input
                                    name="linkedin"
                                    type="text"
                                    defaultValue={
                                        allSocialLinks.find((item: any) => item.type === "linkedin")?.url || ""
                                    }
                                    className="py-3 bg-white dark:bg-darkSecondary w-full outline-none"
                                />
                            </div>

                            <div className="flex items-center border rounded-lg  w-full overflow-hidden">
                                <div className="h-full px-4 text-dark dark:text-white  flex justify-center text-xl scale-[1.2]">
                                    <FaTwitter />
                                </div>
                                <input
                                    name="twitter"
                                    type="text"
                                    defaultValue={
                                        allSocialLinks.find((item: any) => item.type === "twitter")?.url || ""
                                    }
                                    className="py-3 bg-white dark:bg-darkSecondary w-full outline-none"
                                />
                            </div>
                            {otherLinks.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className="flex items-center border rounded-lg  w-full overflow-hidden"
                                >
                                    <div className="h-full px-4 text-dark dark:text-white  flex justify-center text-xl scale-[1.2]">
                                        {urlCheck(item?.url || "").icon}
                                    </div>
                                    <input
                                        onChange={(e) => onChangeLink(e, index)}
                                        type="text"
                                        defaultValue={item?.url?.toLowerCase()}
                                        required
                                        className="py-3 bg-white dark:bg-darkSecondary w-full outline-none"
                                    />

                                    <button
                                        onClick={(e) => removeLink(e, index)}
                                        className="text-xl text-dark dark:text-white w-10 flex justify-center"
                                    >
                                        <MdDelete />
                                    </button>
                                </div>
                            ))}
                            <button onClick={addMoreLink} type="button" className="ml-2 text-2xl font-bold">
                                <IoMdAdd />
                            </button>
                        </div>
                        <div className="flex justify-end pt-6 gap-x-2">
                            <label
                                htmlFor="linkModal"
                                className="px-4 py-2 rounded-lg bg-error text-white font-semibold w-fit cursor-pointer"
                            >
                                Cancel
                            </label>
                            <button
                                type="submit"
                                className=" px-4 py-2 border rounded-lg bg-dark dark:bg-white text-white  dark:text-dark font-semibold w-fit"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LinkFormModal;

import React, { useContext, useRef, useState } from "react";
import TurndownService from "turndown";
import showdown from "showdown";
import { IoCloseOutline } from "react-icons/io5";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { updateData } from "../../hooks/update";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import TextEditor from "../Common/TextEditor/TextEditor";
import CustomRadioSelect from "../Common/CustomRadioSelect/CustomRadioSelect";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";
import { JobsType } from "../../type/JobsType";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";

const UpdateJob = ({ refetch, item }: { refetch: any; item: JobsType }) => {
    const turndownService = new TurndownService();
    const converter = new showdown.Converter();
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const { positionName, jobCategory, jobSummary, jobDetails, jobType, workMode, location, deadline, objectId } = item;
    const [jobDataParse, setJobDataParse] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [textEditor, setTextEditor] = useState(converter.makeHtml(jobDetails));
    const [error, setError] = useState(false);
    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>(`[for="${item.objectId + "update"}"]`);
        if (label) {
            label.click();
            setError(false);
        }
    };
    const handleRevert = () => {
        const form = formRef.current;
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                popup: darkMode ? "swal-dark-mode" : "swal-light-mode",
                confirmButton: darkMode ? "swal-dark-mode-confirm" : "swal-light-mode-confirm",
                cancelButton: darkMode ? "swal-dark-mode-cancel" : "swal-light-mode-cancel"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (form) {
                    if (jobDataParse?.input) {
                        const htmlOutput = converter.makeHtml(`${jobDataParse?.input?.job_description}`);
                        setTextEditor(htmlOutput);
                        form.jobSummary.value = jobDataParse?.input?.job_summary;
                        form.positionName.value = jobDataParse?.input?.job_title;
                    }
                }
            }
        });
    };
    const handleMagicButton = async () => {
        setLoading(true);
        const form = formRef.current;
        if (form) {
            const data = {
                job_description: turndownService.turndown(textEditor),
                job_title: form.positionName.value,
                job_summary: form.jobSummary.value
            };
            try {
                const response = await fetch(new URL("/resume_parser/v1/job/fix", process.env.REACT_APP_BACKEND_URL), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
                if (response.ok) {
                    const result = await response.json();
                    if (result?.output) {
                        const htmlOutput = converter.makeHtml(`${result?.output?.job_description}`);
                        setTextEditor(htmlOutput);
                        form.jobSummary.value = result?.output?.job_summary;
                        form.positionName.value = result?.output?.job_title;
                        setJobDataParse(result);
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                    toast.error("Internal Server Error", {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            } catch (error) {
                setLoading(false);
                toast.error("Internal Server Error", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const form = formRef.current;
        if (form) {
            setError(false);
            setLoading(true);
            try {
                const fingerPrintData = await getUserFingerPrint();
                const jobDetailsMarkdown = turndownService.turndown(textEditor);
                const data: any = {
                    positionName: form.positionName.value,
                    jobCategory: form.jobCategory.value,
                    jobType: form.jobType.value,
                    workMode: form.workMode.value,
                    location: form.location.value,
                    deadline: form.deadline.value,
                    jobDetails: jobDetailsMarkdown,
                    jobSummary: form.jobSummary.value,
                    fingerPrint: fingerPrintData
                };
                const query = new Parse.Query("Jobs");
                const objectId = item.objectId;
                const object = await query.get(objectId);
                Object.keys(data).forEach((key) => {
                    object.set(key, data[key]);
                });
                await object.save();
                toast.success("Jobs Updated", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setLoading(false);
                refetch();
            } catch (error: any) {
                toast.error(error?.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                setLoading(false);
            }
        }
    };

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.objectId + "update"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl rounded dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>
                    <div>
                        <h1 className="font-medium text-xl pb-2">Update Reference.</h1>
                        <p>Please fill up the form and hit submit.</p>
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit} className={`${loading ? "opacity-50" : " "} relative`}>
                        {loading && (
                            <div className="absolute h-full top-0 w-full  bg-white/60 dark:bg-darkBG/95 z-[999]">
                                <div className="flex justify-center items-center h-[65%]">
                                    <h1 className="font-semibold text-lg">Processing....</h1>
                                </div>
                            </div>
                        )}
                        <div className="py-6 grid grid-cols-1 md:grid-cols-2 gap-4 ">
                            <div className="md:col-span-2">
                                <CustomInputField
                                    value={positionName}
                                    name="positionName"
                                    title="Position Name"
                                    type="text"
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <div className=" md:col-span-2 min-h-[10rem]">
                                <TextEditor
                                    textEditor={textEditor}
                                    setTextEditor={setTextEditor}
                                    title="Job Details"
                                    magicButton={turndownService.turndown(textEditor).length > 0}
                                    error={error}
                                    handleMagicButton={handleMagicButton}
                                    handleRevert={jobDataParse ? handleRevert : false}
                                    disabled={loading}
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    value={jobSummary}
                                    name="jobSummary"
                                    title="Job Summary"
                                    type="text"
                                    required
                                    disabled={loading}
                                    textArea
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    value={jobCategory}
                                    name="jobCategory"
                                    title="Job Category"
                                    type="text"
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomRadioSelect
                                    selected={jobType}
                                    list={["Full-time", "Part-time", "Contract", "Internship"]}
                                    name="jobType"
                                    title="Employment type"
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomRadioSelect
                                    selected={workMode}
                                    list={["On-site", "Remote", "Hybrid"]}
                                    name="workMode"
                                    title="Work Mode"
                                    required
                                    disabled={loading}
                                />
                            </div>
                            <CustomInputField
                                value={location}
                                name="location"
                                title="Location"
                                type="text"
                                required
                                disabled={loading}
                            />
                            <CustomInputField
                                value={deadline}
                                name="deadline"
                                title="Deadline"
                                type="date"
                                required
                                disabled={loading}
                            />
                        </div>

                        <div className="flex justify-end items-center gap-4">
                            <button
                                type="submit"
                                className="btn bg-primary text-white hover:bg-success border-0 flex justify-center items-center gap-x-2"
                            >
                                {loading && <span className="loading loading-spinner"></span>}
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UpdateJob;

import React from "react";
import ResumeInfoGenerator from "./ResumeInfoGenerator";
import ContactGroup from "./ContactGroup";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa6";

import { FaCalendarDays } from "react-icons/fa6";
import { toast } from "react-toastify";
import { ResumeData } from "../../type/ResumeType";

const InformationBlock = ({ data }: { data: ResumeData }) => {
    const name = (data?.first_name || "") + " " + (data?.last_name || "");

    const filteredLocation = data?.location
        ? Object.fromEntries(Object?.entries(data?.location).filter(([_, value]) => value !== null))
        : {};

    let locationString = ``;
    if (Object.keys(filteredLocation).length !== 0) {
        locationString = `${data?.location?.street_address && data?.location.street_address + " "}${
            data?.location?.city && data?.location.city + " "
        }${data?.location?.code && data?.location.code + " "}${data?.location?.state && data?.location.state + " "}${
            data?.location?.country && data?.location.country + " "
        }`;
    }

    let copyData = [];

    if (data?.contact?.phone) {
        copyData.push(`Phone: ${data?.contact.phone}`);
    }
    if (data?.contact?.email) {
        copyData.push(`Email: ${data?.contact.email}`);
    }

    const formattedLinks = data?.contact
        ? Object.entries(data?.contact.socials)
              .filter(([key, value]) => value !== null)
              .map(([key, value]) => `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`)
        : [];

    copyData = [...copyData, ...formattedLinks];

    const candidateInformation = `HRHouz resume scanner Candidate Information results for ${name}’s Resume:\n\nName: ${name}\n${
        data?.date_of_birth ? "Date of Birth: " + data?.date_of_birth + "\n" : ""
    }${locationString.length > 0 ? "Address: " + locationString + "\n" : ""}${copyData.join("\n")}\n\n${
        data?.summary && "Summary: " + data?.summary
    }\n\npowered by HRHouz (https://www.hrhouz.com/)`;

    return (
        <div>
            <div className="mb-4 flex min-w-[4rem] items-center gap-x-2 lg:min-w-[6rem]">
                <h1 className="font-semibold">Candidate Information</h1>
                <CopyToClipboard onCopy={() => toast.success(` Copied!`)} text={candidateInformation}>
                    <button className="text-primary">
                        <FaRegCopy />
                    </button>
                </CopyToClipboard>
            </div>
            {data?.first_name && <ResumeInfoGenerator title={"Name"} data={name} />}

            {data?.contact && <ContactGroup title="Contact" data={data} />}
            {data?.summary && <ResumeInfoGenerator paragraph title={"Summary"} data={data?.summary} />}
            {data?.date_of_birth && (
                <ResumeInfoGenerator logo={<FaCalendarDays />} title={"Date of Birth"} data={data?.date_of_birth} />
            )}
            {locationString.length > 0 && <ResumeInfoGenerator location title={"Location"} data={locationString} />}
        </div>
    );
};

export default InformationBlock;

import React from "react";
import locationLogo from "../../assets/images/pdf/location.png";
import ip from "../../assets/images/pdf/ip.png";
import device from "../../assets/images/pdf/device.png";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./PDFStyle";

const UserLocation = ({ location, ipAddress, deviceInfo, deviceScreenSize, timezone }: any) => {
    return (
        <View style={{ width: "35%" }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                <View style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    {(location?.city || location?.region || location?.country) && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Image src={locationLogo} style={styles.icon} />
                            <View>
                                {location?.city && (
                                    <Text style={{ ...styles.fontSmall, opacity: 0.8 }}> {location.city}</Text>
                                )}
                                {(location?.region || location?.country) && (
                                    <Text style={{ ...styles.fontVerySmall, paddingLeft: 4 }}>
                                        {location.region} {location.country && <>,</>} {location.country}
                                    </Text>
                                )}
                            </View>
                        </View>
                    )}
                    {(ipAddress?.ipAddressV4 || ipAddress.ipAddressV6) && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 3, marginTop: 4 }}>
                            <Image src={ip} style={styles.icon} />

                            <View>
                                {ipAddress?.ipAddressV4 && (
                                    <Text style={{ ...styles.fontVerySmall }}>IPv4/:{ipAddress.ipAddressV4}</Text>
                                )}
                                {ipAddress.ipAddressV6 && (
                                    <Text style={styles.fontVerySmall}>IPv6/: {ipAddress.ipAddressV6}</Text>
                                )}
                            </View>
                        </View>
                    )}
                    {(deviceInfo || deviceScreenSize || timezone) && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 3, marginTop: 4 }}>
                            <Image src={device} style={styles.icon} />
                            <View>
                                {deviceInfo && <Text style={{ ...styles.fontSmall, opacity: 0.8 }}>{deviceInfo}</Text>}
                                {(deviceScreenSize || timezone) && (
                                    <Text style={styles.fontVerySmall}>
                                        {deviceScreenSize},{timezone}
                                    </Text>
                                )}
                            </View>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

export default UserLocation;

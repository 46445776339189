import React from "react";

const PieChartMini = ({ data }: { data: number[] }) => {
    const total = data.reduce((acc, value) => acc + value, 0);
    let startAngle = 0;

    const colors = ["#00A76F", "#FABD23", "#D32F2F"].reverse();

    return (
        <div className="relative h-[2.35rem] w-[2.35rem] sm:h-[2.8rem] sm:w-[2.8rem] ">
            <svg viewBox="0 0 40 40" className="absolute inset-0 w-full h-full">
                {data.map((value, index) => {
                    const percentage = (value / total) * 100;
                    const largeArcFlag = percentage > 50 ? 1 : 0;
                    const endAngle = startAngle + percentage * 3.6;

                    const startX = 20 + 20 * Math.cos((startAngle - 90) * (Math.PI / 180));
                    const startY = 20 + 20 * Math.sin((startAngle - 90) * (Math.PI / 180));

                    const endX = 20 + 20 * Math.cos((endAngle - 90) * (Math.PI / 180));
                    const endY = 20 + 20 * Math.sin((endAngle - 90) * (Math.PI / 180));

                    const pathData = `M 20 20 L ${startX} ${startY} A 20 20 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;

                    startAngle = endAngle;

                    return <path key={index} d={pathData} fill={colors[index]} />;
                })}
                <defs>
                    {colors.map((color, index) => (
                        <linearGradient key={index} id={color} gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={color} />
                            <stop offset="100%" stopColor={color} />
                        </linearGradient>
                    ))}
                </defs>
            </svg>
        </div>
    );
};

export default PieChartMini;

import React from "react";

const TabSwitch = ({ activeTab, setActiveTab }: any) => {
    const tabs = ["General", "Links"];

    const handleTab = (event: any) => {
        setActiveTab(parseInt(event.target.id, 10)); // Parse the id as an integer
    };

    return (
        <div className="flex justify-center p-4 sticky top-[4.2rem] w-full   bg-primaryBG  dark:bg-dark mb-10 ">
            <div className="tabs">
                {tabs.map((item, index) => (
                    <button
                        onClick={handleTab}
                        id={index.toString()}
                        type="button"
                        className={`tab tab-bordered dark:text-white   dark:border-gray-500 ${
                            activeTab === index && "tab-active dark:border-[#FFFFFF!important]"
                        }`}
                        key={index} // Add a unique key to each button
                    >
                        {item}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default TabSwitch;

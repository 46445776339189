import React from "react";
import { ChildrenProps } from "../../type/Types";

const CustomSecurity = ({ children }: ChildrenProps) => {
    // Disable Right Click
    const disableRightClick = (e: MouseEvent) => {
        e.preventDefault();
    };
    // Disable F12 Key
    const disableF12Key = (e: KeyboardEvent) => {
        if (e.key === "F12") {
            e.preventDefault();
        }
    };
    // Disable Ctrl+Shift+i Action
    const disableCtrlShiftI = (e: KeyboardEvent) => {
        if (e.ctrlKey && e.shiftKey && e.key === "I") {
            e.preventDefault();
        }
    };
    if (process.env.REACT_APP_SECURITY_ENABLED === "true") {
        document.addEventListener("contextmenu", disableRightClick);
        document.addEventListener("keydown", disableF12Key);
        document.addEventListener("keydown", disableCtrlShiftI);
    }
    return <> {children}</>;
};

export default CustomSecurity;

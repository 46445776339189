import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useFetch = ({ Parse, dataClass, dataQuery, id, total }: any) => {
    const [data, setData] = useState<{ totalCount: number; result: any } | null>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refetchData, setRefetchData] = useState(false);
    const abortController = new AbortController();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const query = new Parse.Query(dataClass);

                if (dataQuery) {
                    dataQuery.forEach((element: { key: string; item: string }) => {
                        element.item && query.equalTo(element.key, element.item);
                    });
                }
                const totalCount = await query.count();

                if (total?.perPage) {
                    query.limit(total?.perPage);
                    query.skip(total?.perPage * (total?.page - 1));
                    query.descending("createdAt");
                }

                // Set a timeout promise
                const timeoutPromise = new Promise<any>((_, reject) => {
                    setTimeout(() => {
                        reject(new Error("Request timeout"));
                        abortController.abort();
                    }, 4500);
                });

                // Make the API request and race it with the timeout promise
                const result = await Promise.race([id ? query.get(id) : query.find(), timeoutPromise]);

                setData({ totalCount, result });
                setLoading(false);
            } catch (error: any) {
                if (error.message === "Request timeout") {
                    setData({ totalCount: 0, result: [] });
                    setError(error.message);
                } else {
                    setError(error.message);
                    setData({ totalCount: 0, result: [] });
                    toast.error("Service is unavailable!", { autoClose: 10000 });
                }
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            abortController.abort();
        };
    }, [total?.page, total?.perPage, refetchData, JSON.stringify(dataQuery)]);

    const refetch = () => setRefetchData((prevState) => !prevState);

    return [data, loading, refetch, error];
};

export default useFetch;

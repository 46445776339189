import React, { useContext, useState } from "react";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import useFetch from "../../hooks/useFetch";

import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import Table from "../../components/Common/Table/Table";

import { RefereeType } from "../../type/RefereeType";
import { TableData } from "../../type/Table";

import image from "../../assets/images/header/person.jpg";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { DateComponent, ProfileComponent, StatusComponent } from "../../components/Common/Table/UtilityComponent";
import useCategoryCheck from "../../hooks/useCategoryCheck";
import { useLocation } from "react-router-dom";
import { pageData } from "../../content/constantVariable";

interface RefereeDataType {
    totalCount: number;
    result: RefereeType[];
}

const RefereePage = () => {
    const statusItems = new Set(["all", "pending", "completed", "completed"]);
    const location = useLocation();
    const statusParam = new URLSearchParams(location.search).get("status");
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const viewUser = userListData && userListData.usersList[0];
    const [currentStatus, setCurrentStatus] = useState(
        statusParam ? (statusItems.has(statusParam) ? statusParam : "all") : "all"
    );
    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "Referee",
        dataQuery: [
            {
                key: "refereeEmail",
                item: adminModeData?.adminMode && isAdmin ? viewUser?.email : userDataManage.user?.attributes.email
            },
            { key: "status", item: currentStatus === "all" ? "" : currentStatus }
        ],

        statusQuery: currentStatus === "all" ? "" : currentStatus,
        total: { perPage: totalData, page: page },
        alert: true
    });
    const [statusData, statusLoading, filterRefetch] = useCategoryCheck({
        Parse: Parse,
        dataClass: "RefereeStatusCount",
        query: {
            refereeEmail: adminModeData?.adminMode && isAdmin ? viewUser?.email : userDataManage.user?.attributes.email
        }
    });
    if (loading || statusLoading) {
        return <Loading />;
    }

    const tableBody = (data as RefereeDataType).result.map((item) => {
        return {
            id: item.id,
            candidate: (
                <ProfileComponent
                    image={item.attributes.candidateImage || image}
                    name={item.attributes.candidateFirstname + " " + item.attributes.candidateLastname}
                />
            ),
            requestor: (
                <ProfileComponent
                    image={image}
                    name={item.attributes.requestorFirstname + " " + item.attributes.requestorLastname}
                    email={item.attributes.requestorEmail}
                    phone={item.attributes.requestorPhoneNumber}
                />
            ),
            createdAt: <DateComponent date={item.attributes.createdAt} />,
            updatedAt: <DateComponent date={item.attributes.updatedAt} />,
            status: <StatusComponent status={item.attributes.status} />,
            action: item.attributes.status === "pending" ? true : false
        };
    });

    const tableData = {
        columnItems: ["Candidate", "Requestor", "Date Initiated", "Last Updated", "Status", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as RefereeDataType).totalCount
        },
        refetch: refetch,
        dataClass: "Referee",
        filter: statusData,
        currentStatus: currentStatus,
        statusUpdate: setCurrentStatus,
        filterRefetch: filterRefetch
    };

    return (
        <section className="py-10 ">
            <HelmetConfiguration seoData={SeoContent["giveSeo"] || SeoContent.baseSeo} />
            <TableLayer>
                <Table button={{ title: "Give Reference" }} tableData={tableData as TableData} />
            </TableLayer>
            {error && <RetryModal refetch={refetch} />}
        </section>
    );
};

export default RefereePage;

import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";

const LineChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    const chartOptions: ApexCharts.ApexOptions = {
        chart: {
            type: "line",
            zoom: {
                type: "x",
                enabled: false,
                autoScaleYaxis: true
            },
            toolbar: {
                show: false // Disable the export/download option
            }
        },
        tooltip: {
            theme: darkMode ? "dark" : "light" // Change the tooltip theme based on darkMode
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: "smooth"
        },
        title: {
            text: data.title,
            align: "left",
            style: {
                fontSize: "24px",
                fontWeight: "700",
                fontFamily: "Public Sans",
                color: darkMode ? "white" : "black"
            }
        },
        grid: {
            row: {
                colors: ["transparent"],
                opacity: 0.5
            }
        },
        markers: {
            size: 0
        },

        xaxis: {
            categories: data.categories,
            axisTicks: {
                show: false
            },
            labels: {
                style: {
                    colors: darkMode ? "white" : "black",
                    fontSize: "12px"
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: darkMode ? "white" : "black",
                    fontSize: "12px"
                }
            }
        },

        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            fontSize: "16px",
            offsetY: -45,
            fontWeight: "700",
            fontFamily: "Public Sans",
            labels: {
                colors: darkMode ? "white" : "black"
            }
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        line: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "top",
                        horizontalAlign: "center",
                        fontSize: "10px",
                        offsetY: 0
                    },
                    title: {
                        style: {
                            fontSize: "14px"
                        }
                    }
                }
            }
        ]
    };

    return <ReactApexChart options={chartOptions} series={data.chartSeries} type="line" />;
};

export default LineChart;

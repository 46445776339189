import React, { useContext } from "react";
import { statusColorCheck } from "../../../utils/statusColor";
import { dateFormatter } from "../../../utils/dateFormat";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
import { BsCalendar3 } from "react-icons/bs";
import Input from "react-phone-number-input/input";
import { Link } from "react-router-dom";
import { FaCheck, FaGlobe, FaRegFilePdf } from "react-icons/fa6";
import { LuImageOff } from "react-icons/lu";

export const ProfileComponent = ({
    id,
    image,
    name,
    email,
    phone,
    resumeUrl,
    emailDomain,
    square
}: {
    id?: string;
    image?: string;
    name: string;
    email?: string;
    phone?: string;
    resumeUrl?: string;
    emailDomain?: string;
    square?: boolean;
}) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const copyText = ({ label, text }: { label: string; text: string }) => {
        const tempInput = document.createElement("input");
        tempInput.value = text;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        toast.success(`${label} Copied!`, { theme: darkMode ? "dark" : "light" });
    };
    return (
        <div className=" flex items-center gap-x-2 text-sm md:text-base min-w-[18rem]">
            {square ? (
                <div className={` w-20 h-12 overflow-hidden object rounded-md `}>
                    {image ? (
                        <img src={image} alt="person" className="w-20 h-12  object-contain" />
                    ) : (
                        <div className="w-full flex justify-center items-center opacity-50  h-full font-semibold text-5xl">
                            <LuImageOff />
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-12 h-12 rounded-full overflow-hidden">
                    <img src={image} alt="person" className="w-12 h-12 object-cover" />
                </div>
            )}
            <div>
                <div className="flex items-center gap-x-1">
                    {resumeUrl && (
                        <label htmlFor={id} className="hover:text-blue-500 duration-300 cursor-pointer">
                            <FaRegFilePdf />
                        </label>
                    )}
                    <h1 className="font-medium capitalize">{name}</h1>
                </div>

                {email && (
                    <div className="flex  gap-x-1 dataHover">
                        <a
                            href={`mailto: ${email}`}
                            className="text-secondary/80 dark:text-white/60 flex items-center gap-x-1 hover:!text-blue-500 duration-200 break-all leading-4 "
                        >
                            <span className="h-full mt-1">
                                <AiOutlineMail />
                            </span>
                            <span>{email}</span>
                        </a>
                        <button
                            onClick={() => copyText({ label: "Email", text: email })}
                            className="hover:text-blue-500 duration-200 opacity-0"
                        >
                            <FaRegCopy />
                        </button>
                    </div>
                )}

                {emailDomain && (
                    <div className="flex  gap-x-1 dataHover">
                        <p className="text-secondary/80 dark:text-white/60 flex items-center gap-x-1 hover:!text-blue-500 duration-200 break-all leading-4 ">
                            <span className="h-full mt-1">
                                <FaGlobe />
                            </span>
                            <span>{emailDomain}</span>
                        </p>
                        <button
                            onClick={() => copyText({ label: "Email", text: emailDomain })}
                            className="hover:text-blue-500 duration-200 opacity-0"
                        >
                            <FaRegCopy />
                        </button>
                    </div>
                )}

                {phone && (
                    <div className="flex items-center gap-x-1 dataHover cursor-pointer">
                        <a
                            href={`tel:${phone}`}
                            className="text-secondary/80 dark:text-white/60 flex items-start gap-x-1 hover:!text-blue-500 break-all "
                        >
                            <span className="mt-1">
                                <AiOutlinePhone />
                            </span>

                            <span className="max-w-[8rem] overflow-hidden">
                                <Input
                                    value={phone}
                                    onChange={() => null}
                                    withCountryCallingCode
                                    disabled={true}
                                    className="w-44 text-sm bg-inherit"
                                />
                            </span>
                        </a>
                        <button
                            onClick={() => copyText({ label: "Phone", text: phone })}
                            className="hover:text-blue-500 duration-200 opacity-0"
                        >
                            <FaRegCopy />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export const StatusComponent = ({
    status,
    id,
    candidateId,
    response,
    minRefereeCount,
    refereeResponseCount = 0,
    isCandidate
}: {
    status: string;
    id?: string;
    candidateId?: string;
    refereeResponseCount?: number;
    response?: any[];
    minRefereeCount?: number;
    isCandidate?: boolean;
}) => {
    return (
        <div className="w-[6.5rem] flex flex-col items-center justify-center">
            <h2 className={`${statusColorCheck(status)} px-2 py-1 rounded capitalize text-white font-semibold w-fit `}>
                {status}
            </h2>
            {status === "referee" && id && (response as any)?.length > 0 && minRefereeCount && (
                <Link
                    to={`/employer/${id}`}
                    className="text-info underline underline-offset-4 font-medium flex items-center gap-x-1"
                >
                    {`${(response as any).length} of ${minRefereeCount} `}
                    <span className="text-success ">
                        <FaCheck />
                    </span>
                </Link>
            )}
            {isCandidate && minRefereeCount && (status === "referee" || status === "completed") && (
                <Link
                    to={`/candidate/${candidateId}`}
                    className="text-info underline underline-offset-4 font-medium flex items-center gap-x-1"
                >
                    {`${refereeResponseCount} of ${minRefereeCount} `}
                    <span className="text-success ">
                        <FaCheck />
                    </span>
                </Link>
            )}
            {status === "analyzing" && id && (
                <Link to={`/employer/${id}`} className="text-info underline underline-offset-4 font-medium">
                    Raw Results
                </Link>
            )}
            {status === "completed" && id && (
                <Link to={`/employer/${id}`} className="text-info underline underline-offset-4 font-medium">
                    Results
                </Link>
            )}
        </div>
    );
};

export const DateComponent = ({ date }: { date: string }) => {
    const timeDifference: number = Math.floor(
        Math.abs(new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
    );
    let dateRangeColor;
    if (timeDifference < 2) {
        dateRangeColor = "text-green-500";
    } else if (timeDifference >= 2 && timeDifference <= 5) {
        dateRangeColor = "text-orange-500";
    } else {
        dateRangeColor = "text-red-500";
    }

    return (
        <div className="!min-w-[10rem]">
            <div className="flex items-start gap-x-2 ">
                <span className="text-lg">
                    <BsCalendar3 />
                </span>

                <div>
                    <span className="text-sm">{dateFormatter(date)}</span>
                    <div className="text-xs ">
                        <span className={dateRangeColor}>{`${timeDifference} days ago`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

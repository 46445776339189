import React from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

import Pagination from "../Pagination/Pagination";
import { TableData } from "../../../type/Table";
import TableSearch from "../TableSearch/TableSearch";
import Filter from "./Filter";

const Table = ({
    tableData,
    action,
    button
}: {
    tableData: TableData;
    action?: boolean;
    button?: { title: string };
}) => {
    const { columnItems, tableBody, refetch, pagination, dataClass } = tableData;
    const { total, page, setTotal, setPage, totalItemCount } = pagination;

    return (
        <div className="relative overflow-x-auto z-0 shadow-lg  my-10 min-h-[78.6vh] bg-white dark:bg-darkSecondary xl:rounded-lg flex flex-col justify-between ">
            <div>
                {tableData.filter && (
                    <Filter
                        currentStatus={tableData.currentStatus}
                        statusUpdate={tableData.statusUpdate}
                        data={tableData.filter}
                        refetch={tableData.refetch}
                    />
                )}
                <TableSearch />
                <table className="table border-b-[1px] border-secondary/10 dark:border-white/10">
                    <TableHead columnItems={columnItems} />
                    {tableBody.length > 0 && (
                        <tbody>
                            {tableBody.map((item: { attributes: any }, index: any) => (
                                <TableBody
                                    key={index}
                                    data={item}
                                    action={action}
                                    button={button}
                                    lastItem={tableBody.length === index + 1}
                                    refetch={refetch}
                                    filterRefetch={tableData?.filterRefetch}
                                    dataClass={dataClass}
                                />
                            ))}
                        </tbody>
                    )}
                </table>
                {tableBody.length === 0 && <h1 className="text-center font-bold py-4">No Data Found!</h1>}
            </div>
            <Pagination
                page={page}
                itemCount={total}
                setItemCount={setTotal}
                setPage={setPage}
                totalItemCount={totalItemCount}
            />
        </div>
    );
};

export default Table;

import { View, Text } from "@react-pdf/renderer";
import React from "react";

const SingleRegularObjectivePDF = ({ data }: any) => {
    return (
        <View
            style={{
                backgroundColor: "#FAFAFA",
                padding: 10,
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                gap: 3
            }}
        >
            {Object.keys(data.options).map((key) => (
                <View key={key} style={{ display: "flex", flexDirection: "row", gap: "3", alignItems: "center" }}>
                    <View
                        style={{
                            width: 10,
                            height: 10,
                            padding: 4,
                            border: 1.5,
                            borderColor: "#3B82F6",
                            borderRadius: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {data.response === key && (
                            <View
                                style={{
                                    width: 4,
                                    height: 4,
                                    padding: 2,
                                    backgroundColor: "#3B82F6",
                                    borderRadius: "100%"
                                }}
                            ></View>
                        )}
                    </View>
                    <Text
                        style={{
                            fontSize: 10
                        }}
                    >
                        {data.options[key]}
                    </Text>
                </View>
            ))}
        </View>
    );
};

export default SingleRegularObjectivePDF;

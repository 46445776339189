import React from "react";
import ObjectiveQuestionResponse from "./ObjectiveQuestionResponse";
import SubjectiveQuestionResponse from "./SubjectiveQuestionResponse";
import VideoQuestionResponse from "./VideoQuestionResponse";

const QuestionResponse = ({ response }: any) => {
    const { questionObjective, questionSubjective, questionVideo } = response;
    return (
        <div>
            <ObjectiveQuestionResponse question={questionObjective} />
            <SubjectiveQuestionResponse question={questionSubjective} />
            <VideoQuestionResponse question={questionVideo} />
        </div>
    );
};

export default QuestionResponse;

import { toast } from "react-toastify";

export const deleteItem = ({ Parse, dataClass, dataId, refetch }: any) => {
    const MyObject = Parse.Object.extend(dataClass);
    const query = new Parse.Query(MyObject);

    query
        .get(dataId)
        .then((object: any) => {
            return object.destroy();
        })
        .then(() => {
            refetch.map((item: () => void) => item());
            toast.info("Deleted", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        })
        .catch((error: any) => {
            toast.error(error, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        });
};

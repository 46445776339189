import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useCloudFunction = ({ Parse, cloudFunctionName, cloudFunctionParams }: any) => {
    const [data, setData] = useState<{ totalCount: number; result: any } | null>(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refetchData, setRefetchData] = useState(false);
    const abortController = new AbortController();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Set a timeout promise
                const timeoutPromise = new Promise<any>((_, reject) => {
                    setTimeout(() => {
                        reject(new Error("Request timeout"));
                        abortController.abort();
                    }, 4500);
                });

                // Call the cloud function and race it with the timeout promise
                const result = await Promise.race([
                    Parse.Cloud.run(cloudFunctionName, cloudFunctionParams, { signal: abortController.signal }),
                    timeoutPromise
                ]);

                const totalCount = result.totalJobs || 0;
                setData({ totalCount, result: result?.data ? result.data : [] });
                setLoading(false);
            } catch (error: any) {
                if (error.message === "Request timeout") {
                    setError(error.message);
                } else {
                    setError(error.message);
                    toast.error("Service is unavailable!", { autoClose: 10000 });
                }
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            abortController.abort();
        };
    }, [refetchData, JSON.stringify(cloudFunctionParams)]);

    const refetch = () => setRefetchData((prevState) => !prevState);

    return [data, loading, refetch, error];
};

export default useCloudFunction;

import React from "react";
import errorImage from "../../assets/images/error/error.svg";
import { Link } from "react-router-dom";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const ErrorPage = () => {
    return (
        <>
            <HelmetConfiguration seoData={SeoContent["errorSeo"] || SeoContent.baseSeo} />
            <div className="flex flex-col justify-center items-center min-h-screen gap-y-8 xl:w-[25rem] mx-auto text-center">
                <h1 className="text-4xl font-bold">Sorry, Page Not Found!</h1>
                <p>
                    Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to
                    check your spelling.
                </p>
                <img src={errorImage} alt="" />

                <Link
                    to="/"
                    className="bg-black py-3 px-4 rounded-lg text-white font-medium dark:bg-white dark:text-black"
                >
                    Go Back
                </Link>
            </div>
        </>
    );
};

export default ErrorPage;

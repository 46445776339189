import React from "react";
import image from "../../../assets/images/header/person.jpg";
import { ProfileComponent } from "../../Common/Table/UtilityComponent";
import { FaCalendarDays } from "react-icons/fa6";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { dateFormatter } from "../../../utils/dateFormat";
import { statusColorCheck } from "../../../utils/statusColor";
import FingerPrintInformation from "./FingerPrintInformation";
import AnalyticsSection from "../../Common/Analytics/AnalyticsSection";

const CandidateTab = ({ activeTab, handleTab, data }: any) => {
    const {
        response,
        candidateImage,
        candidateFirstname,
        candidateLastname,
        candidateEmail,
        candidatePhoneNumber,
        candidateFingerPrint,
        createdAt,
        status,
        resumeUrl,
        analytics
    } = data.attributes;

    const { geoLocation, geoLocationByIP, ipAddressV4, ipAddressV6, deviceInfo, deviceScreenSize, timezone } =
        candidateFingerPrint;

    const location = {
        city: geoLocation?.city || geoLocationByIP?.city || null,
        region: geoLocation?.region || geoLocationByIP?.region || null,
        country: geoLocation?.country || geoLocationByIP?.country || null
    };
    const video: any[] = [];

    response.map((item: any, index: number) =>
        video.push({ name: "Referee#" + (index + 1), video: item.response.questionVideo[0].response })
    );

    return (
        <div>
            <div className="md:max-w-[50rem] mx-auto">
                <div className="border-2 p-4 rounded-lg mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-4  ">
                    <div className="md:col-span-3">
                        <h1 className="font-semibold pb-3">Candidate Information</h1>
                        <ProfileComponent
                            id={data.id}
                            image={candidateImage || image}
                            name={candidateFirstname + " " + candidateLastname}
                            email={candidateEmail}
                            phone={candidatePhoneNumber}
                            resumeUrl={resumeUrl}
                        />
                    </div>
                    <div className="flex flex-col gap-y-3">
                        <div>
                            <h2 className="flex items-center gap-x-2">
                                <span className="-mt-1 tooltip" data-tip="Initiated Date">
                                    <FaCalendarDays />
                                </span>
                                <span className="text-sm"> {dateFormatter(createdAt)}</span>
                            </h2>
                        </div>
                        <div className="flex items-center  gap-x-2">
                            <div className="tooltip" data-tip="Status">
                                <BsFillInfoCircleFill />
                            </div>
                            <div className="">
                                <h2
                                    className={`${statusColorCheck(
                                        status
                                    )} px-2 py-1 rounded capitalize text-white font-semibold w-fit text-xs`}
                                >
                                    {status}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="md:col-span-2">
                        <FingerPrintInformation
                            location={location}
                            ipAddress={{ ipAddressV4, ipAddressV6 }}
                            deviceInfo={deviceInfo}
                            deviceScreenSize={deviceScreenSize}
                            timezone={timezone}
                        />
                    </div>
                </div>
                {analytics && (
                    <div className="w-full">
                        <AnalyticsSection
                            analytics={analytics}
                            refereeCount={response.length}
                            subjectiveQuestionCount={response[0]?.questionSubjective?.length}
                            video={video}
                            defaultOpen
                        />
                    </div>
                )}
            </div>

            <div className=" flex justify-center mt-6">
                <div className=" flex">
                    {response.map((item: any, index: number) => (
                        <button
                            onClick={handleTab}
                            id={index.toString()}
                            type="button"
                            className={`border-b-2 duration-300 text-xs md:text-sm border-gray-300 px-2 dark:text-white   dark:border-gray-500 ${
                                activeTab === index && "!border-dark  dark:border-[#FFFFFF!important] font-semibold"
                            }`}
                            key={index}
                        >
                            Referee #{index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CandidateTab;

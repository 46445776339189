import React, { useEffect, useState } from "react";
import SummaryCard from "../SummaryCard";
import PieChart from "../PieChart";
import LineChart from "../LineChart";
import { lineCartData } from "../../../content/chartContent";

const AnalyticsComponents = ({ analytics }: any) => {
    const { employer, candidate, referee } = analytics as any;
    const data = [
        { title: "employer", item: employer },
        { title: "candidate", item: candidate },
        { title: "referee", item: referee }
    ];
    const filterData = data.filter((element: any) => element.item.counts.all > 0);
    const [tab, setTab] = useState<any>(filterData[0]);
    // Watch for changes in the analytics prop
    useEffect(() => {
        const newFilterData = data.filter((element: any) => element.item.counts.all > 0);
        setTab(newFilterData[0]);
    }, [analytics]);

    return (
        <section>
            {filterData.length > 0 && (
                <div>
                    <div className="flex justify-center md:p-4 sticky top-[4rem] w-full   bg-primaryBG  dark:bg-dark mb-10 z-[999]">
                        <div className="tabs">
                            {filterData.map((item, index) => (
                                <button
                                    onClick={() => setTab(item)}
                                    type="button"
                                    className={`tab tab-bordered dark:text-white   dark:border-gray-500 capitalize ${
                                        tab?.title === item?.title && "tab-active dark:border-[#FFFFFF!important] "
                                    }`}
                                    key={index}
                                >
                                    {item?.title}
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-16 mr-2">
                        {tab?.item.groupCardData.map((item: any, index: number) => (
                            <SummaryCard name={tab?.title} key={index} data={item} />
                        ))}
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-16  mr-2">
                        {tab?.item?.pieChartData && (
                            <div className="  py-4 2xl-16 md:p-10 2xl:px-16 rounded-lg shadow-lg w-full bg-white dark:bg-darkSecondary h-fit flex justify-center">
                                <PieChart data={tab?.item?.pieChartData} />
                            </div>
                        )}
                        <div className=" py-4 2xl-16 md:p-10 2xl:px-16 rounded-lg shadow-lg w-full bg-white dark:bg-darkSecondary h-fit">
                            <LineChart data={lineCartData} />
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default AnalyticsComponents;

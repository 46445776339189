import React, { useContext, useState } from "react";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";

import ResponseTab from "../../components/Employer/EmployerResponse/ResponseTab";
import ResponseBody from "../../components/Employer/EmployerResponse/ResponseBody";
import ErrorPage from "../ErrorPage/ErrorPage";
import ResumeViewer from "../../components/Common/Table/ResumeViewer";
import { EmployerType } from "../../type/EmployerType";

interface EmployerResponse {
    totalCount: number;
    result: EmployerType;
}

const EmployerResponsePage = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const adminTurnedOn = adminModeData?.adminMode;
    const { id } = useParams();

    const [data, loading, refetch] = useFetch({
        Parse: Parse,
        dataClass: "Employer",
        id: id
    });

    if (loading) {
        return <Loading />;
    }
    const { email } = userDataManage.user.attributes;
    const { result } = data as EmployerResponse;
    const { resumeUrl } = (data as EmployerResponse).result.attributes;
    const handleTab = (event: any) => {
        setActiveTab(parseInt(event.target.id, 10));
    };

    if (
        (isAdmin && adminTurnedOn) ||
        (result.attributes.requestorEmail === email && (result.attributes.status != "started" || "candidate"))
    ) {
        return (
            <section>
                {/* Note* need this part for developing purpose to see the preview */}

                {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
                    <PDFGenerator data={(data as EmployerResponse).result.attributes} />
                </PDFViewer> */}

                <ResponseTab activeTab={activeTab} handleTab={handleTab} data={result} />
                <ResponseBody data={result} />

                {resumeUrl && <ResumeViewer id={result.id} resumeUrl={resumeUrl} />}
            </section>
        );
    } else {
        return <ErrorPage />;
    }
};

export default EmployerResponsePage;

import React from "react";
import positive from "../../../assets/images/pdf/positive.png";
import neutral from "../../../assets/images/pdf/neutral.png";
import negative from "../../../assets/images/pdf/negative.png";
import { View, Text, Image } from "@react-pdf/renderer";

const SentimentPDF = ({
    data
}: {
    data: {
        positive: number;
        negative: number;
        neutral: number;
    };
}) => {
    return (
        <View style={{ display: "flex", flexDirection: "column", gap: "3", width: "100%" }}>
            <Text style={{ fontSize: 14, fontWeight: "demibold" }}>Sentiment Analysis</Text>

            <View
                style={{
                    height: 80,
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    alignItems: "flex-end",
                    marginTop: 25
                }}
            >
                <View style={{ display: "flex", flexDirection: "column", gap: "2", alignItems: "center" }}>
                    <Text style={{ fontSize: 7 }}>{data.positive.toFixed(1)}%</Text>
                    <View
                        style={{
                            width: 20,
                            height: `${data.positive.toFixed(1)}%`,
                            backgroundColor: "#00A76F",
                            borderRadius: 5
                        }}
                    ></View>
                    <Image src={positive} style={{ width: 16 }} />
                </View>

                <View style={{ display: "flex", flexDirection: "column", gap: "2", alignItems: "center" }}>
                    <Text style={{ fontSize: 7 }}>{data.neutral.toFixed(1)}%</Text>
                    <View
                        style={{
                            width: 20,
                            height: `${data.neutral.toFixed(1)}%`,
                            backgroundColor: "#FABD23",
                            borderRadius: 5
                        }}
                    ></View>
                    <Image src={neutral} style={{ width: 16 }} />
                </View>
                <View style={{ display: "flex", flexDirection: "column", gap: "2", alignItems: "center" }}>
                    <Text style={{ fontSize: 7 }}>{data.negative.toFixed(1)}%</Text>
                    <View
                        style={{
                            width: 20,
                            height: `${data.negative.toFixed(1)}%`,
                            backgroundColor: "#D32F2F",
                            borderRadius: 5
                        }}
                    ></View>
                    54
                    <Image src={negative} style={{ width: 16 }} />
                </View>
            </View>
        </View>
    );
};

export default SentimentPDF;

import React from "react";
import logo from "../../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const RequestPasswordResetOTPUI = ({ formRef, handleResetPassword }: any) => {
    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["forgotPassword"] || SeoContent.baseSeo} />
            <div className="flex flex-col gap-y-6 items-start dark:text-white duration-300 w-[20rem] sm:w-[22rem] 2xl:w-[25rem]">
                <div className="flex justify-center items-center gap-x-1 w-full">
                    <img src={logo} alt="" width="45rem" />
                    <span className="text-3xl font-extrabold dark:text-white">HRHouz</span>
                </div>
                <div className="flex flex-col gap-y-3 items-center justify-center w-full">
                    <h1
                        className={`text-primary font-semibold text-xl flex justify-center border-primary pb-2 border-b-2 px-6`}
                    >
                        Forgot Password
                    </h1>
                    <p className="text-base  text-black dark:text-white font-semibold text-center">
                        we will send an OTP to your email
                    </p>
                </div>

                <form ref={formRef} onSubmit={handleResetPassword} className="flex flex-col gap-y-4 w-full">
                    <CustomInputField type="email" name="email" required title="Email Address" />

                    <button
                        type="submit"
                        className="bg-primary text-white uppercase font-medium py-4 hover:bg-primary  duration-300 rounded flex items-center justify-center gap-x-2"
                    >
                        Get OTP
                    </button>
                    <div>
                        <p className="font-semibold text-black dark:text-white">
                            already have access?{" "}
                            <Link to="/login" className="text-primary">
                                Login
                            </Link>{" "}
                        </p>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default RequestPasswordResetOTPUI;

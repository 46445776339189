import React from "react";
import { BiCurrentLocation, BiSolidDevices } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";

const FingerPrintInformation = ({
    location,
    ipAddress,
    deviceInfo,
    deviceScreenSize,
    timezone
}: {
    location?: { city?: string; region?: string; country?: string };
    ipAddress?: { ipAddressV4: string; ipAddressV6: string };
    deviceInfo?: string;
    deviceScreenSize?: string;
    timezone?: string;
}) => {
    return (
        <div>
            <div className="flex flex-col gap-y-2 ">
                {location?.city && (
                    <div className="flex  gap-x-2">
                        <h1 className="font-semibold  mt-[.1rem] tooltip" data-tip="Location">
                            <FaLocationDot />
                        </h1>
                        <div className="">
                            <h2 className={`capitalize text-sm flex flex-col`}>
                                <span>{location.city}</span>
                                <span className={`capitalize text-[10px] leading-tight font-semibold`}>
                                    {location.region}
                                    {location.country && <>,</>} {location.country}
                                </span>
                            </h2>
                        </div>
                    </div>
                )}
                {ipAddress?.ipAddressV4 && ipAddress?.ipAddressV6 && (
                    <div className="flex  gap-x-2  items-center">
                        <h1 className="font-semibold text-lg tooltip" data-tip="IP Address">
                            <BiCurrentLocation />
                        </h1>

                        <h2 className={`capitalize flex flex-col  font-semibold break-all text-[9px]`}>
                            <span> IPv4/:{ipAddress.ipAddressV4}</span>
                            <span> IPv6/:{ipAddress.ipAddressV6}</span>
                        </h2>
                    </div>
                )}
                {deviceInfo && (
                    <div className="flex  gap-x-2">
                        <h1 className="font-semibold mt-[.1rem] text-lg tooltip" data-tip="Device">
                            <BiSolidDevices />
                        </h1>
                        <div className="">
                            <h2 className={`capitalize  break-all text-sm flex flex-col`}>
                                <span>{deviceInfo}</span>
                                {deviceScreenSize && (
                                    <span className="text-[10px] leading-tight font-semibold">
                                        {deviceScreenSize}
                                        {timezone && <>,</>} {timezone}
                                    </span>
                                )}
                            </h2>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default FingerPrintInformation;

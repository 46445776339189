export const arrowSvg = (
    <svg
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-0.72 -0.72 25.44 25.44"
        stroke="#FFFFFF"
        strokeWidth="3"
    >
        <g>
            <g>
                <g>
                    <polygon points="17.2,23.7 5.4,12 17.2,0.3 18.5,1.7 8.4,12 18.5,22.3 "></polygon>
                </g>
            </g>
        </g>
    </svg>
);

export const calenderSVG = (
    <svg width="24" height="24" viewBox="0 0 24 24" className="NSy2Hd cdByRd RTiFqe GGZtCb fill-current">
        <path d="M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10z"></path>
        <path d="M14.5 13a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5z"></path>
    </svg>
);

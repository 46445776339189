import React, { useContext, useRef } from "react";
import person from "../../../assets/images/header/person.jpg";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { updateData } from "../../../hooks/update";
import { convertToHttps } from "../../../utils/convertUrl";

const ProfileImage = () => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const profileImage = userDataManage.user.attributes.userImage;

    // Function to trigger file input when the label is clicked
    const handleLabelClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Function to handle file input change
    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const parseFile = new Parse.File(file.name, file);
            try {
                await parseFile.save();
                updateData({
                    Parse: Parse,
                    parseItem: userDataManage.user,
                    data: { userImage: convertToHttps(parseFile.url()) },
                    message: "Image Updated!",
                    refetch: userDataManage.userRefetch
                });
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    return (
        <div className="shadow-lg flex flex-col  gap-y-4 justify-center items-center p-4 rounded-2xl bg-white dark:bg-darkSecondary py-10 w-full">
            <div className="flex justify-center items-center overflow-hidden w-40 h-40 relative rounded-full ">
                <img
                    className=" w-40 h-40 flex justify-center items-center rounded-full object-cover font-bold relative overflow-hidden  object-center"
                    src={profileImage || person}
                ></img>

                <span
                    className="text-base text-black absolute bottom-0 capitalize bg-gray-200/80 dark:bg-dark/95 dark:text-white w-full text-center h-20 pt-5 duration-300 cursor-pointer opacity-0 hover:opacity-100 font-bold "
                    onClick={handleLabelClick}
                >
                    upload image
                </span>
            </div>
            <div className="w-[50%] mx-auto">
                <p className="text-center text-sm">Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB</p>
                <label className="cursor-pointer bg-error">
                    <input
                        type="file"
                        accept=".jpeg, .jpg, .png, .gif"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                </label>
            </div>
        </div>
    );
};

export default ProfileImage;

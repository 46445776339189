import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import RequireAuth from "./auth/RequireAuth";
import LoggedInAuth from "./auth/LoggedInAuth";

import AppLayout from "./layout/AppLayout";
import LoginPage from "./page/LoginPage/LoginPage";
import DashboardPage from "./page/DashboardPage/DashboardPage";
import EmployerPage from "./page/EmployerPage/EmployerPage";
import CandidatePage from "./page/CandidatePage/CandidatePage";
import CandidateRefereePage from "./page/CandidatePage/CandidateRefereePage";
import RefereePage from "./page/RefereePage/RefereePage";
import RefereeQuestionPage from "./page/RefereePage/RefereeQuestionPage";
import TemplatePage from "./page/TemplatePage/TemplatePage";
import SupportPage from "./page/SupportPage/SupportPage";
import ProfilePage from "./page/ProfilePage/ProfilePage";
import SettingsPage from "./page/SettingsPage/SettingsPage";

import ErrorPage from "./page/ErrorPage/ErrorPage";
import "react-toastify/dist/ReactToastify.css";
import { DARKMODE_CONTEXT } from "./provider/DarkModeProvider";
import { ToastContainer } from "react-toastify";
import EmployerResponsePage from "./page/EmployerPage/EmployerResponsePage";
import ForgotPasswordPage from "./page/ForgotPasswordPage/ForgotPasswordPage";
import EmailVerificationAuth from "./auth/EmailVerificationAuth";
import MarketingLayout from "./layout/MarketingLayout";
import EmailVerificationUI from "./components/EmailVerification/EmailVerificationUI";
import VerifiedEmailAuth from "./auth/VerifiedEmailAuth";
import EmployerCheck from "./auth/EmployerCheck";
import ResumeScannerPage from "./page/ResumeScannerPage/ResumeScannerPage";
import CustomSecurity from "./utils/security/CustomSecurity";
import OrganizationPage from "./page/OrganizationPage/OrganizationPage";
import AdminCheck from "./auth/AdminCheck";
import JobsPage from "./page/JobsPage/JobsPage";
import JobsResponsePage from "./page/JobsPage/JobsResponsePage";

function App() {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    return (
        <CustomSecurity>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <EmailVerificationAuth>
                                <AppLayout />
                            </EmailVerificationAuth>
                        </RequireAuth>
                    }
                >
                    <Route index element={<DashboardPage />} />
                    <Route
                        path="organization"
                        element={
                            <AdminCheck>
                                <OrganizationPage />
                            </AdminCheck>
                        }
                    />
                    <Route
                        path="employer"
                        element={
                            <EmployerCheck>
                                <EmployerPage />
                            </EmployerCheck>
                        }
                    />
                    <Route
                        path="employer/:id"
                        element={
                            <EmployerCheck>
                                <EmployerResponsePage />
                            </EmployerCheck>
                        }
                    />

                    <Route
                        path="jobs"
                        element={
                            <EmployerCheck>
                                <JobsPage />
                            </EmployerCheck>
                        }
                    />
                    <Route
                        path="jobs/:id"
                        element={
                            <EmployerCheck>
                                <JobsResponsePage />
                            </EmployerCheck>
                        }
                    />

                    <Route
                        path="resume-scanner"
                        element={
                            <EmployerCheck>
                                <ResumeScannerPage />
                            </EmployerCheck>
                        }
                    />

                    <Route path="candidate" element={<CandidatePage />} />
                    <Route path="candidate/:id" element={<CandidateRefereePage />} />
                    <Route path="referee" element={<RefereePage />} />
                    <Route path="referee/:id" element={<RefereeQuestionPage />} />
                    <Route path="templates" element={<TemplatePage />} />
                    <Route path="support" element={<SupportPage />} />

                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="settings" element={<SettingsPage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Route>

                <Route path="*" element={<ErrorPage />} />
                <Route
                    path="/login"
                    element={
                        <LoggedInAuth>
                            <LoginPage />
                        </LoggedInAuth>
                    }
                />
                <Route
                    path="/forgot-password"
                    element={
                        <LoggedInAuth>
                            <ForgotPasswordPage />
                        </LoggedInAuth>
                    }
                />
                <Route
                    path="/email-verification"
                    element={
                        <RequireAuth>
                            <VerifiedEmailAuth>
                                <MarketingLayout>
                                    <EmailVerificationUI />
                                </MarketingLayout>
                            </VerifiedEmailAuth>
                        </RequireAuth>
                    }
                />
            </Routes>
            <ToastContainer theme={darkMode ? "dark" : "light"} />
        </CustomSecurity>
    );
}

export default App;

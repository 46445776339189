import React from "react";
import MarketingLayout from "../../layout/MarketingLayout";
import LoginRegisterToggle from "../../components/LoginRegister/LoginRegisterToggle";

const LoginPage = () => {
    return (
        <MarketingLayout>
            <LoginRegisterToggle />
        </MarketingLayout>
    );
};

export default LoginPage;

import React, { useState } from "react";
import ResponseHeader from "./ResponseHeader";
import QuestionResponse from "./QuestionResponse";
import CandidateTab from "./CandidateTab";

const ResponseBody = ({ data }: any) => {
    const [activeTab, setActiveTab] = useState(0);
    const handleTab = (event: any) => {
        setActiveTab(parseInt(event.target.id, 10));
    };
    return (
        <section className="mx-2">
            <CandidateTab activeTab={activeTab} handleTab={handleTab} data={data} />
            <ResponseHeader data={data.attributes.response[activeTab]} />
            <QuestionResponse response={data.attributes.response[activeTab].response} />
        </section>
    );
};

export default ResponseBody;

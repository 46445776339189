import React, { useState } from "react";
import TabSwitch from "../../components/Profile/TabSwitch/TabSwitch";
import General from "../../components/Profile/General/General";
import Links from "../../components/Profile/Links/Links";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import LinkFormModal from "../../components/Profile/Links/LinkFormModal";

const ProfilePage = () => {
    const [activeTab, setActiveTab] = useState(0);

    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["profileSeo"] || SeoContent.baseSeo} />
            <div>
                <TabSwitch activeTab={activeTab} setActiveTab={setActiveTab} />
                <div className="px-4">
                    {activeTab === 0 && <General />}
                    {activeTab === 1 && <Links />}
                </div>
            </div>
            <LinkFormModal />
        </section>
    );
};

export default ProfilePage;

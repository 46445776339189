import React from "react";
import { FaRegCopy } from "react-icons/fa6";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import RadialProgress from "../Common/Analytics/RadialProgress";

const ResumeInfoGenerator = ({
    array,
    logo,
    paragraph,
    location,
    question,
    title,
    data,
    matchScore,
    copy,
    name
}: {
    array?: boolean;
    logo?: any;
    paragraph?: boolean;
    location?: boolean;
    question?: boolean;
    copy?: boolean;
    title?: string;
    data: any;
    matchScore?: number | null;
    name?: string;
}) => {
    let copyData = "";
    if (array) {
        const listData = data.map((str: any, index: number) => `${index + 1 + " "}.${str} \r\n`).join("\r\n");
        copyData = `HRHouz resume scanner ${title} results for ${name}’s Resume:\n\n${matchScore ? `Match Score: ${matchScore}% \n\n` : ""}${listData}\n\npowered by HRHouz (https://www.hrhouz.com/)`;
    }

    return (
        <>
            <div className={`flex ${!array && !paragraph && !location ? "items-center " : "flex-col"} gap-4 py-3`}>
                {matchScore && (
                    <div>
                        <div className="font-semibold flex items-center gap-x-2">Match Score: {matchScore}%</div>
                    </div>
                )}
                <div className="flex min-w-[4rem] items-center gap-x-2 lg:min-w-[6rem]">
                    <h1 className="font-semibold">{title}</h1>
                    {copy && (
                        <CopyToClipboard onCopy={() => toast.success(` Copied!`)} text={copyData}>
                            <button className="text-primary">
                                <FaRegCopy />
                            </button>
                        </CopyToClipboard>
                    )}
                </div>

                <div>
                    {array ? (
                        <div className={`grid ${question ? "grid-cols-1" : "grid-cols-2 "} gap-2`}>
                            {data.map((item: any, index: number) => (
                                <div key={index} className={`${question ? "flex items-center gap-x-2" : ""} `}>
                                    {question && <span className="w-[1.5rem] font-semibold">{index + 1}.</span>}
                                    <p
                                        className={`flex w-full items-center  border border-primary/70 bg-primary/10 px-3 py-1 ${
                                            question
                                                ? "rounded-md "
                                                : "h-full justify-center rounded-full text-center text-base "
                                        } `}
                                    >
                                        {item}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <>
                            <p
                                className={`${
                                    paragraph || location
                                        ? "break-all rounded-md py-2 md:break-normal"
                                        : "rounded-full "
                                } border border-primary/70 bg-primary/10  ${
                                    logo ? "gap-z-2 flex overflow-hidden " : "px-3 py-1"
                                }`}
                            >
                                {logo && (
                                    <span className="bg-primary px-[.45rem] py-[.35rem] text-white dark:bg-primary/10 dark:text-primary ">
                                        {logo}
                                    </span>
                                )}
                                <span className={`${logo ? "pl-2 pr-3" : ""} brek-all`}>{data}</span>
                            </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ResumeInfoGenerator;

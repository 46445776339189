import React, { useContext } from "react";
import { FaUserAltSlash } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa6";
import { DATA_CONTEXT } from "../../provider/DataProvider";

const AdminUserSwitch = () => {
    const { userListData } = useContext(DATA_CONTEXT) || {};
    return (
        <div>
            <label
                htmlFor="adminUserSwitch"
                className={`text-2xl w-10 h-10 flex justify-center items-center rounded-full bg-secondary/10 hover:bg-secondary/20 duration-300 dark:text-white text-secondary cursor-pointer`}
            >
                {userListData && userListData.usersList.length > 0 ? (
                    <span className="text-success">
                        <FaUserCheck />
                    </span>
                ) : (
                    <span className="text-error">
                        <FaUserAltSlash />
                    </span>
                )}
            </label>
        </div>
    );
};

export default AdminUserSwitch;

import React, { useContext, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { PARSE_CONTEXT } from "../../provider/Provider";
import Loading from "../../components/Common/Loading/Loading";

import ReferenceRequestForm from "../../components/Candidate/ReferenceRequestForm";
import CandidateInformation from "../../components/Common/CandidateInformation/CandidateInformation";
import { BiArrowBack } from "react-icons/bi";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import { CandidateType } from "../../type/CandidateType";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import ErrorPage from "../ErrorPage/ErrorPage";
import RefereeInfoBox from "../../components/Candidate/RefereeInfoBox";

interface CandidateReferee {
    totalCount: number;
    result: CandidateType;
}

const CandidateRefereePage = () => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const adminTurnedOn = adminModeData?.adminMode;

    const { id } = useParams();

    const [data, loading, refetch] = useFetch({
        Parse: Parse,
        dataClass: "Candidate",
        id: id
    });

    if (loading) {
        return <Loading />;
    }

    const { firstname, lastname, phoneNumber, email } = userDataManage.user.attributes;
    const { result } = data as CandidateReferee;

    if (result.attributes.candidateEmail != email && (!isAdmin || !adminTurnedOn)) {
        return <ErrorPage />;
    }

    const {
        requestorFirstname,
        requestorLastname,
        requestorEmail,
        requestorPhoneNumber,
        minRefereeCount,
        status,
        refereeInformation
    } = result.attributes;
    return (
        <div>
            <div className=" rounded dark:bg-dark p-0">
                {result ? (
                    <div className="p-4  lg:px-6">
                        <div className="relative flex flex-col lg:flex-row lg:items-center justify-center gap-6 pt-6">
                            <Link
                                to="/candidate"
                                className="lg:absolute left-2 flex  gap-x-2 items-center text-lg font-semibold"
                            >
                                <BiArrowBack />
                                <span>Back</span>
                            </Link>

                            {!refereeInformation && (
                                <div className="lg:text-center">
                                    <h1 className="font-medium text-xl pb-2 ">Enter Referee Information</h1>
                                    <p>Please fill form below and hit submit.</p>
                                </div>
                            )}
                        </div>
                        <>
                            <CandidateInformation
                                requestorName={requestorFirstname + " " + requestorLastname}
                                requestorEmail={requestorEmail}
                                requestorPhone={requestorPhoneNumber}
                                candidateName={firstname + " " + lastname}
                                candidateEmail={email}
                                candidatePhone={phoneNumber}
                                refereeCount={minRefereeCount}
                                status={status}
                            />
                            {refereeInformation && refereeInformation.length > 0 ? (
                                refereeInformation.map((item, index) => (
                                    <RefereeInfoBox result={item} item={index} key={index} />
                                ))
                            ) : (
                                <ReferenceRequestForm formRef={formRef} data={result} />
                            )}
                        </>
                    </div>
                ) : (
                    <div className="p-4">
                        <Link to="/candidate" className=" flex  gap-x-2 items-center text-lg font-semibold">
                            <BiArrowBack />
                            <span>Back</span>
                        </Link>
                    </div>
                )}
            </div>
            {Array.isArray(result) && result.length === 0 && <RetryModal refetch={refetch} loading={loading} />}
        </div>
    );
};

export default CandidateRefereePage;

import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo/logo.svg";
import useResumeScanner from "../../hooks/useResumeScanner";
import Loading from "../Common/Loading/Loading";
import { IoCloseOutline } from "react-icons/io5";
import { RiQuestionnaireFill } from "react-icons/ri";
import { PiPercentFill } from "react-icons/pi";
import { MdWorkHistory } from "react-icons/md";
import { TiInfoLarge } from "react-icons/ti";
import { AiFillTool } from "react-icons/ai";
import { ResumeData } from "../../type/ResumeType";
import ResumeInfoGenerator from "./ResumeInfoGenerator";
import InformationBlock from "./InformationBlock";
import ResumeWorkExperience from "./ResumeWorkExperience";
import { toast } from "react-toastify";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { convertToHttps } from "../../utils/convertUrl";
import { createData } from "../../hooks/create";
import { FaEnvelopeOpenText } from "react-icons/fa6";
import CoverLetter from "./CoverLetter";

const ResumeScannerOutput = ({
    file,
    fileBase64,
    setFile,
    setFileBase64,
    refetch,
    id,
    resumeData,
    coverLetter
}: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const viewUser = userListData && userListData.usersList[0];
    const query = [
        {
            key: "requestorUserId",
            item: adminModeData?.adminMode && isAdmin ? viewUser?.id : userDataManage.user?.id
        },
        { key: "resumeBase64", item: fileBase64 }
    ];
    const [tabs, setTabs] = useState(1);
    const [formLoading, setFormLoading] = useState(false);

    const { data, loading, fileExist } = resumeData || useResumeScanner({ Parse: Parse, file: file, fileQuery: query });

    if (!resumeData && loading) {
        return <Loading title />;
    }

    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>(
            resumeData ? `[for="${id + "view"}"]` : '[for="resumeScanner"]'
        );
        if (setFile) {
            setFile(null);
        }
        if (setFileBase64) {
            setFileBase64(null);
        }
        if (label) {
            label.click();
        }
    };
    const handleSave = async () => {
        setFormLoading(true);
        const parseFile = new Parse.File(file.name, file);
        try {
            await parseFile.save();
        } catch (error) {
            toast.error((error as any).message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }

        const submitData = {
            requestorUserId: userDataManage.user?.id,
            requestorImage: userDataManage.user.attributes.userImage,
            requestorFirstname: userDataManage.user.attributes.firstname,
            requestorLastname: userDataManage.user.attributes.lastname,
            requestorEmail: userDataManage.user.attributes.email,
            requestorPhoneNumber: userDataManage.user.attributes.phoneNumber,
            resumeData: data,
            resumeBase64: fileBase64,
            resumeUrl: convertToHttps(parseFile.url())
        };

        createData({
            Parse: Parse,
            dataClass: "Resume",
            data: submitData,
            message: "Resume Sata Saved!",
            refetch: [refetch],
            closeModal: closeModal,
            loading: setFormLoading
        });
        setFile(null);
        setFileBase64(null);
    };

    return (
        <div className="">
            <div className="absolute top-0 right-0 bg-error px-2 pt-1 text-3xl text-white rounded-bl-lg">
                <button disabled={formLoading} type="button" onClick={closeModal}>
                    <IoCloseOutline />
                </button>
            </div>

            <div className="h-[40rem] overflow-x-auto overflow-y-auto rounded-lg border-2 p-3 md:p-6">
                {fileExist && (
                    <h2 className="text-center text-error font-semibold pb-4">This File Was Already Processed*</h2>
                )}
                <div className="flex w-full flex-col items-center justify-center gap-x-3 pb-6">
                    <img src={logo} alt="" className=" w-16" />
                    <h1 className="text-xl font-semibold">HRHouz Resume Parser</h1>
                </div>

                <div>
                    <div className="mx-auto mb-10 mt-4 flex w-full justify-around rounded-full bg-primary/10 py-2 text-lg  font-semibold md:w-fit md:gap-x-12 lg:px-6  lg:text-xl">
                        <div
                            className={`tooltip tooltip-success flex cursor-pointer items-center justify-center text-3xl ${
                                tabs === 1 ? "text-primary" : ""
                            }`}
                            data-tip="Information"
                        >
                            <button onClick={() => setTabs(1)}>
                                <TiInfoLarge />
                            </button>
                        </div>

                        <div
                            className={`tooltip tooltip-success flex cursor-pointer items-center  justify-center text-3xl ${
                                tabs === 2 ? "text-primary" : ""
                            }`}
                            data-tip="Skills"
                        >
                            <button onClick={() => setTabs(2)}>
                                <AiFillTool />
                            </button>
                        </div>
                        <div
                            className={`tooltip tooltip-success flex cursor-pointer items-center  justify-center text-3xl ${
                                tabs === 3 ? "text-primary" : ""
                            }`}
                            data-tip="Work Experience"
                        >
                            <button onClick={() => setTabs(3)}>
                                <MdWorkHistory />
                            </button>
                        </div>
                        <div
                            className={`tooltip tooltip-success flex !cursor-pointer items-center  justify-center text-3xl ${
                                tabs === 4 ? "text-primary" : ""
                            }`}
                            data-tip="Questions"
                        >
                            <button onClick={() => setTabs(4)}>
                                <RiQuestionnaireFill />
                            </button>
                        </div>
                        {coverLetter && (
                            <div
                                className={`tooltip tooltip-success flex !cursor-pointer items-center  justify-center text-3xl ${
                                    tabs === 5 ? "text-primary" : ""
                                }`}
                                data-tip="CoverLetter"
                            >
                                <button onClick={() => setTabs(5)}>
                                    <FaEnvelopeOpenText />
                                </button>
                            </div>
                        )}
                        {data?.match_score_reasons && (
                            <div
                                className={`tooltip tooltip-success flex !cursor-pointer items-center  justify-center text-3xl ${
                                    tabs === 6 ? "text-primary" : ""
                                }`}
                                data-tip="Match Score"
                            >
                                <button onClick={() => setTabs(6)}>
                                    <PiPercentFill />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {tabs === 1 && <InformationBlock data={data} />}

                {data?.skills && tabs === 2 && (
                    <ResumeInfoGenerator
                        array
                        title={"Candidate Skills"}
                        data={data?.skills}
                        copy
                        name={data?.first_name + " " + data?.last_name}
                    />
                )}

                {data?.candidate_questions && tabs === 3 && (
                    <ResumeWorkExperience
                        title={"Work Experience"}
                        copy
                        name={data?.first_name + " " + data?.last_name}
                        data={data?.work_experience}
                    />
                )}

                {data?.candidate_questions && tabs === 4 && (
                    <ResumeInfoGenerator
                        array
                        question
                        title={"Candidate Questions"}
                        data={data?.candidate_questions}
                        copy
                        name={data?.first_name + " " + data?.last_name}
                    />
                )}
                {coverLetter && tabs === 5 && <CoverLetter coverLetter={coverLetter} />}
                {data?.match_score_reasons && tabs === 6 && (
                    <ResumeInfoGenerator
                        array
                        question
                        title={"Resume Matching Score Reason"}
                        data={data?.match_score_reasons}
                        matchScore={data?.match_score}
                        copy
                        name={data?.first_name + " " + data?.last_name}
                    />
                )}
            </div>

            {!fileExist && !resumeData && (
                <div className="flex justify-end mt-4">
                    <button
                        onClick={handleSave}
                        disabled={formLoading}
                        className="px-6 py-2 rounded-md bg-success font-semibold text-white flex items-center gap-x-2"
                    >
                        {formLoading && <span className="loading loading-spinner"></span>}Save
                    </button>
                </div>
            )}
        </div>
    );
};

export default ResumeScannerOutput;

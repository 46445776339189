import { AiTwotoneTool } from "react-icons/ai";
import { FaBuildingUser, FaUserCheck } from "react-icons/fa6";
import { BsPersonLinesFill, BsBarChartFill, BsFillBuildingsFill, BsBriefcaseFill } from "react-icons/bs";
import { IoIosPeople } from "react-icons/io";
import { HiTemplate } from "react-icons/hi";
import { Menu } from "../type/Types";
import { MdDocumentScanner } from "react-icons/md";

export const sideBarItems: Menu[] = [
    {
        name: "Dashboard",
        link: "/",
        icon: BsBarChartFill,
        isAdmin: true,
        isEmployer: true,
        isCandidate: true,
        isReferee: true
    },
    {
        name: "organization",
        link: "/organization",
        icon: BsFillBuildingsFill,
        isAdmin: true,
        isEmployer: false,
        isCandidate: false,
        isReferee: false
    },
    {
        name: "Employer",
        icon: FaBuildingUser,
        isAdmin: true,
        isEmployer: true,
        isCandidate: false,
        isReferee: false,
        subMenu: [
            { name: "Reference Checks", icon: FaUserCheck, path: "/employer" },
            { name: "Job Board", icon: BsBriefcaseFill, path: "/jobs" },
            { name: "Resume Scanner", icon: MdDocumentScanner, path: "/resume-scanner" }
        ]
    },
    {
        name: "Candidate",
        link: "/candidate",
        icon: BsPersonLinesFill,
        isAdmin: true,
        isEmployer: true,
        isCandidate: true,
        isReferee: true
    },
    {
        name: "Referee",
        link: "/referee",
        icon: IoIosPeople,
        isAdmin: true,
        isEmployer: true,
        isCandidate: true,
        isReferee: true
    },
    {
        name: "Templates",
        link: "/templates",
        icon: HiTemplate,
        isAdmin: true,
        isEmployer: true,
        isCandidate: false,
        isReferee: false
    },
    {
        name: "Support",
        link: "/support",
        icon: AiTwotoneTool,
        isAdmin: true,
        isEmployer: true,
        isCandidate: true,
        isReferee: true
    }
];

import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { updateData } from "../../../hooks/update";
import { toast } from "react-toastify";
import VerifyPasswordResetOTPUI from "../../ForgotPassword/VerifyPasswordResetOTPUI";
import { IoCloseOutline } from "react-icons/io5";
import PasswordChangeForm from "./PasswordChangeForm";

const PasswordResetModal = () => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const [otpReset, setOtpReset] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpVerified, setOtpVerified] = useState(false);

    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>('[for="passwordChange"]');
        if (label) {
            label.click();
            setOtpReset(!otpReset);
        }
    };

    return (
        <div>
            <input type="checkbox" id="passwordChange" className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box dark:bg-dark">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>
                    <div className="flex justify-center">
                        {!otpVerified && (
                            <VerifyPasswordResetOTPUI
                                otp={otp}
                                setOtp={setOtp}
                                email={userDataManage.user.attributes.email}
                                passwordChange
                                otpReset={otpReset}
                                setOtpVerified={setOtpVerified}
                            />
                        )}
                        {otpVerified && (
                            <PasswordChangeForm
                                email={userDataManage.user.attributes.email}
                                otp={otp}
                                setOtpVerified={setOtpVerified}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetModal;

import React from "react";
import { IoCloseOutline } from "react-icons/io5";

const ResumeViewer = ({ id, resumeUrl }: { id: string; resumeUrl: string }) => {
    return (
        <div>
            <input type="checkbox" id={id} className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box p-0 max-w-2xl relative rounded-none">
                    <iframe
                        src={resumeUrl}
                        height="100%"
                        width="100%"
                        className="h-[50rem] border-[#323639] border-4"
                        style={{ maxWidth: "100%", overflowX: "hidden" }}
                    />

                    <label
                        htmlFor={id}
                        className="absolute top-0 right-0 bg-error px-3 py-2 rounded-bl-md text-white text-3xl cursor-pointer"
                    >
                        <IoCloseOutline />
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ResumeViewer;

import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ChildrenProps } from "../type/Types";
import { DATA_CONTEXT } from "../provider/DataProvider";

function EmailVerificationAuth({ children }: ChildrenProps) {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const location = useLocation();

    if (!userDataManage?.user?.attributes?.emailVerified) {
        return <Navigate to="/email-verification" state={{ from: location }} replace />;
    } else {
        return <>{children}</>;
    }
}

export default EmailVerificationAuth;

import React from "react";
import { View, Text, Defs, LinearGradient, Stop, Svg, Path } from "@react-pdf/renderer";

const PieChartMiniPDF = ({ data }: { data: number[] }) => {
    const total = data.reduce((acc: any, value: any) => acc + value, 0);
    let startAngle = 0;

    const colors = ["#00A76F", "#FABD23", "#D32F2F"].reverse();

    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}
        >
            {" "}
            <View style={{ position: "relative", height: 35, width: 35 }}>
                <Svg viewBox="0 0 40 40" style={{ position: "absolute", width: "100%", height: "100%" }}>
                    {data.map((value, index) => {
                        const percentage = (value / total) * 100;
                        const largeArcFlag = percentage > 50 ? 1 : 0;
                        const endAngle = startAngle + percentage * 3.6;

                        const startX = 20 + 20 * Math.cos((startAngle - 90) * (Math.PI / 180));
                        const startY = 20 + 20 * Math.sin((startAngle - 90) * (Math.PI / 180));

                        const endX = 20 + 20 * Math.cos((endAngle - 90) * (Math.PI / 180));
                        const endY = 20 + 20 * Math.sin((endAngle - 90) * (Math.PI / 180));

                        const pathData = `M 20 20 L ${startX} ${startY} A 20 20 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;

                        startAngle = endAngle;

                        return <Path key={index} d={pathData} fill={`url(#${colors[index]})`} />;
                    })}
                    <Defs>
                        {colors.map((color, index) => (
                            <LinearGradient key={index} id={color}>
                                <Stop offset="0%" stopColor={color} />
                                <Stop offset="100%" stopColor={color} />
                            </LinearGradient>
                        ))}
                    </Defs>
                </Svg>
            </View>
            <Text style={{ fontSize: 10 }}>sentiments</Text>
        </View>
    );
};

export default PieChartMiniPDF;

import Slider from "react-slick";

const Brands = ({ brands }: any) => {
    var settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 2000, // Set the autoplay speed in milliseconds
        arrows: false,

        responsive: [
            {
                breakpoint: 1536,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1194,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section>
            <div className=" bg-primary/10 ">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4">
                        <div
                            className="wow fadeInUp py-4 2xl:py-8 px-8 "
                            data-wow-delay=".1s
              "
                        >
                            <Slider {...settings}>
                                {brands.map((brand: any) => (
                                    <SingleBrand key={brand.id} brand={brand} />
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Brands;

const SingleBrand = ({ brand }: any) => {
    const { href, image, name } = brand;

    return (
        <div className="mx-3 ml-[50px] flex w-full max-w-[160px] items-center justify-center py-[15px] md:ml-[0px]  lg:max-w-[130px] xl:mx-6 xl:max-w-[150px] 2xl:mx-8 2xl:max-w-[160px]">
            <a
                href={href}
                target="_blank"
                rel="nofollow noreferrer"
                className="relative h-10 w-full grayscale transition  hover:grayscale-0  flex items-center justify-center"
            >
                <img
                    src={new URL(image, process.env.REACT_APP_PUBLIC_WEBSITE).href}
                    alt={name}
                    className="imageFilter max-h-12"
                />
            </a>
        </div>
    );
};

import { toast } from "react-toastify";
import { updateData } from "./update";

interface LoginData {
    username: string;
    password: string;
}
interface NavigationFunction {
    (path: string): void;
}
let isLoginInProgress = false;

export const login = async ({
    Parse,
    data,
    refetch,
    fingerPrintData,
    navigate,
    loading
}: {
    Parse: any;
    data: LoginData;
    refetch: () => void;
    fingerPrintData: any;
    navigate: NavigationFunction;
    loading: any;
}): Promise<boolean> => {
    if (isLoginInProgress) {
        return false;
    }
    try {
        isLoginInProgress = true;
        const user = await Parse.User.logIn(data.username, data.password);
        await user.save();
        toast.success("Welcome!");
        await updateData({
            Parse: Parse,
            parseItem: user,
            data: { updatedAtFingerPrint: fingerPrintData }
        });
        refetch();
        navigate("/");
        return true;
    } catch (error) {
        toast.error("Invalid username/password");
        loading(false);
        return false;
    } finally {
        loading(false);
        isLoginInProgress = false;
    }
};

import { View } from "@react-pdf/renderer";
import React from "react";
import UserProfile from "./UserProfile";
import OtherInformation from "./OtherInformation";
import { styles } from "./PDFStyle";
import UserLocation from "./UserLocation";

const ProfileInformationPDF = ({ userInfo, otherInformation, profileLocation }: any) => {
    const { geoLocation, geoLocationByIP, ipAddressV4, ipAddressV6, deviceInfo, deviceScreenSize, timezone } =
        profileLocation;

    const location = {
        city: geoLocation?.city || geoLocationByIP?.city || null,
        region: geoLocation?.region || geoLocationByIP?.region || null,
        country: geoLocation?.country || geoLocationByIP?.country || null
    };
    return (
        <View
            style={{
                borderRadius: "10%",
                border: 1.5,
                padding: 10,
                width: "90%",
                marginHorizontal: "auto",
                borderColor: "gray",
                display: "flex",
                flexDirection: "row",
                gap: 6
            }}
        >
            <UserProfile
                heading={userInfo.heading}
                name={userInfo.name}
                email={userInfo.email}
                phone={userInfo.phone}
            />
            <OtherInformation otherInformation={otherInformation} />
            <UserLocation
                location={location}
                ipAddress={{ ipAddressV4, ipAddressV6 }}
                deviceInfo={deviceInfo}
                deviceScreenSize={deviceScreenSize}
                timezone={timezone}
            />
        </View>
    );
};

export default ProfileInformationPDF;

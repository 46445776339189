import { FaRegCopy } from "react-icons/fa6";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { toast } from "react-toastify";

const ResumeWorkExperience = ({ copy, name, data }: { copy?: boolean; title?: string; data?: any; name?: string }) => {
    let copyData = "";
    const listData = data
        .map(
            (item: any, index: number) =>
                `${index + 1}. ${item?.job_title}\n   ${item?.company} ${
                    item?.start_date
                        ? `\n   ${item?.start_date} ${
                              item?.is_current
                                  ? `- Present · ${item?.experience_time}`
                                  : `${item?.end_date ? "- " + item?.end_date : ""} · ${item?.experience_time}`
                          }`
                        : ""
                } ${item?.date_dif ? " - " + item?.date_dif : ""}  ${item?.location && locationParse(item.location) ? "\n " + "  " + locationParse(item.location) : ""} ${item?.summary ? "\n \n" + "   " + item?.summary : ""}`
        )
        .join("\n");

    copyData = `HRHouz resume scanner Work Experience results for ${name}’s Resume:\n\n${listData}\n\npowered by HRHouz (https://www.hrhouz.com/)`;

    return (
        <>
            <div className={`flex flex-col gap-4 py-3`}>
                <div className="flex min-w-[4rem] items-center gap-x-2 lg:min-w-[6rem]">
                    <h1 className="font-semibold">Work Experience</h1>
                    {copy && (
                        <CopyToClipboard onCopy={() => toast.success(` Copied!`)} text={copyData}>
                            <button className="text-primary">
                                <FaRegCopy />
                            </button>
                        </CopyToClipboard>
                    )}
                </div>

                <div>
                    <div className={`grid grid-cols-1 gap-2`}>
                        {data.map((item: any, index: number) => (
                            <div key={index} className="flex items-start gap-x-2">
                                <span className="w-[1.5rem] font-semibold">{index + 1}.</span>
                                <div
                                    className={`flex w-full flex-col  rounded-md border border-primary/70 bg-primary/10 px-3 py-1  `}
                                >
                                    <h1 className="text-base font-bold">{item?.job_title}</h1>
                                    <h2 className="text-sm font-semibold">{item?.company}</h2>
                                    {item?.start_date && (
                                        <h2 className="text-sm">
                                            <span>{item?.start_date}</span>
                                            {!item?.is_current ? (
                                                <>
                                                    {item?.end_date && (
                                                        <>
                                                            <span> - </span>
                                                            <span>{item?.end_date}</span>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <span className="font-medium"> - Present </span>
                                            )}
                                            {item?.date_dif && <span> - {item?.date_dif}</span>} ·{" "}
                                            {item?.experience_time}
                                        </h2>
                                    )}
                                    {item?.location && (
                                        <h2 className="text-sm">
                                            <span>{locationParse(item.location)}</span>
                                        </h2>
                                    )}

                                    {item?.summary && (
                                        <h2 className="text-sm mt-2">
                                            <span>{item.summary}</span>
                                        </h2>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResumeWorkExperience;

const locationParse = (location: { city?: string | null; country?: string | null; state?: string | null }) => {
    if (location) {
        const values = Object.values(location);
        if (values.every((value) => value === null)) {
            return null;
        }
        return values.filter((value) => value !== null).join(", ");
    } else {
        return null;
    }
};

import React, { useContext } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { BiTrashAlt } from "react-icons/bi";
import Swal from "sweetalert2";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { deleteItem } from "../../../hooks/delete";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
const ActionMenu = ({
    dataClass,
    id,
    refetch,
    lastItem,
    removeUpdate,
    removeDelete,
    removeView
}: {
    dataClass: string;
    id: string;
    refetch: any[];
    lastItem: boolean;
    removeUpdate?: boolean;
    removeDelete?: boolean;
    removeView?: boolean;
}) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                popup: darkMode ? "swal-dark-mode" : "swal-light-mode",
                confirmButton: darkMode ? "swal-dark-mode-confirm" : "swal-light-mode-confirm",
                cancelButton: darkMode ? "swal-dark-mode-cancel" : "swal-light-mode-cancel"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem({
                    Parse: Parse,
                    dataClass: dataClass,
                    dataId: id,
                    refetch: refetch
                });
                Swal.fire({
                    title: "Deleted!",
                    text: "Your Data has been deleted.",
                    icon: "success",
                    customClass: {
                        popup: darkMode ? "swal-dark-mode" : "swal-light-mode"
                    }
                });
            }
        });
    };
    return (
        <div className={`dropdown ${lastItem ? "dropdown-top" : ""}  dropdown-end text-base font-semibold`}>
            <label
                tabIndex={0}
                className="btn bg-inherit  hover:bg-primary/10 dark:hover:bg-primary/30 border-0 duration-300 dark:text-white "
            >
                <BsThreeDotsVertical />
            </label>
            <ul
                tabIndex={0}
                className="dropdown-content z-[1] bg-white menu p-2 shadow  rounded-box w-52 dark:bg-darkSecondary"
            >
                {!removeView && (
                    <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg ">
                        <label htmlFor={id + "view"} className="dark:!text-white active:!bg-success">
                            <span className="text-lg ">
                                <AiOutlineEye />
                            </span>
                            View
                        </label>
                    </li>
                )}
                {removeUpdate && (
                    <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                        <label htmlFor={id + "update"} className="dark:!text-white active:!bg-success">
                            <span className="text-lg">
                                <HiPencilAlt />
                            </span>
                            Update
                        </label>
                    </li>
                )}
                {removeDelete && (
                    <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                        <button type="button" onClick={handleDelete} className="dark:!text-white active:!bg-success">
                            <span className="text-lg">
                                <BiTrashAlt />
                            </span>
                            Delete
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ActionMenu;

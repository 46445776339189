import React from "react";
import MarketingLayout from "../../layout/MarketingLayout";
import ForgotPasswordFlow from "../../components/ForgotPassword/ForgotPasswordFlow";

const ForgotPasswordPage = () => {
    return (
        <MarketingLayout>
            <ForgotPasswordFlow />
        </MarketingLayout>
    );
};

export default ForgotPasswordPage;

import React from "react";
import ResumeScannerOutput from "./ResumeScannerOutput";

const ResumeResultModal = ({ id, resumeData, coverLetter }: any) => {
    return (
        <div>
            <input type="checkbox" id={id + "view"} className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box p-10 dark:bg-dark w-11/12 max-w-4xl modalScroll">
                    <ResumeScannerOutput
                        id={id}
                        resumeData={{ data: resumeData, loading: false }}
                        coverLetter={coverLetter}
                    />
                </div>
            </div>
        </div>
    );
};

export default ResumeResultModal;

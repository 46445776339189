import { View, Text } from "@react-pdf/renderer";
import React from "react";
import SingleBlockObjectivePDF from "./SingleBlockObjectivePDF";
import MultipleBlockObjectivePDF from "./MultipleBlockObjectivePDF";
import SingleRegularObjectivePDF from "./SingleRegularObjectivePDF";
import MultipleRegularObjectivePDF from "./MultipleRegularObjectivePDF";
import SliderObjectivePDF from "./SliderObjectivePDF";
import AnalyticsPDF from "./AnalyticsPDF";

const ObjectiveQuestionPDF = ({ data }: any) => {
    return (
        <View style={{ display: "flex", flexDirection: "column" }}>
            <View
                style={{
                    padding: 10,
                    backgroundColor: "#F9FAFB",
                    borderRadius: 5,
                    width: "90%",
                    marginHorizontal: "auto"
                }}
            >
                <Text style={{ fontSize: 12 }}>{data.question}</Text>
                <Text style={{ fontSize: 8, color: "red", marginTop: 2 }}>
                    {data.type == "singleRegular" && "single choice*"}
                    {data.type == "singleBlock" && "single choice*"}
                    {data.type == "multipleBlock" && "multiple choice*"}
                    {data.type == "multipleRegular" && "multiple choice*"}
                </Text>
                {data.type == "singleSlider" && <SliderObjectivePDF data={data} />}
                {data.type == "singleRegular" && <SingleRegularObjectivePDF data={data} />}
                {data.type == "singleBlock" && <SingleBlockObjectivePDF data={data} />}
                {data.type == "multipleRegular" && <MultipleRegularObjectivePDF data={data} />}
                {data.type == "multipleBlock" && <MultipleBlockObjectivePDF data={data} />}
            </View>
            {data?.analytics && <AnalyticsPDF analytics={data.analytics} />}
        </View>
    );
};

export default ObjectiveQuestionPDF;

import React, { useState, useContext } from "react";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import useFetch from "../../hooks/useFetch";

import Loading from "../../components/Common/Loading/Loading";
import Table from "../../components/Common/Table/Table";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import NewSupportModal from "../../components/Support/Modal/NewSupportModal";
import UpdateSupportModal from "../../components/Support/Modal/UpdateSupportModal";
import SupportViewModal from "../../components/Support/Modal/SupportViewModal";

import { FaPlus } from "react-icons/fa";
import { Support } from "../../type/supportType";
import { TableData } from "../../type/Table";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { DateComponent, StatusComponent } from "../../components/Common/Table/UtilityComponent";
import useCategoryCheck from "../../hooks/useCategoryCheck";
import { useLocation } from "react-router";
import { pageData } from "../../content/constantVariable";

interface SupportDataType {
    totalCount: number;
    result: Support[];
}

const SupportPage = () => {
    const statusItems = new Set(["all", "pending", "completed"]);
    const location = useLocation();
    const statusParam = new URLSearchParams(location.search).get("status");
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const viewUser = userListData && userListData.usersList[0];
    const [currentStatus, setCurrentStatus] = useState(
        statusParam ? (statusItems.has(statusParam) ? statusParam : "all") : "all"
    );

    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "SupportTicket",
        dataQuery: [
            {
                key: "userId",
                item: adminModeData?.adminMode && isAdmin ? viewUser?.id : userDataManage.user?.id
            },
            { key: "status", item: currentStatus === "all" ? "" : currentStatus }
        ],
        statusQuery: currentStatus === "all" ? "" : currentStatus,
        total: { perPage: totalData, page: page },
        alert: true
    });
    const [statusData, statusLoading, filterRefetch] = useCategoryCheck({
        Parse: Parse,
        dataClass: "SupportTicketStatusCount",
        query: { userId: isAdmin ? viewUser?.id : userDataManage.user?.id }
    });

    if (loading || statusLoading) {
        return <Loading />;
    }

    const tableBody = (data as SupportDataType).result.map((item, index) => {
        const { createdAt, updatedAt, issueSubject, issueMessage, status } = item.attributes;
        return {
            id: item.id,
            createdAt: <DateComponent date={createdAt} />,
            updatedAt: <DateComponent date={updatedAt} />,
            issueSubject: (
                <div className="w-[15rem]">
                    <p className="break-all">{issueSubject}</p>
                </div>
            ),
            issueMessage: (
                <div className="w-[20rem]">
                    <p className="break-all">
                        {issueMessage.length < 100 ? issueMessage : issueMessage.slice(0, 100) + "..."}
                    </p>
                </div>
            ),
            status: <StatusComponent status={status} />,
            removeUpdate: item.attributes.status !== "pending" ? false : true,
            removeDelete: item.attributes.status === "completed" ? false : true
        };
    });

    const tableData = {
        columnItems: ["Date Initiated", "Last Updated", "Subject", "Description", "Status", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as SupportDataType).totalCount
        },
        refetch: refetch,
        dataClass: "SupportTicket",
        filter: statusData,
        currentStatus: currentStatus,
        statusUpdate: setCurrentStatus,
        filterRefetch: filterRefetch
    };

    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["ticketsSeo"] || SeoContent.baseSeo} />
            <section className="py-10 ">
                <div className=" flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="newTicket"
                    >
                        <FaPlus />
                        Submit New Ticket
                    </label>
                </div>

                <Table action tableData={tableData as TableData} />

                <div>
                    {(data as SupportDataType).result.map((item: { attributes: any }, index: any) => (
                        <div key={index}>
                            <SupportViewModal item={item} />
                            <UpdateSupportModal refetch={refetch} item={item} />
                        </div>
                    ))}
                </div>
                <NewSupportModal refetch={[refetch, filterRefetch]} />
                {error && <RetryModal refetch={refetch} />}
            </section>
        </TableLayer>
    );
};

export default SupportPage;

export const SeoContent = {
    baseSeo: {
        title: "HRHouz - Reference Checks",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    dashboardSeo: {
        title: "Dashboard | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/dashboard",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    organizationSeo: {
        title: "Organization | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/organization",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    requestSeo: {
        title: "Request Reference | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/request",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    scannerSeo: {
        title: "Resume Scanner | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/request",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    jobSeo: {
        title: "Job Board | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/request",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    askSeo: {
        title: "Ask Reference | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/ask",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    giveSeo: {
        title: "Give Reference | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/give",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    templateSeo: {
        title: "Template | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/template",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    ticketsSeo: {
        title: "Tickets | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/tickets",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    profileSeo: {
        title: "Profile | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/profile",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    settingsSeo: {
        title: "Settings | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/settings",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    loginSeo: {
        title: "Log In | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/login",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    registerSeo: {
        title: "Register | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/login",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    forgotPassword: {
        title: "Forgot Password | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/forgot-password",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    OTPVerification: {
        title: "OTP Verification | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/forgot-password?flow=otp",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    resetPassword: {
        title: "Reset Password | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/forgot-password?flow=reset",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    emailVerification: {
        title: "Email Verification | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/email-verification",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    },
    errorSeo: {
        title: "Error 404 | HRHouz",
        description:
            "Reference Checks - Made Easy! HRHouz's AI driven automated reference check software helps employers make informed hiring decisions with speed & confidence.",
        image: "/landing.svg",
        siteUrl: "https://app.hrhouz.com/error",
        keywords: ["HRHouz", "HR", "ReferenceChecks", "AI", "HRSolutions", "HRTech", "Home"]
    }
};

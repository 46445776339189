import { FaFacebookF, FaGithub, FaInstagramSquare, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { FaThreads, FaTiktok } from "react-icons/fa6";
import { TbWorld } from "react-icons/tb";

export const urlCheck = (url: string) => {
    if (url.toLowerCase().includes("facebook.com/")) {
        return { name: "facebook", icon: <FaFacebookF /> };
    }
    if (url.toLowerCase().includes("instagram.com/")) {
        return { name: "instagram", icon: <FaInstagramSquare /> };
    }
    if (url.toLowerCase().includes("threads.net/")) {
        return { name: "threads", icon: <FaThreads /> };
    }
    if (url.toLowerCase().includes("tiktok.com/")) {
        return { name: "tiktok", icon: <FaTiktok /> };
    }
    if (url.toLowerCase().includes("linkedin.com/")) {
        return { name: "linkedin", icon: <FaLinkedin /> };
    }
    if (url.toLowerCase().includes("youtube.com/")) {
        return { name: "youtube", icon: <FaYoutube /> };
    }
    if (url.toLowerCase().includes("github.com/")) {
        return { name: "github", icon: <FaGithub /> };
    }
    if (url.toLowerCase().includes("twitter.com/")) {
        return { name: "twitter", icon: <FaTwitter /> };
    }
    return { name: "other", icon: <TbWorld /> };
};

import { View, Text, Image } from "@react-pdf/renderer";
import React from "react";
import icon from "../../../assets/images/pdf/report.png";

const AnalyticsIconPDF = () => {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}
        >
            <View
                style={{
                    width: 35,
                    height: 35,
                    borderRadius: "100%",
                    border: 3,
                    borderColor: "#60A5FA",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#60A5FA"
                }}
            >
                <Image src={icon} style={{ width: 20 }} />
            </View>
            <Text style={{ fontSize: 10 }}>analytics</Text>
        </View>
    );
};

export default AnalyticsIconPDF;

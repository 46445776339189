import React, { useState, useContext } from "react";
import { PARSE_CONTEXT } from "../../provider/Provider";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import { FaPlus, FaUserFriends } from "react-icons/fa";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { pageData } from "../../content/constantVariable";
import { JobsType } from "../../type/JobsType";
import { DateComponent, ProfileComponent } from "../../components/Common/Table/UtilityComponent";
import Table from "../../components/Common/Table/Table";
import { TableData } from "../../type/Table";
import CreateJob from "../../components/JobBoard/CreateJob";
import useCloudFunction from "../../hooks/useCloudFunction";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import UpdateJob from "../../components/JobBoard/UpdateJob";
import ViewJob from "../../components/JobBoard/ViewJob";
import { Link } from "react-router-dom";

interface JobsDataType {
    totalCount: number;
    result: JobsType[];
}

const JobsPage = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useCloudFunction({
        Parse: Parse,
        cloudFunctionName: "GetJobsOfCompany",
        cloudFunctionParams: {
            domain: userDataManage?.user?.attributes?.email.split("@")[1],
            page: page,
            limit: totalData
        },
        alert: true
    });

    if (loading) {
        return <Loading />;
    }

    const tableBody = (data as JobsDataType).result.map((item) => {
        return {
            id: item.objectId,
            job: <ProfileComponent id={item.objectId} image={item.companyLogo} name={item.positionName} />,
            candidateApplied: (
                <Link
                    to={`${item.objectId}`}
                    className="flex flex-col justify-center text-primary font-semibold  text-lg w-[8rem] items-center"
                >
                    <div className="flex justify-center items-center gap-x-1">
                        <FaUserFriends />
                        <p className="text-base">{item.applicantCount}</p>
                    </div>
                    <div className="w-12 border-primary border"></div>
                </Link>
            ),
            createdAt: <DateComponent date={item.createdAt} />,
            deadline: <DateComponent date={item.deadline} />,
            removeUpdate: true,
            removeDelete: true
        };
    });

    const tableData = {
        columnItems: ["Position Name", "Candidate Applied", "Date Initiated", "Deadline", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as JobsDataType).totalCount
        },
        refetch: refetch,
        dataClass: "Jobs"
    };
    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["jobSeo"] || SeoContent.baseSeo} />
            <section className="py-10 ">
                <div className="flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="createJob"
                    >
                        <FaPlus /> Create Jobs
                    </label>
                </div>

                <div></div>

                <Table action tableData={tableData as TableData} />

                {(data as JobsDataType).result.map((item, index: number) => (
                    <div key={index}>
                        <ViewJob item={item} />
                        <UpdateJob refetch={refetch} item={item} />
                    </div>
                ))}

                <CreateJob refetch={refetch} />
                {error && <RetryModal refetch={refetch} />}
            </section>
        </TableLayer>
    );
};

export default JobsPage;

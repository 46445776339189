import React, { useContext } from "react";
import { ChildrenProps } from "../type/Types";
import { DATA_CONTEXT } from "../provider/DataProvider";
import ErrorPage from "../page/ErrorPage/ErrorPage";

function AdminCheck({ children }: ChildrenProps) {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;

    if (isAdmin) {
        return <>{children}</>;
    } else {
        return (
            <>
                <ErrorPage />
            </>
        );
    }
}

export default AdminCheck;

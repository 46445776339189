import React, { createContext, useState, useEffect } from "react";
import { ChildrenProps } from "../type/Types";

export const DARKMODE_CONTEXT = createContext<any | undefined>(undefined);

const DarkModeProvider = ({ children }: ChildrenProps) => {
    const storedDarkMode = localStorage.getItem("darkMode");
    const systemDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const initialDarkMode = storedDarkMode ? storedDarkMode === "true" : systemDarkMode;
    const [darkMode, setDarkMode] = useState(initialDarkMode);
    useEffect(() => {
        if (!storedDarkMode) {
            setDarkMode(systemDarkMode);
        }
    }, [setDarkMode, storedDarkMode, systemDarkMode]);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.remove("light");
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.add("light");
        }

        localStorage.setItem("darkMode", darkMode.toString());
    }, [darkMode]);

    const value: any = { darkMode, setDarkMode };

    return <DARKMODE_CONTEXT.Provider value={value}>{children}</DARKMODE_CONTEXT.Provider>;
};

export default DarkModeProvider;

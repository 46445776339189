import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { updateData } from "../../../hooks/update";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import CountryDropdown from "../../Common/CountryDropdown/CountryDropdown";

const ProfileForm = () => {
    const [editEnable, setEditEnable] = useState(false);
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const [phoneNumber] = useState<string | undefined>(userDataManage.user?.attributes?.phoneNumber);
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const form = formRef.current;
        if (form) {
            const data = {
                firstname: form.firstname.value,
                lastname: form.lastname.value,
                phoneNumber: form.phoneNumber.value
            };
            setEditEnable(false);

            updateData({
                Parse: Parse,
                parseItem: userDataManage.user,
                data: data,
                message: "User Information Updated!",
                refetch: userDataManage.userRefetch
            });
        }
    };
    return (
        <div className="shadow-lg flex flex-col  gap-y-4 justify-center items-center p-4 rounded-md bg-white dark:bg-darkSecondary py-10 col-span-2 w-full">
            <form ref={formRef} onSubmit={handleSubmit} className="grid grid-cols-1  lg:grid-cols-2 gap-4 w-full">
                <CustomInputField
                    name="firstname"
                    title="First Name"
                    type="text"
                    value={userDataManage.user?.attributes?.firstname}
                    required
                    disabled={!editEnable}
                />

                <CustomInputField
                    name="lastname"
                    title="Last Name"
                    type="text"
                    value={userDataManage.user?.attributes?.lastname}
                    required
                    disabled={!editEnable}
                />

                <CustomInputField
                    name="email"
                    title="Email"
                    type="email"
                    value={userDataManage.user?.attributes?.email}
                    required
                    disabled
                />
                <CountryDropdown disabled={!editEnable} phoneNumber={phoneNumber} name="phoneNumber" required />

                <div className="lg:col-span-2 flex justify-end gap-x-3">
                    <button
                        type="button"
                        onClick={() => {
                            setEditEnable(false);
                        }}
                        className={`${
                            !editEnable && "hidden"
                        } px-4 py-2 hover:bg-error duration-300  text-white   bg-error/95 rounded-lg`}
                    >
                        Cancel
                    </button>

                    <button
                        type="submit"
                        className={`${
                            !editEnable && "hidden"
                        } px-4 py-2 hover:bg-dark/90 duration-300 bg-dark text-white dark:bg-white dark:text-dark dark:hover:bg-white-90 rounded-lg`}
                    >
                        Save Change
                    </button>

                    <button
                        type="button"
                        onClick={() => setEditEnable(true)}
                        className={`${
                            editEnable && "hidden"
                        } px-4 py-2 hover:bg-dark/90 duration-300 bg-dark text-white dark:bg-white dark:text-dark dark:hover:bg-white-90 rounded-lg`}
                    >
                        Edit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileForm;

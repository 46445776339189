import { useState, useEffect } from "react";

const useFetchWebsite = ({
    endPoint,
    page = 1,
    limit = 3,
    defaultValue
}: {
    endPoint: string;
    page?: number;
    limit?: number;
    defaultValue: any;
}) => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_PUBLIC_WEBSITE}/api/v1/${endPoint}?page=${page}&limit=${limit}`
                );
                const data = await response.json();
                setData(data.data);
                setLoading(false);
            } catch (error: any) {
                setData(defaultValue);
                setLoading(false);
            }
        };

        fetchData();
    }, [page, limit]);

    return [data, loading];
};

export default useFetchWebsite;

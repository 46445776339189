import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";

const Toggler = ({ darkMode, handleSwitch }: any) => {
    return (
        <button
            onClick={handleSwitch}
            className={`text-2xl w-10 h-10 flex justify-center items-center rounded-full bg-secondary/10 hover:bg-secondary/20 duration-300 dark:text-white text-secondary cursor-pointer`}
        >
            {!darkMode ? <FaMoon /> : <FaSun />}
        </button>
    );
};

export default Toggler;

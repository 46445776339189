import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const CountryDropdown = ({
    title,
    name,
    required,
    disabled,
    phoneNumber,
    mini
}: {
    title?: string;
    name?: string;
    required?: boolean;
    disabled?: boolean;
    phoneNumber?: string;
    mini?: boolean;
}) => {
    const [value, setValue] = useState(phoneNumber || "");
    return (
        <div className="w-full h-full text-dark dark:text-white ">
            <fieldset
                className={`border-2 pl-3 rounded overflow-hidden relative dark:border-primaryBG/80 h-full border-secondary/50 `}
            >
                <legend className={`px-2  text-sm font-medium dark:text-white text-secondary "`}>
                    {title ? title : "Phone Number"}
                </legend>

                {/* This input field is visually hidden but it's data is dynamically changin based on the <PhoneInput> component */}
                <input
                    type="text"
                    name={name}
                    value={value}
                    className=" absolute top-0 opacity-0 outline-0 scale-0"
                    required={required}
                    disabled
                />
                <div className={mini ? "max-w-[13rem]" : "w-full "}>
                    <PhoneInput
                        defaultCountry="US"
                        placeholder=""
                        value={phoneNumber}
                        onChange={(inputValue: string) => setValue(inputValue)}
                        disabled={disabled}
                    />
                </div>
            </fieldset>
        </div>
    );
};

export default CountryDropdown;

import React, { useEffect, useState } from "react";
import AnalyticsSection from "../Analytics/AnalyticsSection";

const InputField = ({
    questionItem,
    result,
    analytics,
    handleAnswerUpdate,
    disabled
}: {
    questionItem: any;
    result?: string;
    analytics?: {
        sentiment_analysis: {
            positive: number;
            negative: number;
            neutral: number;
        };
        overall_score: number;
        reasoning: string;
        words_written: number;
    };
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [answer, setAnswer] = useState(result || "");
    // Update answer and character count when result changes
    useEffect(() => {
        if (result !== undefined) {
            setAnswer(result);
            setCharacter(result?.length);
        }
    }, [result]);
    // Handle changes in the textarea
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const inputValue = e.target.value;
        if (inputValue?.length <= maxCharacterLimit) {
            answer && setAnswer(inputValue);
            setCharacter(inputValue?.length);
        }
    };

    const { maxCharacterLimit, minCharacterLimit, objectId, question } = questionItem;
    const [character, setCharacter] = useState(result ? result?.length : 0);

    return (
        <div className="shadow rounded-md overflow-hidden">
            <div className="bg-white dark:bg-darkSecondary px-6 py-6 ">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold pb-4  ">{question}</h2>
                {answer ? (
                    <textarea
                        name={objectId}
                        rows={4}
                        required
                        value={answer}
                        onChange={handleChange}
                        onBlur={handleAnswerUpdate}
                        placeholder="Your answer"
                        className={`outline-none w-full text-secondary/90 dark:text-white/70  border-[1.5px] border-black/20 py-2 focus:border-primary duration-300 px-2 dark:bg-darkSecondary text-xs md:text-sm  lg:text-base ${disabled && "cursor-not-allowed"}`}
                        minLength={minCharacterLimit}
                        maxLength={maxCharacterLimit}
                        disabled={disabled}
                    />
                ) : (
                    <textarea
                        name={objectId}
                        rows={4}
                        required
                        defaultValue={answer}
                        onChange={handleChange}
                        onBlur={handleAnswerUpdate}
                        placeholder="Your answer"
                        className="outline-none w-full text-secondary/90 dark:text-white/70  border-[1.5px] border-black/20 py-2 focus:border-primary duration-300 px-2 dark:bg-darkSecondary text-xs md:text-sm  lg:text-base "
                        minLength={minCharacterLimit}
                        maxLength={maxCharacterLimit}
                        disabled={disabled}
                    />
                )}
                <h2>
                    <span className={`${character < minCharacterLimit ? "text-error" : "text-success"}`}>
                        {character}
                    </span>{" "}
                    / {maxCharacterLimit}
                </h2>

                {analytics && (
                    <div>
                        <AnalyticsSection analytics={analytics} defaultOpen />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputField;

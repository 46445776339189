import React from "react";
import VideoPlayer from "../../Common/QuestionComponents/VideoPlayer";

const VideoQuestionResponse = ({ question }: any) => {
    return (
        <section className="mb-10 w-full">
            <div className="flex flex-col justify-center items-center w-full">
                {question.map((item: any, index: number) => (
                    <div key={index} className=" shadow rounded-md overflow-hidden mt-4 w-full">
                        {item.response && (
                            <div className="bg-white dark:bg-darkSecondary px-6 py-6 ">
                                <h2 className="text-sm md:text-base lg:text-xl font-semibold pb-4  ">
                                    {item.question}
                                </h2>
                                <div className="flex justify-center gap-x-4 items-center">
                                    <label
                                        htmlFor={item.objectId}
                                        className=" bg-white dark:bg-dark border border-dark dark:border-white text-dark dark:text-white px-3 py-2 lg:px-5 lg:py-3 rounded-lg font-semibold text-xs md:text-base text-center cursor-pointer"
                                    >
                                        Preview
                                    </label>
                                </div>
                            </div>
                        )}
                        <VideoPlayer response={item.response} id={item.objectId} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default VideoQuestionResponse;

import React, { useState, useContext } from "react";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import useFetch from "../../hooks/useFetch";
import { FaClock } from "react-icons/fa6";
import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import Table from "../../components/Common/Table/Table";
import { TableData } from "../../type/Table";
import { MdOutlinePreview } from "react-icons/md";
import image from "../../assets/images/header/person.jpg";
import { FaPlus } from "react-icons/fa";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { DateComponent, ProfileComponent } from "../../components/Common/Table/UtilityComponent";
import { pageData } from "../../content/constantVariable";
import ResumeScannerModal from "../../components/ResumeScanner/ResumeScannerModal";
import ResumeViewer from "../../components/Common/Table/ResumeViewer";
import ResumeResultModal from "../../components/ResumeScanner/ResumeResultModal";

const ResumeScannerPage = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const viewUser = userListData && userListData.usersList[0];
    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "Resume",
        dataQuery: [
            {
                key: "requestorUserId",
                item: adminModeData?.adminMode && isAdmin ? viewUser?.id : userDataManage.user?.id
            }
        ],
        total: { perPage: totalData, page: page },
        alert: true
    });

    if (loading) {
        return <Loading />;
    }

    const tableBody = (data as any)?.result.map((item: any) => {
        return {
            id: item.id,
            candidate: (
                <ProfileComponent
                    id={item.id}
                    image={image}
                    name={item.attributes.resumeData.first_name + " " + item.attributes.resumeData.last_name}
                    email={item.attributes.resumeData.contact.email}
                    phone={item.attributes.resumeData.contact.phone}
                    resumeUrl={item.attributes.resumeUrl}
                />
            ),
            resumeView: (
                <label
                    htmlFor={item.id + "view"}
                    className="text-3xl pl-4 flex items-center hover:text-success duration-300 cursor-pointer"
                >
                    <MdOutlinePreview />
                </label>
            ),
            jobTitle: (
                <div className="font-semibold text-base">{item?.attributes?.resumeData?.candidate_job_title}</div>
            ),
            totalExperience: (
                <div className="flex items-start gap-x-2 ">
                    <span className="text-lg">
                        <FaClock />
                    </span>
                    <div>
                        <span className="text-base font-medium">
                            {item?.attributes?.resumeData?.total_experience_time}
                        </span>
                    </div>
                </div>
            ),

            createdAt: <DateComponent date={item.attributes.createdAt} />,
            removeDelete: true,
            removeView: true
        };
    });

    const tableData = {
        columnItems: ["Candidate", "Resume Result", "Job Title", "Total Experience", "Date Added", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as any)?.totalCount
        },
        refetch: refetch,
        dataClass: "Resume"
    };

    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["scannerSeo"] || SeoContent.baseSeo} />
            <section className="py-10 ">
                <div className="flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="resumeScanner"
                    >
                        <FaPlus /> Resume Scanner
                    </label>
                </div>

                <Table action tableData={tableData as TableData} />

                <div>
                    {(data as any).result.map((item: { id: string; attributes: any }, index: any) => (
                        <div key={index}>
                            <ResumeViewer id={item.id} resumeUrl={item.attributes.resumeUrl} />
                            <ResumeResultModal id={item.id} resumeData={item.attributes.resumeData} />
                        </div>
                    ))}
                </div>

                <ResumeScannerModal refetch={refetch} />
                {error && <RetryModal refetch={refetch} />}
            </section>
        </TableLayer>
    );
};

export default ResumeScannerPage;

import React, { useState } from "react";

const UserSearchField = ({ title, allUserData, setValue }: { title: string; allUserData: any; setValue?: any }) => {
    const [view, setView] = useState(true);
    let defaultString = "";
    if (allUserData.userSearchString !== "*") {
        defaultString = allUserData.userSearchString;
    }
    const handleOnChange = (event: any) => {
        const selectedValue = event.target.value;
        allUserData.setUserSearchString(selectedValue);
    };
    const handleOnClick = (email: string) => {
        allUserData.setUserSearchString(email);
        setView(false);
        if (setValue) {
            setValue(email);
        }
    };

    return (
        <div className="relative w-full h-full ">
            <fieldset
                className={`border-2 pl-3 !h-fit rounded overflow-hidden relative border-secondary/50 dark:border-primaryBG/80 `}
            >
                <legend className={`px-2  text-sm font-medium text-secondary dark:text-white `}>{title}</legend>

                <input
                    onChange={handleOnChange}
                    value={defaultString}
                    onClick={() => setView(true)}
                    className="w-full outline-none  p-2 pb-3 bg-inherit dark:date-input"
                />
            </fieldset>
            {view && allUserData.usersList.length > 0 && (
                <div className="absolute w-full max-h-40 overflow-y-auto right-0 bg-white dark:bg-dark border-2 mt-1 border-secondary/50 dark:border-primaryBG/80 pb-2">
                    <div className="flex flex-col gap-y-1 mt-2">
                        {allUserData.usersList.map((item: any, index: number) => (
                            <button
                                key={index}
                                className="py-1 px-4 font-medium hover:bg-black/10 w-full rounded-lg duration-300 text-start"
                                onClick={() => handleOnClick(item.email)}
                            >
                                {item.email}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserSearchField;

/* eslint-disable no-empty-pattern */
import React, { useContext } from "react";
import logo from "../../assets/images/logo/logo.svg";
import { FiChevronLeft } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { sideBarItems } from "../../content/sideBarItems";
import { Menu } from "../../type/Types";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import SideDropDown from "./SideDropDown";

const SideBar = ({ open, setOpen }: any) => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const isEmployer = userDataManage?.user?.attributes?.isEmployer;
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    let sidebarItemsFilter;

    if (isAdmin) {
        sidebarItemsFilter = sideBarItems;
    } else if (isEmployer) {
        sidebarItemsFilter = sideBarItems.filter((item) => item.isEmployer);
    } else {
        sidebarItemsFilter = sideBarItems.filter((item) => item.isCandidate || item.isReferee);
    }

    return (
        <section
            className={`bg-primaryBG dark:bg-darkBG min-h-screen fixed z-[999] ${
                open ? "w-[100vw] sm:w-[10rem] md:w-[12rem] lg:w-[14rem]" : "w-[4rem] xl:w-[5rem] border-r"
            } duration-300 text-secondary px-4 border-dashed  dark:border-[#FFFFFF1E] dark:text-white sm:border-r`}
        >
            <button
                className={`bg-primaryBG dark:bg-darkBG border border-dark:[#FFFFFF1E] duration-300 rounded-full p-1 text-bold absolute sm:top-[1.8rem] top-[3.5rem] xl:top-7 ${
                    open ? "right-0" : "right-[-1rem] rotate-180"
                } sm:right-[-.8rem] z-[999] duration-300`}
                onClick={() => setOpen(!open)}
            >
                <FiChevronLeft />
            </button>

            <div className="mt-6 xl:mb-10">
                <div className="flex justify-start items-center gap-x-2">
                    <img src={logo} alt="" width={30} height={8} className={`${!open ? " xl:ml-2" : ""} `} />
                    <h2
                        className={`whitespace-pre duration-300 text-xl font-extrabold text-black dark:text-white ${
                            !open ? "opacity-0 overflow-hidden" : ""
                        }`}
                    >
                        HRHouz
                    </h2>
                </div>
            </div>

            <div className={`mt-4 flex flex-col ${open ? "gap-8" : "gap-2"} relative capitalize `}>
                {sidebarItemsFilter.map((menu: Menu, index: number) => (
                    <div key={index}>
                        {menu.subMenu ? (
                            <SideDropDown menu={menu} open={open} />
                        ) : menu.link ? (
                            <NavLink
                                to={menu?.link}
                                className={`group flex ${
                                    !open ? "flex-col" : "flex-row "
                                } items-center text-sm  gap-x-3.5 z-10`}
                            >
                                <div
                                    className={`${
                                        !open ? "ml-[-3px] xl:ml-[0.3rem]  p-2" : ""
                                    } hover:bg-secondary/10 rounded-md  `}
                                >
                                    {menu?.icon && React.createElement(menu.icon, { size: 20 })}
                                </div>
                                {open && (
                                    <h2
                                        className={`whitespace-pre duration-100  ${
                                            !open ? "opacity-0 overflow-hidden " : ""
                                        }`}
                                    >
                                        {menu?.name}
                                    </h2>
                                )}

                                {!open && (
                                    <h2 className={`whitespace-pre duration-100 text-[.65rem] `}>{menu?.name}</h2>
                                )}
                            </NavLink>
                        ) : (
                            <></>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default SideBar;

import React, { useContext, useRef } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { toast } from "react-toastify";

const PasswordChangeForm = ({ email, otp, setOtpVerified }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="passwordChange"]');
        if (form && label) {
            form.reset();
            label.click();
        }
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (formRef.current) {
            const data = {
                password: formRef.current.password.value,
                confirmPassword: formRef.current.confirmPassword.value
            };
            if (data.password === data.confirmPassword) {
                try {
                    const result = await Parse.Cloud.run("ResetPassword", {
                        email: email,
                        passwordResetOTP: otp,
                        password: data.password
                    });

                    if (result.result) {
                        closeModal();
                        if (setOtpVerified) {
                            setOtpVerified(false);
                        }
                        toast.success(result.message, {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    } else {
                        toast.error(result.message, {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                } catch (error: any) {
                    // Handle errors
                    toast.error(error, {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            } else {
                toast.error("Password didn't matched!", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    };

    return (
        <div className="w-full">
            <h2 className="text-center font-bold text-2xl py-6">Change Password</h2>
            <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-y-3">
                <CustomInputField name="password" title="New Password" type="password" required min={8} />
                <CustomInputField
                    name="confirmPassword"
                    title="Confirm New Password"
                    type="password"
                    required
                    min={8}
                />

                <div className="flex justify-end">
                    <button className="btn bg-success text-white hover:bg-primary border-none">Submit</button>
                </div>
            </form>
        </div>
    );
};

export default PasswordChangeForm;

import React, { useState, useEffect } from "react";

const CheckBox = ({
    question,
    value,
    required,
    setRequired,
    result,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    value: any;
    required: boolean;
    setRequired: any;
    result?: any;
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [checked, setChecked] = useState(result && result.includes(value));

    useEffect(() => {
        setChecked(result && result.includes(value));
    }, [result, value]);

    const handleChecked = async (event: any) => {
        if (event.target.checked) {
            setRequired(false);
        }
        await new Promise<void>((resolve) => {
            setChecked(event.target.checked);
            resolve();
        });

        if (handleAnswerUpdate) {
            await handleAnswerUpdate();
        }
    };

    return (
        <li key={value} className="mb-2">
            <label className="inline-flex items-center">
                <input
                    name={question.objectId}
                    type="checkbox"
                    value={checked ? value : ""}
                    className={`${disabled ? "cursor-not-allowed" : "cursor-pointer"} text-indigo-500 h-4 w-4`}
                    onChange={disabled ? () => {} : handleChecked}
                    required={required}
                    checked={checked}
                />
                <span className="ml-2 text-sm lg:text-base">{question.options[value]}</span>
            </label>
        </li>
    );
};

export default CheckBox;

import React, { useState } from "react";

const CustomRadioSelect = ({
    list,
    name,
    title,
    selected,
    required = false,
    disabled = false
}: {
    list: string[];
    name: string;
    title: string;
    selected?: string;
    required?: boolean;
    disabled?: boolean;
}) => {
    const [value, setValue] = useState<string | null>(selected || null);

    return (
        <div>
            <div>
                <h1 className="text-sm font-semibold dark:text-white text-secondary">{title}</h1>
            </div>
            <div className="flex gap-x-4 pt-2">
                {list.map((item: string, index: number) => (
                    <label key={index} className={`flex items-center mb-2 ${disabled ? "cursor-not-allowed" : ""}`}>
                        <input
                            type="radio"
                            name={name}
                            value={item}
                            checked={value === item}
                            onChange={() => setValue(item)}
                            className="form-radio h-4 w-4 text-blue-600"
                            required={required}
                            disabled={disabled}
                        />
                        <span className={`ml-2 ${disabled ? "opacity-80" : ""}`}>{item}</span>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default CustomRadioSelect;

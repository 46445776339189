import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useResumeScanner = ({ Parse, file, fileQuery }: any) => {
    const [data, setData] = useState<any | null>(undefined);
    const [fileExist, setFileExist] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const abortController = new AbortController();

    const devMode: string = process.env.REACT_APP_BACKEND_URL_TEST || "True";
    let totalCount = 0;
    const fetchData = async () => {
        try {
            if (fileQuery) {
                const query = new Parse.Query("Resume");
                fileQuery.forEach((element: { key: string; item: string }) => {
                    element.item && query.equalTo(element.key, element.item);
                });
                totalCount = await query.count();
                const result = await query.find();
                setData(result[0]?.attributes?.resumeData);
            }

            if (totalCount === 0) {
                const formData = new FormData();
                formData.append("resume_file", file);
                formData.append("is_test", devMode);

                // Set a timeout promise
                const timeoutPromise = new Promise<any>((_, reject) => {
                    setTimeout(() => {
                        reject(new Error("Request timeout"));
                        abortController.abort(); // Abort the request on timeout
                    }, 45000); // 45 seconds timeout
                });

                // Make the API request and race it with the timeout promise
                const response = await Promise.race([
                    fetch(new URL("/resume_parser/v1/resume/all", process.env.REACT_APP_BACKEND_URL) || "", {
                        method: "POST",
                        body: formData,
                        signal: abortController.signal
                    }),
                    timeoutPromise
                ]);

                if (response instanceof Response && response.ok) {
                    const responseData: any = await response.json();
                    setData(responseData);
                    setLoading(false);
                } else {
                    const responseData: any = await response.json();
                    setData(null);
                    toast.error(responseData["message"], { autoClose: 10000 });
                    setLoading(false);
                }
            }
            setLoading(false);
            setFileExist(totalCount > 0 ? true : false);
        } catch (error: any) {
            toast.error(error.message, { autoClose: 10000 });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (file) {
            fetchData();
        } else {
            toast.error("Please Upload PDF Resume", { autoClose: 10000 });
            setLoading(false);
        }
        // Cleanup the AbortController when the component unmounts
        return () => abortController.abort();
    }, [file]);

    return { data, loading, fileExist };
};

export default useResumeScanner;

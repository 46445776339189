import React from "react";
import calender from "../../assets/images/pdf/calender.png";
import statusLogo from "../../assets/images/pdf/status.png";
import groupLogo from "../../assets/images/pdf/group.png";
import officeLogo from "../../assets/images/pdf/office.png";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./PDFStyle";
import { statusColor } from "../../utils/statusColor";

const UserLocation = ({
    otherInformation
}: {
    otherInformation: { status?: string; createdDate?: string; group?: string; office?: string };
}) => {
    const { status, createdDate, group, office } = otherInformation;
    return (
        <View style={{ width: "25%" }}>
            <View style={{ display: "flex", flexDirection: "row", gap: 4, alignItems: "center" }}>
                <View style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    {group && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Image src={groupLogo} style={styles.icon} />
                            <Text style={{ ...styles.fontSmall, opacity: 0.8 }}>{group}</Text>
                        </View>
                    )}
                    {office && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Image src={officeLogo} style={styles.icon} />
                            <Text style={{ ...styles.fontSmall, opacity: 0.8 }}>{office}</Text>
                        </View>
                    )}
                    {createdDate && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                            <Image src={calender} style={styles.icon} />
                            <Text style={{ ...styles.fontSmall, opacity: 0.8 }}>{createdDate}</Text>
                        </View>
                    )}
                    {status && (
                        <View style={{ display: "flex", flexDirection: "row", gap: 2, marginTop: 6 }}>
                            <Image src={statusLogo} style={styles.icon} />
                            <Text
                                style={{
                                    ...styles.fontSmall,
                                    backgroundColor: statusColor(status),
                                    padding: 4,
                                    borderRadius: 5,
                                    color: "white",
                                    fontWeight: "demibold",
                                    textTransform: "capitalize"
                                }}
                            >
                                {status}
                            </Text>
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};

export default UserLocation;

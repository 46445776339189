import React from "react";
import InputField from "../../Common/QuestionComponents/InputField";

const SubjectiveQuestionResponse = ({ question }: any) => {
    return (
        <div className="flex flex-col gap-y-3 pt-3">
            {question.map((item: any) => (
                <InputField
                    questionItem={item}
                    key={item.objectId}
                    result={item.response}
                    analytics={item.analytics}
                    disabled
                />
            ))}
        </div>
    );
};

export default SubjectiveQuestionResponse;

import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";

const RadarChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const chartData = {
        series: data.series,
        options: {
            chart: {
                toolbar: {
                    show: false
                },
                height: 300,
                type: "radar",
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                }
            },
            legend: {
                labels: {
                    colors: darkMode ? "white" : "black"
                }
            },

            stroke: {
                width: 2
            },
            fill: {
                opacity: 0.1
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: data.categories,
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },
            yaxis: {
                max: 100,
                min: 0,
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },

            toolbar: {
                show: false
            },
            colors: ["#FABD23", "#3b82f6", "#bef264", "#a855f7", "#06b6d4", "#34d399"]
        } as ApexOptions
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="radar" height={350} />
        </div>
    );
};

export default RadarChart;

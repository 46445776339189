import React, { useContext, useState } from "react";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { GiNetworkBars } from "react-icons/gi";
import { Link, useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import ErrorPage from "../ErrorPage/ErrorPage";
import { jobsPageData } from "../../content/constantVariable";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { CiMenuKebab } from "react-icons/ci";
import Pagination from "../../components/Common/Pagination/Pagination";
import { ApplicantsType } from "../../type/ApplicantsType";
import { dateFormatter } from "../../utils/dateFormat";
import { BsBriefcaseFill } from "react-icons/bs";
import { FaMapMarkedAlt } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import ResumeViewer from "../../components/Common/Table/ResumeViewer";
import { AiOutlineEye } from "react-icons/ai";
import { HiPencilAlt } from "react-icons/hi";
import { BiArrowBack, BiTrashAlt } from "react-icons/bi";
import Swal from "sweetalert2";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";
import { deleteItem } from "../../hooks/delete";
import { updateData } from "../../hooks/update";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import ResumeResultModal from "../../components/ResumeScanner/ResumeResultModal";
import RadialProgress from "../../components/Common/Analytics/RadialProgress";
import { PiPercentFill } from "react-icons/pi";

interface ApplicantsDataType {
    totalCount: number;
    result: ApplicantsType[];
}

const JobsResponsePage = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { id } = useParams();
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const isEmployer = userDataManage?.user?.attributes?.isEmployer;
    const isAdmin = userDataManage?.user?.attributes?.isAdmin;
    const [totalData, setTotalData] = useState(jobsPageData.dataPerPage);
    const [page, setPage] = useState(jobsPageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "Applicants",
        dataQuery: [{ key: "jobId", item: id }],
        total: { perPage: totalData, page: page },
        alert: true
    });

    if (loading) {
        return <Loading />;
    }

    if (isAdmin || isEmployer) {
        return (
            <TableLayer>
                <HelmetConfiguration seoData={SeoContent["jobSeo"] || SeoContent.baseSeo} />
                <section className="pt-10">
                    <div className=" pt-3 ">
                        <Link to="/jobs" className="left-2 flex  gap-x-2 items-center text-lg font-semibold">
                            <BiArrowBack />
                            <span>Back</span>
                        </Link>
                    </div>
                    <div className="relative overflow-x-auto z-0 shadow-lg  my-10 min-h-[78.6vh] bg-white dark:bg-darkSecondary xl:rounded-lg flex flex-col justify-between ">
                        {(data as ApplicantsDataType)?.result.length > 0 ? (
                            <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-4 p-6">
                                {(data as ApplicantsDataType)?.result.map((item: ApplicantsType, index: number) => (
                                    <ApplicantCard item={item} key={index} refetch={refetch} />
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center w-full font-bold p-6 h-[70vh] text-xl">
                                Not Data Found
                            </div>
                        )}
                        <Pagination
                            jobs
                            page={page}
                            itemCount={totalData}
                            setItemCount={setTotalData}
                            setPage={setPage}
                            totalItemCount={(data as ApplicantsDataType)?.totalCount || 0}
                        />
                    </div>

                    <div>
                        {(data as ApplicantsDataType)?.result.map((item: ApplicantsType, index: any) => (
                            <div key={index}>
                                <ResumeViewer id={item.id} resumeUrl={item.attributes.resumeUrl} />
                                <ResumeResultModal
                                    id={item.id}
                                    resumeData={item.attributes.resumeData}
                                    coverLetter={item.attributes.coverLetter}
                                />
                            </div>
                        ))}
                    </div>
                </section>
            </TableLayer>
        );
    } else {
        return <ErrorPage />;
    }
};

export default JobsResponsePage;

const ApplicantCard = ({ item, refetch }: { item: ApplicantsType; refetch: any }) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                popup: darkMode ? "swal-dark-mode" : "swal-light-mode",
                confirmButton: darkMode ? "swal-dark-mode-confirm" : "swal-light-mode-confirm",
                cancelButton: darkMode ? "swal-dark-mode-cancel" : "swal-light-mode-cancel"
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteItem({
                    Parse: Parse,
                    dataClass: "Applicants",
                    dataId: item.id,
                    refetch: [refetch]
                });
                Swal.fire({
                    title: "Deleted!",
                    text: "Your Data has been deleted.",
                    icon: "success",
                    customClass: {
                        popup: darkMode ? "swal-dark-mode" : "swal-light-mode"
                    }
                });
            }
        });
    };

    const handleSelect = () => {
        updateData({
            Parse: Parse,
            parseItem: item,
            data: { selected: item.attributes.selected ? false : true },
            message: item.attributes.selected ? "Applicant Removed" : "Applicant Selected!",
            refetch: refetch
        });
    };
    return (
        <div className={`border ${item.attributes.selected ? "border-success bg-success/5" : ""}  rounded-lg shadow`}>
            <div className=" p-4 flex justify-between items-start">
                <div>
                    <div className="flex items-center gap-x-1">
                        <label htmlFor={item.id} className="hover:text-blue-500 duration-300 cursor-pointer">
                            <FaRegFilePdf />
                        </label>
                        <h1 className="font-semibold">
                            {item.attributes?.firstName + " " + item.attributes?.lastName}
                        </h1>
                    </div>

                    <p className="text-gray-500 dark:text-white/60 text-xs">
                        Applied Date: {dateFormatter(item.attributes.createdAt)}{" "}
                    </p>

                    <a
                        href={`mailto: ${item.attributes?.email}`}
                        className="text-xs flex items-center gap-x-1 text-primary mt-2"
                    >
                        <MdEmail /> {item.attributes?.email}
                    </a>
                    {item?.attributes?.resumeData?.match_score && (
                        <div
                            className={`text-xs flex items-center gap-x-1 ${colorCheck(item.attributes.resumeData.match_score)} mt-2`}
                        >
                            <PiPercentFill /> Match Score: {item.attributes.resumeData.match_score}%
                        </div>
                    )}
                </div>
                <div className="relative">
                    <div>
                        <div className="dropdown dropdown-end">
                            <div tabIndex={0} role="button">
                                <CiMenuKebab />
                            </div>
                            <ul
                                tabIndex={0}
                                className="dropdown-content z-[1] bg-white menu p-2 shadow  rounded-box w-52 dark:bg-darkSecondary border"
                            >
                                <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg ">
                                    <label htmlFor={item.id + "view"} className="dark:!text-white active:!bg-success">
                                        <span className="text-lg ">
                                            <AiOutlineEye />
                                        </span>
                                        View
                                    </label>
                                </li>

                                <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                                    <button
                                        onClick={handleSelect}
                                        className={` ${item.attributes.selected ? "text-error" : "active:!bg-success dark:!text-white"}`}
                                    >
                                        <span className="text-lg">
                                            {item.attributes.selected ? <IoIosRemoveCircleOutline /> : <HiPencilAlt />}
                                        </span>
                                        {item.attributes.selected ? "Remove" : "Select"}
                                    </button>
                                </li>

                                <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                                    <button
                                        onClick={handleDelete}
                                        type="button"
                                        className="dark:!text-white active:!bg-success"
                                    >
                                        <span className="text-lg">
                                            <BiTrashAlt />
                                        </span>
                                        Delete
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-t border-dashed w-full mb-4" />
            <div className="flex flex-col px-4 pb-4 gap-y-2 opacity-60 ">
                <div className="flex items-center gap-x-2 text-sm font-semibold break-all ">
                    <BsBriefcaseFill />
                    {item.attributes.resumeData?.candidate_job_title}
                </div>
                <div className="flex items-center gap-x-2 text-sm font-semibold">
                    <GiNetworkBars />
                    {item.attributes.resumeData?.total_experience_time}
                </div>
                {(item.attributes.resumeData?.location?.city || item.attributes.resumeData?.location?.country) && (
                    <div className="flex items-center gap-x-2 text-sm font-semibold break-all">
                        <FaMapMarkedAlt />
                        {item.attributes.resumeData.location?.city}
                        {item.attributes.resumeData.location?.country
                            ? `,${item.attributes.resumeData.location?.country}`
                            : ""}
                    </div>
                )}
            </div>
        </div>
    );
};

const colorCheck = (value: number) => {
    if (value >= 0 && value <= 33.33) {
        return "text-error";
    } else if (value >= 33.34 && value <= 66.67) {
        return "text-warning ";
    } else {
        return "text-primary";
    }
};

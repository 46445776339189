import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import CountryDropdown from "../Common/CountryDropdown/CountryDropdown";

const RefereeInfoBox = ({
    result,
    item,
    deleteButton,
    removeBox
}: {
    result?: {
        refereeFirstname: string;
        refereeLastname: string;
        refereeEmail: string;
        refereePhoneNumber: string;
        refereeRelationship: string;
        refereeWorkplace: string;
        refereeStartPeriod: string;
        refereeEndPeriod: string;
        refereeLinkedIn?: string;
    };
    item: number;
    deleteButton?: boolean;
    removeBox?: any;
}) => {
    const [startDate, setStartDate] = useState();

    return (
        <div className="p-4 rounded-lg border ">
            <div className="border-b flex justify-between">
                <h1 className="text-lg font-semibold">Referee {item + 1}</h1>
                {deleteButton && (
                    <button type="button" onClick={removeBox} className="text-error text-lg p-1">
                        <AiOutlineClose />
                    </button>
                )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-6">
                <CustomInputField
                    name={"refereeFirstname" + item}
                    value={result?.refereeFirstname}
                    title="First Name"
                    type="text"
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeLastname" + item}
                    value={result?.refereeLastname}
                    title="Last Name"
                    type="text"
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeEmail" + item}
                    value={result?.refereeEmail}
                    title="Email Address"
                    type="email"
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CountryDropdown
                    name={"refereePhoneNumber" + item}
                    phoneNumber={result?.refereePhoneNumber}
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeRelationship" + item}
                    value={result?.refereeRelationship}
                    title="Relationship"
                    type="text"
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeWorkplace" + item}
                    value={result?.refereeWorkplace}
                    title="Workplace"
                    type="text"
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeLinkedIn" + item}
                    value={result?.refereeLinkedIn}
                    title="LinkedIn"
                    type="url"
                    disabled={result?.refereeEndPeriod ? true : false}
                />

                <CustomInputField
                    name={"refereeStartPeriod" + item}
                    value={result?.refereeStartPeriod}
                    title="Start Period"
                    type="date"
                    updateData={setStartDate}
                    disabled={result?.refereeEndPeriod ? true : false}
                    required
                />
                <CustomInputField
                    name={"refereeEndPeriod" + item}
                    value={result?.refereeEndPeriod}
                    title="End Period"
                    type="date"
                    required
                    min={startDate}
                    disabled={result?.refereeEndPeriod ? true : false}
                />
            </div>
        </div>
    );
};

export default RefereeInfoBox;

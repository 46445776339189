import React from "react";
import { Helmet } from "react-helmet";

const HelmetConfiguration = ({ seoData }: any) => {
    const { title, description, keywords, image, siteUrl } = seoData;

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="author" content="https://www.mytytech.com" />

                {/* Open Graph (OG) Tags */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={siteUrl} />
                <meta property="og:type" content="application" />

                {/* Keywords */}
                <meta name="keywords" content={keywords} />

                {/* Twitter Card Tags */}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />

                {/* Robots Meta Tag */}
                <meta name="robots" content="index, follow" />
            </Helmet>
        </div>
    );
};

export default HelmetConfiguration;

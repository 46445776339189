import { View, Text } from "@react-pdf/renderer";
import React from "react";

const QuestionCountPDF = ({ questions }: { questions: number }) => {
    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}
        >
            <View
                style={{
                    width: 35,
                    height: 35,
                    borderRadius: "30%",
                    border: 3,
                    borderColor: "#570DF8",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#570DF8"
                }}
            >
                <Text style={{ color: "white", fontSize: 12, fontWeight: "bold" }}>{questions}</Text>
            </View>
            <Text style={{ fontSize: 10 }}>questions</Text>
        </View>
    );
};

export default QuestionCountPDF;

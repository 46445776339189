import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import person from "../../assets/images/header/person.jpg";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";

const User = () => {
    const navigate = useNavigate();
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { user, setUser } = userDataManage;

    const handleLogout = () => {
        Parse.User.logOut().then(async () => {
            await setUser(null);
            navigate("/login");
        });
    };
    return (
        <div className="dropdown dropdown-end">
            <label tabIndex={0} className="cursor-pointer">
                <div className="w-10 h-10 rounded-full overflow-hidden">
                    <img
                        src={user?.attributes?.userImage || person}
                        alt={person}
                        className="object-cover w-10 h-10 object-center"
                    />
                </div>
            </label>
            <ul
                tabIndex={0}
                className="mt-3 z-[1] rounded-md  shadow-md  dropdown-content bg-base-100   dark:bg-darkSecondary dark:text-white w-52"
            >
                <li className="border-b border-dashed	 p-4">
                    <h1 className="font-bold break-all">
                        {user?.attributes?.firstname} {user?.attributes?.lastname}
                    </h1>
                    <h1 className="text-[.8rem] font-base break-all">{user?.attributes?.email}</h1>
                </li>
                <li className="px-2 pt-1 w-full flex justify-start items-center">
                    <NavLink to="/profile" className="px-2 py-1 duration-300 hover:bg-gray-200 w-full hover:rounded-lg">
                        Profile
                    </NavLink>
                </li>
                <li className="px-2 py-1 w-full flex justify-start items-center border-b border-dashed	">
                    <NavLink
                        to="/settings"
                        className="px-2 py-1 duration-300 hover:bg-gray-200 w-full hover:rounded-lg"
                    >
                        Settings
                    </NavLink>
                </li>

                <li className="px-2 py-1 w-full flex justify-start items-center">
                    <button
                        onClick={handleLogout}
                        className="px-2 py-1 duration-300 hover:bg-gray-200 w-full hover:rounded-lg font-bold text-red-400"
                    >
                        Logout
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default User;

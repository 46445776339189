import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
import { useContext } from "react";

const StackedChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const chartData = {
        series: data.series,
        options: {
            chart: {
                type: "bar",
                height: 350,
                stacked: true,
                stackType: "100%",
                toolbar: {
                    show: false
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: "bottom",
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }
            ],
            xaxis: {
                categories: data.categories,
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: darkMode ? "white" : "black"
                    }
                }
            },
            fill: {
                opacity: 1
            },
            legend: {
                position: "right",
                offsetX: 0,
                offsetY: 50,
                labels: {
                    colors: darkMode ? "white" : "black"
                }
            },
            tooltip: {
                style: {
                    color: "black"
                },
                theme: darkMode ? "dark" : "light"
            },
            colors: ["#00A76F", "#FABD23", "#D32F2F"]
        } as ApexOptions
    };

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default StackedChart;

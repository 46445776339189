import React, { useContext } from "react";
import banner from "../../assets/images/dashboard/banner.svg";
import bannerPerson from "../../assets/images/dashboard/bannerPerson.png";
import { SIDEBAR_CONTEXT } from "../../provider/SideBarProvider";
import { DATA_CONTEXT } from "../../provider/DataProvider";

const WelcomeBanner = () => {
    const { sideBarOpen } = useContext(SIDEBAR_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};

    const backgroundLinear = {
        background: `linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.2)), rgb(255, 255, 255)`
    };
    return (
        <div
            style={backgroundLinear}
            className="p-6 xl:p-12 rounded-lg  flex flex-col sm:flex-row justify-between items-center col-span-2 gap-6 mr-4"
        >
            <div>
                <h1 className="text-[#004b50] text-2xl lg:text-3xl font-bold">Welcome Back 👋</h1>
                <h1 className="text-[#004b50] text-2xl lg:text-3xl font-bold">
                    {userDataManage.user?.attributes?.firstname} {userDataManage.user?.attributes?.lastname}
                </h1>

                <p className="text-[#004b50] text-base pt-6 lg:w-8/12">Reference Checks - Made Easy</p>
                <p className="text-[#004b50] text-base pt-6 lg:w-8/12">
                    HRHouz's AI driven automated reference check software helps employers make informed hiring decisions
                    with speed & confidence.
                </p>
            </div>
            <div className="relative duration-300 lg:py-6">
                <img src={banner} alt="" className="w-[35rem] lg:w-[22rem]" />
                <img
                    src={bannerPerson}
                    alt=""
                    className={`absolute bottom-0 right-0 ${
                        sideBarOpen ? "h-[12rem] lg:h-[14rem]" : "h-[12rem] sm:h-[14rem] md:h-[15rem]  "
                    } 2xl:h-[18rem] p-6 duration-300`}
                />
            </div>
        </div>
    );
};

export default WelcomeBanner;

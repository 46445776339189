export const blogData = [
    {
        id: 1,
        title: "Join Our Beta Program",
        url: "/blog/Join_Our_Beta_Program",
        image: "/images/blog/Join_Our_Beta_Program/banner.png"
    },
    {
        id: 2,
        title: "Reference Checks - Made Easy",
        url: "/blog/Reference_Checks_Made_Easy",
        image: "/images/blog/Reference_Checks_Made_Easy/banner.png"
    },
    {
        id: 3,
        title: "Introducing HRHouz",
        url: "/blog/Introducing_HRHouz",
        image: "/images/blog/Introducing_HRHouz/banner.png"
    },
    {
        id: 4,
        title: "Blog | HRHouz",
        url: "/blog",
        image: "/images/blog/blog.png"
    }
];

import React from "react";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const SettingsPage = () => {
    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["settingsSeo"] || SeoContent.baseSeo} />
            <div className=" flex items-center justify-center h-[90vh]">
                <div className="text-center ">
                    <h1 className="text-5xl font-extrabold mb-6">Coming Soon</h1>
                    <p className="text-lg mb-8">We're working hard to bring you something amazing. Stay tuned!</p>

                    <a
                        target="__blank"
                        href="https://www.hrhouz.com/contact"
                        className="bg-primary hover:bg-primary/80  py-2 px-6 rounded-lg inline-flex items-center transition duration-300"
                    >
                        <span className="font-bold text-white">Contact Us</span>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default SettingsPage;

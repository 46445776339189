import { toast } from "react-toastify";

export const updateData = async ({
    parseItem,
    data,
    message,
    refetch,
    closeModal,
    navigate,
    loading,
    autoSave
}: any) => {
    if (loading) {
        loading(true);
    }
    const dataKeys = Object.keys(data);
    dataKeys.map((item) => parseItem.set(item, data[item]));

    parseItem.save().then(
        () => {
            if (refetch) {
                refetch();
            }
            if (closeModal) {
                closeModal();
            }
            if (navigate) {
                navigate();
            }
            if (loading) {
                loading(false);
            }
            if (message) {
                toast.success(message, {
                    autoClose: autoSave ? 10 : 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        },
        (error: any) => {
            if (message) {
                if (loading) {
                    loading(false);
                }
                toast.error(error.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    );
};

import React, { useEffect, useState } from "react";
import AnalyticsSection from "../Analytics/AnalyticsSection";

const SingleChoiceComponent = ({
    question,
    result,
    analytics,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    result?: any;
    analytics?: {
        overall_score: number;
        reasoning: string;
    };
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [selectedOption, setSelectedOption] = useState(result || "");
    useEffect(() => {
        if (result !== undefined) {
            setSelectedOption(result);
        }
    }, [result]);

    const handleRadioChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value);
        if (handleAnswerUpdate) {
            await handleAnswerUpdate();
        }
    };

    return (
        <div className="overflow-hidden  shadow rounded-md">
            <div className=" bg-white dark:bg-darkSecondary  px-6 py-4 ">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold ">{question.question}</h2>
                <p className="text-error font-semibold mb-4">single choice*</p>
                <ul>
                    {Object.keys(question.options).map((key) => (
                        <li key={key} className="mb-2">
                            <label className="inline-flex items-center">
                                <input
                                    name={question.objectId}
                                    type="radio"
                                    checked={selectedOption === key}
                                    onChange={handleRadioChange}
                                    value={key}
                                    required
                                    className={`radio checked:bg-blue-500 border-2 border-blue-500 !opacity-100`}
                                    disabled={disabled}
                                />
                                <span className="ml-2 text-sm lg:text-base ">{question.options[key]}</span>
                            </label>
                        </li>
                    ))}
                </ul>
                {analytics && (
                    <div>
                        <AnalyticsSection analytics={analytics} defaultOpen />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleChoiceComponent;

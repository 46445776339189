import { View, Text } from "@react-pdf/renderer";
import React from "react";

const SliderObjectivePDF = ({ data }: any) => {
    return (
        <View style={{ marginTop: 14 }}>
            <View
                style={{
                    backgroundColor: "#36D399",
                    padding: 2,
                    borderRadius: 8,
                    width: `${data.response}%`,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                }}
            >
                <View
                    style={{ width: 10, height: 10, padding: 2, borderRadius: "100%", backgroundColor: "white" }}
                ></View>
            </View>
            <View
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                {Object.keys(data.options).map((key) => (
                    <Text
                        key={key}
                        style={{
                            fontSize: 10,
                            borderRadius: 5,
                            padding: 3,
                            paddingVertical: 8
                        }}
                    >
                        {data.options[key]}
                    </Text>
                ))}
            </View>
        </View>
    );
};

export default SliderObjectivePDF;

import React from "react";
import check from "../../../assets/images/pdf/check.png";
import warning from "../../../assets/images/pdf/warning.png";
import { View, Text, Image } from "@react-pdf/renderer";

const HighlightsPDF = ({ result, data }: { data: string[]; result: string }) => {
    return (
        <View style={{ display: "flex", flexDirection: "column", gap: "3", width: "100%", marginTop: 5 }}>
            <View style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                {data.map((item, index) => (
                    <View
                        key={index}
                        style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: "2" }}
                    >
                        <View
                            style={{
                                height: 12,
                                width: 12,
                                backgroundColor: result === "positive" ? "#00A76F" : "#D32F2F",
                                borderRadius: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Image src={result === "positive" ? check : warning} style={{ width: 8 }}></Image>
                        </View>
                        <Text style={{ fontSize: 10 }}>{item}</Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default HighlightsPDF;

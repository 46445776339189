import React, { useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const ResumeUploader = ({ setFile, setFileBase64 }: any) => {
    const [dragging, setDragging] = useState(false);

    const handleDragEnter = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = (e: any) => {
        e.preventDefault();
        setDragging(false);
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
        setDragging(true);
    };

    function convertPdfContentToBase64(file: File): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();

            // Slice the file to exclude metadata
            const contentBlob = file.slice(0, file.size, file.type);

            reader.onload = () => {
                const base64String = reader.result?.toString().split(",")[1] || "";
                resolve(base64String);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsDataURL(contentBlob);
        });
    }

    const handleFileDrop = (e: any) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            const fileName = file.name;
            const fileExtension = fileName.split(".").pop().toLowerCase();
            if (fileExtension === "pdf") {
                if (file.size / 1000 > 1000) {
                    toast.error("Please Upload PDF file under 1MB only!", {
                        autoClose: 10000
                    });
                } else {
                    convertPdfContentToBase64(file)
                        .then((base64String) => {
                            setFileBase64(base64String);
                            setFile(file);
                        })
                        .catch((error) => {
                            console.error("Error converting PDF to Base64:", error);
                        });
                }
            } else {
                toast.error("Please Upload PDF file only!", { autoClose: 10000 });
            }
        }
    };
    const handleFileUpload = (e: any) => {
        const file = e.target.files[0];
        const fileName = file.name;
        const fileExtension = fileName.split(".").pop().toLowerCase();
        if (fileExtension === "pdf") {
            if (file.size / 1000 > 1000) {
                toast.error("Please Upload PDF file under 1MB only!", {
                    autoClose: 10000
                });
            } else {
                convertPdfContentToBase64(file)
                    .then((base64String) => {
                        setFileBase64(base64String);
                        setFile(file);
                    })
                    .catch((error) => {
                        console.error("Error converting PDF to Base64:", error);
                    });
            }
        } else {
            toast.error("Please Upload PDF file only!", { autoClose: 10000 });
        }
    };
    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>('[for="resumeScanner"]');
        if (label) {
            label.click();
        }
    };

    return (
        <div
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleFileDrop}
            className=" flex flex-col justify-center gap-y-4"
        >
            <div className="absolute top-0 right-0 bg-error px-2 pt-1 text-3xl text-white rounded-bl-lg">
                <button type="button" onClick={closeModal}>
                    <IoCloseOutline />
                </button>
            </div>
            <form className="z-0  rounded-lg border shadow-md dark:bg-dark ">
                <div>
                    <div className="flex items-center justify-center">
                        <input
                            type="file"
                            id="fileUpload"
                            className="hidden"
                            accept="application/pdf"
                            onChange={handleFileUpload}
                        />
                        <label
                            htmlFor="fileUpload"
                            className="flex min-h-[25rem] min-w-[20rem] cursor-pointer flex-col  items-center justify-center "
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-10 w-10"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                            </svg>
                            <p className="text-gray-600 dark:text-gray-400 mt-2 text-center text-sm">
                                {dragging ? (
                                    "Drop the PDF resume here"
                                ) : (
                                    <span>
                                        Click or drag and drop a PDF resume here <br />
                                        (max size: 1MB)
                                    </span>
                                )}
                            </p>
                        </label>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ResumeUploader;

import React, { useState } from "react";
import ResumeUploader from "./ResumeUploader";
import ResumeScannerOutput from "./ResumeScannerOutput";

const ResumeScannerModal = ({ refetch }: any) => {
    const [file, setFile] = useState(null);
    const [fileBase64, setFileBase64] = useState(null);

    return (
        <div>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="resumeScanner" className="modal-toggle" />
            <div className="modal" role="dialog">
                <div className="modal-box p-10 dark:bg-dark w-11/12 max-w-4xl modalScroll">
                    {file ? (
                        <ResumeScannerOutput
                            file={file}
                            setFile={setFile}
                            refetch={refetch}
                            fileBase64={fileBase64}
                            setFileBase64={setFileBase64}
                        />
                    ) : (
                        <ResumeUploader setFile={setFile} setFileBase64={setFileBase64} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ResumeScannerModal;

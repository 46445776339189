import { View, Text } from "@react-pdf/renderer";
import React from "react";
import PieChartMiniPDF from "./Analytics/PieChartMiniPDF";
import WordCountPDF from "./Analytics/WordCountPDF";
import ScorePDF from "./Analytics/ScorePDF";
import QuestionCountPDF from "./Analytics/QuestionCountPDF";
import AnalyticsIconPDF from "./Analytics/AnalyticsIconPDF";
import SentimentPDF from "./Analytics/SentimentPDF";
import FingerPrintPDF from "./Analytics/FingerPrintPDF";
import { AnalyticsType } from "../../type/AnalyticsType";
import HighlightsPDF from "./Analytics/HighlightsPDF";

const AnalyticsPDF = ({
    analytics,
    refereeCount,
    subjectiveQuestionLength
}: {
    analytics: AnalyticsType;
    refereeCount?: number;
    subjectiveQuestionLength?: number;
}) => {
    let analyticsData = null;

    if (analytics?.sentiment_analysis) {
        analyticsData = [
            analytics?.sentiment_analysis?.positive,
            analytics?.sentiment_analysis?.neutral,
            analytics?.sentiment_analysis?.negative
        ];
    }

    return (
        <View style={{ display: "flex", flexDirection: "column" }}>
            <View
                style={{
                    padding: 10,
                    width: "90%",
                    marginHorizontal: "auto",
                    backgroundColor: "#EEF0F3",
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    display: "flex",
                    flexDirection: "row",
                    gap: 4
                }}
            >
                <AnalyticsIconPDF />
                {analytics?.overall_score && <ScorePDF score={analytics?.overall_score} />}
                {analyticsData && <PieChartMiniPDF data={analyticsData.reverse()} />}
                {analytics?.words_written && subjectiveQuestionLength && (
                    <WordCountPDF
                        wordCount={analytics?.words_written}
                        questions={subjectiveQuestionLength}
                        refereeCount={refereeCount || 1}
                    />
                )}
                {analytics?.questions && <QuestionCountPDF questions={analytics?.questions} />}
            </View>
            <View
                style={{
                    padding: 20,
                    width: "90%",
                    marginHorizontal: "auto",
                    backgroundColor: "#FAFAFA",
                    display: "flex",
                    flexDirection: "column",
                    gap: 4
                }}
            >
                {analytics?.summary && (
                    <View>
                        <Text style={{ fontSize: 14, fontWeight: "demibold", marginTop: 5 }}>Summary</Text>

                        <Text style={{ fontSize: 10, fontWeight: "normal", marginTop: 5 }}>{analytics.summary}</Text>
                    </View>
                )}
                {analytics.positive_highlights && (
                    <View>
                        <Text style={{ fontSize: 14, fontWeight: "demibold", marginTop: 5, marginBottom: 5 }}>
                            Positive Highlights
                        </Text>
                        <View style={{ display: "flex", flexDirection: "row", gap: "50", marginBottom: 10 }}>
                            <View>
                                {analytics?.positive_highlights && (
                                    <HighlightsPDF result="positive" data={analytics.positive_highlights} />
                                )}
                            </View>
                        </View>
                    </View>
                )}
                {analytics.negative_highlights && (
                    <View>
                        <Text style={{ fontSize: 14, fontWeight: "demibold", marginTop: 5, marginBottom: 5 }}>
                            Negative Highlights
                        </Text>
                        <View style={{ display: "flex", flexDirection: "row", gap: "50", marginBottom: 10 }}>
                            <View>
                                {analytics?.negative_highlights && (
                                    <HighlightsPDF result="negative" data={analytics.negative_highlights} />
                                )}
                            </View>
                        </View>
                    </View>
                )}

                <View style={{ display: "flex", flexDirection: "row", gap: "4" }}>
                    <View style={{ width: "50%" }}>
                        {analytics?.sentiment_analysis && <SentimentPDF data={analytics?.sentiment_analysis} />}
                    </View>
                    <View style={{ width: "50%" }}>
                        {analytics?.fingerprint_result && <FingerPrintPDF data={analytics?.fingerprint_result} />}
                    </View>
                </View>
                {analytics?.reasoning && (
                    <View style={{ display: "flex", flexDirection: "column", gap: "3", width: "100%" }}>
                        <Text style={{ fontSize: 14, fontWeight: "demibold" }}>Reasoning</Text>
                        <Text style={{ fontSize: 10 }}>{analytics?.reasoning}</Text>
                    </View>
                )}
            </View>
        </View>
    );
};

export default AnalyticsPDF;

import { useEffect, useState } from "react";

const useUserList = ({ Parse, userSearchString }: any) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Parse.Cloud.run("GetAllUsers", { emailFilter: userSearchString });
                setData(result);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [userSearchString]);

    return [data];
};

export default useUserList;

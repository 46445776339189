import React from "react";
import { FaPhoneAlt, FaFacebookF, FaGithub, FaInstagramSquare, FaLinkedin, FaYoutube, FaGlobe } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { ResumeData } from "../../type/ResumeType";

const ContactGroup = ({ title, data }: { title: string; data: ResumeData }) => {
    const { contact } = data;

    let socialData = [];

    if (contact?.phone) {
        socialData.push({
            logo: <FaPhoneAlt />,
            url: "tel:" + contact.phone
        });
    }
    if (contact?.email) {
        socialData.push({ logo: <MdEmail />, url: "mailto:" + contact.email });
    }
    if (contact?.socials?.facebook) {
        socialData.push({
            logo: <FaFacebookF />,
            url: contact.socials.facebook
        });
    }
    if (contact?.socials?.github) {
        socialData.push({ logo: <FaGithub />, url: contact.socials.github });
    }
    if (contact?.socials?.instagram) {
        socialData.push({
            logo: <FaInstagramSquare />,
            url: contact.socials.instagram
        });
    }
    if (contact?.socials?.linkedin) {
        socialData.push({
            logo: <FaLinkedin />,
            url: contact.socials.linkedin
        });
    }
    if (contact?.socials?.twitter) {
        socialData.push({
            logo: <BsTwitterX />,
            url: contact.socials.twitter
        });
    }
    if (contact?.socials?.website) {
        socialData.push({ logo: <FaGlobe />, url: contact.socials.website });
    }
    if (contact?.socials?.youtube) {
        socialData.push({
            logo: <FaYoutube />,
            url: contact.socials.youtube
        });
    }
    return (
        <>
            {socialData.length > 0 && (
                <div className={`flex flex-row  gap-4 py-3`}>
                    <div className="flex min-w-[4rem] items-center gap-x-2 md:min-w-[6rem]">
                        <h1 className="font-semibold">{title}</h1>
                    </div>

                    <div className={`grid grid-cols-5  gap-2`}>
                        {socialData.map((item, index) => (
                            <a
                                key={index}
                                href={item.url}
                                target={item.logo === <FaPhoneAlt /> || item.logo === <MdEmail /> ? "" : "_blank"}
                                className={`flex w-fit items-center gap-x-2 overflow-hidden rounded-full  border border-primary bg-primary/10 `}
                                rel="noreferrer"
                            >
                                <span className="bg-primary px-[.45rem] py-[.35rem] text-white dark:bg-primary/10 dark:text-primary ">
                                    {item.logo}
                                </span>
                            </a>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ContactGroup;

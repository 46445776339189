import React, { useContext, useRef } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { updateData } from "../../../hooks/update";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { IoCloseOutline } from "react-icons/io5";

const UpdateSupportModal = ({ refetch, item }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};

    const { issueSubject, issueMessage } = item.attributes;
    const formRef = useRef<HTMLFormElement | null>(null);

    const closeModal = () => {
        const label = document.querySelector<HTMLLabelElement>(`[for="${item.id + "update"}"]`);
        if (label) {
            label.click();
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const form = formRef.current;
        if (form) {
            const data = {
                issueSubject: form.issueSubject.value,
                issueMessage: form.issueMessage.value
            };
            updateData({
                Parse: Parse,
                parseItem: item,
                data: data,
                message: "Ticket Updated!",
                refetch: refetch,
                form: form,
                closeModal: closeModal
            });
        }
    };
    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.id + "update"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl rounded dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>
                    <div>
                        <h1 className="font-medium text-xl pb-2">Update Ticket.</h1>
                        <p>Please fill up the form and hit submit.</p>
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="py-6 grid grid-cols-1  gap-4 ">
                            <CustomInputField
                                name="issueSubject"
                                title="Subject"
                                type="text"
                                value={issueSubject}
                                max={100}
                                required
                            />
                            <CustomInputField
                                name="issueMessage"
                                title="Message"
                                type="text"
                                value={issueMessage}
                                required
                                textArea
                            />
                        </div>

                        <div className="flex justify-end items-center gap-4">
                            <button
                                type="submit"
                                className="btn bg-success text-white  font-semibold border-0 hover:bg-primary"
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UpdateSupportModal;

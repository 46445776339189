import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa6";
import { NavLink } from "react-router-dom";
const SideDropDown = ({ menu, open }: any) => {
    const location = useLocation();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const { pathname } = location;
    const activeCheck = menu.subMenu.filter((item: any) => pathname.includes(item.path)).length > 0 || false;
    const [dropDown, setOpen] = useState(false);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setOpen(!dropDown)}
                className={`flex  md:gap-0 md:justify-between items-center text-sm  w-fit ${
                    activeCheck && "text-primary font-semibold duration-300"
                } ${!open ? "-ml-1" : "gap-10"} w-full `}
            >
                <div className={`flex  gap-x-3.5 ${!open ? "flex-col" : "flex-row "}`}>
                    <div
                        className={`${
                            !open ? "ml-[-3px] xl:ml-[0.3rem]  p-2" : ""
                        } hover:bg-secondary/10 rounded-md flex items-center gap-x-1  ${
                            !open && activeCheck && "bg-primary/10"
                        }`}
                    >
                        {menu?.icon && React.createElement(menu.icon, { size: 20 })}
                        {!open && (
                            <div className={`${open && dropDown ? "rotate-90" : ""} duration-300  md:hidden `}>
                                <FaChevronRight />
                            </div>
                        )}
                    </div>
                    {open && (
                        <h2 className={`whitespace-pre duration-100  ${!open ? "opacity-0 overflow-hidden " : ""}`}>
                            {menu?.name}
                        </h2>
                    )}

                    {!open && (
                        <h2 className={`whitespace-pre duration-100 text-[.65rem] -ml-3 md:-ml-0`}>{menu?.name}</h2>
                    )}
                </div>

                <div className={`${open && dropDown ? "rotate-90" : ""} duration-300 hidden md:block`}>
                    <FaChevronRight size={10} />
                </div>
            </button>
            <div
                className={`${
                    open && dropDown ? "pt-6 pl-8 flex flex-col gap-y-6" : "h-0 overflow-hidden -z-10"
                } duration-300`}
            >
                {menu.subMenu.map((item: any, index: number) => (
                    <NavLink key={index} to={item.path} className="flex items-center gap-2 text-sm">
                        <h2>{React.createElement(item.icon, { size: 20 })}</h2>
                        <h2>{item.name}</h2>
                    </NavLink>
                ))}
            </div>
            {!open && dropDown && (
                <div className=" bg-white dark:bg-darkSecondary absolute top-0 -right-44 py-6 rounded-lg px-2 flex flex-col gap-y-5 shadow">
                    {menu.subMenu.map((item: any, index: number) => (
                        <NavLink key={index} to={item.path} className="flex items-center text-sm w-full gap-x-2">
                            <h2>{React.createElement(item.icon, { size: 20 })}</h2>
                            <h2>{item.name}</h2>
                        </NavLink>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SideDropDown;

import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { dateFormatter } from "../../utils/dateFormat";

const PDFFooter = ({ data }: any) => {
    return (
        <>
            <Text
                style={{
                    position: "absolute",
                    fontSize: 12,
                    bottom: 10,
                    left: 0,
                    right: 0,
                    textAlign: "center",
                    color: "grey"
                }}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <Text
                style={{
                    position: "absolute",
                    fontSize: 9,
                    bottom: 10,
                    left: 45,
                    right: 0,
                    textAlign: "left",
                    color: "grey"
                }}
                fixed
            >
                Initiated On {dateFormatter(data.createdAt)}
            </Text>
            <Text
                style={{
                    position: "absolute",
                    fontSize: 9,
                    bottom: 10,
                    left: 0,
                    right: 45,
                    textAlign: "right",
                    color: "grey"
                }}
                fixed
            >
                Generated On {dateFormatter(data.updatedAt)}
            </Text>
        </>
    );
};

export default PDFFooter;

import React from "react";

const TestimonialCard = ({ data }: any) => {
    const { content, image, name, designation } = data;
    return (
        <div className="bg-white w-[25rem] xl:w-[30rem] p-4 xl:p-8 border-1 shadow-md mx-auto rounded mb-2  dark:bg-darkBG dark:text-white">
            <p className="border-b border-dark/20 dark:border-white/20 pb-4 text-base xl:text-lg min-h-[6rem] xl:min-h-[8rem]">
                “{content}”
            </p>
            <div className="py-4 flex gap-x-4">
                <img
                    src={new URL(image, process.env.REACT_APP_PUBLIC_WEBSITE).href}
                    alt=""
                    className="w-12 h-12 object-cover rounded-full"
                />
                <div className="flex flex-col">
                    <span className="text-primary font-semibold text-lg">{name}</span>
                    <span className="text-base">{designation}</span>
                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;

import React from "react";

const TableHead = ({ columnItems }: { columnItems: string[] }) => {
    return (
        <thead className="dark:text-white text-secondary text-sm bg-[#F4F6F8] h-16 dark:bg-darkSecondary">
            <tr className="border-0 dark:bg-secondary ">
                <th> </th>
                {columnItems.map((item, index) => (
                    <th key={index}>{item}</th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHead;

import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Pagination = ({ itemCount, setItemCount, page, setPage, totalItemCount, jobs }: any) => {
    const maxPageCount = Math.ceil(totalItemCount / itemCount);
    const dorpDownItems = [5, 10, 15, 20, 25];
    const jobsDownItems = [12, 20, 28, 36, 40];
    return (
        <div className="flex gap-x-4 justify-end py-4 lg:pr-2   min-w-[10rem] bg-white dark:bg-darkSecondary ">
            <div className="flex items-center gap-x-1 md:gap-x-2 ">
                <h1 className="text-sm ">Rows per Page</h1>
                <div className="dropdown dropdown-top">
                    <div
                        tabIndex={0}
                        role="button"
                        className=" bg-gray-100 dark:bg-darkBG rounded-lg py-2 px-4 md:px-7 "
                    >
                        {itemCount}
                    </div>
                    <ul
                        tabIndex={0}
                        className="dropdown-content z-[1] menu  shadow bg-base-100 dark:bg-darkBG w-[68px] p-0 text-center rounded-lg mb-2"
                    >
                        {jobs
                            ? jobsDownItems.map((item, index) => (
                                  <li
                                      onClick={() => setItemCount(item)}
                                      className={` text-semibold p-2 cursor-pointer hover:bg-gray-100 duration-300 hover:dark:bg-dark`}
                                      key={index}
                                  >
                                      {item}
                                  </li>
                              ))
                            : dorpDownItems.map((item, index) => (
                                  <li
                                      onClick={() => setItemCount(item)}
                                      className={` text-semibold p-2 cursor-pointer hover:bg-gray-100 duration-300 hover:dark:bg-dark`}
                                      key={index}
                                  >
                                      {item}
                                  </li>
                              ))}
                    </ul>
                </div>
            </div>

            <div className="flex items-center lg:gap-x-4">
                <button
                    onClick={() => page > 1 && setPage(page - 1)}
                    className={`text-2xl flex justify-center items-center hover:bg-primary/10 disabled:hover:bg-transparent px-2 p-2 rounded-lg ${
                        page === 1 && "cursor-not-allowed"
                    }`}
                    disabled={page === 1}
                >
                    <BiChevronLeft />
                </button>
                <div className="bg-primary/10 rounded-lg p-2 px-4 font-bold">
                    {page} of {maxPageCount > 0 ? maxPageCount : 1}
                </div>
                <button
                    onClick={() => setPage(page + 1)}
                    className={`text-2xl flex justify-center items-center hover:bg-primary/10 disabled:hover:bg-transparent px-2 p-2 rounded-lg ${
                        page === maxPageCount && "cursor-not-allowed"
                    }`}
                    disabled={page === maxPageCount}
                >
                    <BiChevronRight />
                </button>
            </div>
        </div>
    );
};

export default Pagination;

import React from "react";
import { PiSmileyFill, PiSmileySadFill, PiSmileyMehFill } from "react-icons/pi";

interface SentimentAnalysis {
    positive: number;
    negative: number;
    neutral: number;
}

interface SentimentAnalysisChartProps {
    sentimentAnalysis: SentimentAnalysis;
}

const SentimentAnalysisChart: React.FC<SentimentAnalysisChartProps> = ({ sentimentAnalysis }) => {
    return (
        <div className="w-full">
            <div className="flex gap-x-2  items-end">
                <div className="flex flex-col items-center">
                    <div className="w-8 h-20  flex flex-col items-end justify-end ">
                        <span className="text-[10px]  ">{sentimentAnalysis.positive.toFixed(1)}%</span>
                        <div
                            style={{ height: `${sentimentAnalysis.positive.toFixed(1)}%` }}
                            className="bg-[#00A76F] -0 w-8 rounded-md"
                        />
                    </div>
                    <span className="text-2xl text-green-400 pt-1">
                        <PiSmileyFill />
                    </span>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-8 h-20  flex flex-col items-end justify-end ">
                        <span className="text-[10px]">{sentimentAnalysis.neutral.toFixed(1)}%</span>
                        <div
                            style={{ height: `${sentimentAnalysis.neutral.toFixed(1)}%` }}
                            className="bg-[#FABD23] -0 w-8 rounded-md"
                        />
                    </div>
                    <span className="text-2xl text-[#FABD23]/95 pt-1">
                        <PiSmileyMehFill />
                    </span>
                </div>
                <div className="flex flex-col items-center">
                    <div className="w-8 h-20  flex flex-col items-end justify-end ">
                        <span className="text-[10px]">{sentimentAnalysis.negative.toFixed(1)}%</span>
                        <div
                            style={{ height: `${sentimentAnalysis.negative.toFixed(1)}%` }}
                            className="bg-[#D32F2F] -0 w-8 rounded-md"
                        />
                    </div>
                    <span className="text-2xl text-red-400 pt-1">
                        <PiSmileySadFill />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SentimentAnalysisChart;

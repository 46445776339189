import React, { createContext, useContext, useState } from "react";
import useSession from "../hooks/useSession";
import { ChildrenProps, DataContextValue } from "../type/Types";
import Loading from "../components/Common/Loading/Loading";
import { PARSE_CONTEXT } from "./Provider";
import useUserList from "../hooks/useUserList";

export const DATA_CONTEXT = createContext<DataContextValue | undefined>(undefined);

const DataProvider = ({ children }: ChildrenProps) => {
    const [user, setUser, loading, userRefetch] = useSession();
    const [userSearchString, setUserSearchString] = useState("*");
    const [adminMode, setAdminMode] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};

    const [usersList] = useUserList({
        Parse: Parse,
        userSearchString: userSearchString ? userSearchString : "*"
    });

    if (loading) {
        return <Loading full />;
    }

    const userDataManage = {
        user,
        setUser,
        userRefetch,
        userLoading: loading
    };
    const userListData = { usersList: (usersList as any)?.data, userSearchString, setUserSearchString };
    const adminModeData = { adminMode, setAdminMode };

    const value: DataContextValue = {
        userDataManage,
        userListData,
        adminModeData
    };

    return <DATA_CONTEXT.Provider value={value}>{children}</DATA_CONTEXT.Provider>;
};

export default DataProvider;

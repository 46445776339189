import React, { useState, useContext } from "react";
import ObjectiveComponents from "./ObjectiveComponents";
import SubjectiveComponents from "./SubjectiveComponents";
import VideoComponent from "./VideoComponent";
import { PARSE_CONTEXT } from "../../provider/Provider";
import VideoModal from "./VideoModal";
import { useNavigate } from "react-router-dom";
import { updateData } from "../../hooks/update";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";
import { convertToHttps } from "../../utils/convertUrl";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { toast } from "react-toastify";
import { isMacOs } from "react-device-detect";

const RefereeQuestionForm = ({ formRef, data, response }: any) => {
    const [loading, setLoading] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const [responseVideo, setResponseVideo] = useState([]);
    const { questionTemplate } = data.attributes;
    const { name, questionObjective, questionSubjective, questionVideo } = questionTemplate;
    const [videoQuestion] = useState(questionVideo);
    const handleVideoQuestion = async (answer: any) => {
        setResponseVideo(answer);
    };
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        const fingerPrintData = await getUserFingerPrint();

        const answerData: {
            name: string;
            questionObjective: any[];
            questionSubjective: any[];
            questionVideo: any[];
        } = {
            name: "",
            questionObjective: [],
            questionSubjective: [],
            questionVideo: [] // An empty array as an example
        };

        const questionObjectiveSingle: any[] = [];
        const questionObjectiveMultiple: any[] = [];
        const questionSubjectiveAnswer: any[] = [];

        if (form) {
            questionObjective.forEach((item: any) => {
                if (item.type === "multipleRegular" || item.type === "multipleBlock") {
                    const inputElement = form[item.objectId] as HTMLInputElement[] | undefined;
                    if (inputElement) {
                        let data: string[] = [];
                        inputElement.forEach((element) => {
                            if (element.value) {
                                data.push(element.value);
                            }
                        });
                        const temp = {
                            objectId: item.objectId,
                            options: item.options,
                            question: item.question,
                            type: item.type,
                            response: data
                        };
                        questionObjectiveMultiple.push(temp);
                    }
                } else {
                    const inputElement = form[item.objectId] as HTMLInputElement | undefined;
                    if (inputElement) {
                        const temp = {
                            objectId: item.objectId,
                            options: item.options,
                            question: item.question,
                            type: item.type,
                            response: inputElement.value
                        };
                        questionObjectiveSingle.push(temp);
                    }
                }
            });
            questionSubjective.forEach((item: any) => {
                const inputElement = form[item.objectId] as HTMLInputElement | undefined;
                if (inputElement) {
                    const temp = {
                        maxCharacterLimit: item.maxCharacterLimit,
                        minCharacterLimit: item.minCharacterLimit,
                        objectId: item.objectId,
                        question: item.question,
                        response: inputElement.value
                    };
                    questionSubjectiveAnswer.push(temp);
                }
            });
        }

        if (responseVideo) {
            const blob = new Blob(responseVideo, { type: "video/mp4" });
            const parseFile = new Parse.File("response.mp4", blob, "video/mp4");
            try {
                await parseFile.save();
                videoQuestion[0].response = convertToHttps(parseFile.url());
            } catch (error) {
                toast.error("Error uploading video:", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
        answerData.name = name;
        answerData.questionObjective.push(...questionObjectiveSingle, ...questionObjectiveMultiple);
        answerData.questionSubjective.push(...questionSubjectiveAnswer);
        answerData.questionVideo = videoQuestion;

        await updateData({
            Parse: Parse,
            parseItem: data,
            data: {
                refereeImage: userDataManage.user.attributes.userImage,
                response: answerData,
                status: "completed",
                fingerPrint: fingerPrintData
            },
            message: "Reference Submitted!",
            navigate: navigate("/referee"),
            loading: setLoading
        });
    };

    const handleAnswerUpdate = async (event: any) => {
        const form = formRef.current;

        const answerData: {
            name: string;
            questionObjective: any[];
            questionSubjective: any[];
            questionVideo: any[];
        } = {
            name: "",
            questionObjective: [],
            questionSubjective: [],
            questionVideo: [] // An empty array as an example
        };

        const questionObjectiveSingle: any[] = [];
        const questionObjectiveMultiple: any[] = [];
        const questionSubjectiveAnswer: any[] = [];

        if (form) {
            await questionObjective.forEach((item: any) => {
                if (item.type === "multipleRegular" || item.type === "multipleBlock") {
                    const inputElement = form[item.objectId] as HTMLInputElement[] | undefined;
                    if (inputElement) {
                        let data: string[] = [];
                        inputElement.forEach((element) => {
                            if (element.value) {
                                data.push(element.value);
                            }
                        });
                        const temp = {
                            objectId: item.objectId,
                            options: item.options,
                            question: item.question,
                            type: item.type,
                            response: data
                        };
                        questionObjectiveMultiple.push(temp);
                    }
                } else {
                    const inputElement = form[item.objectId] as HTMLInputElement | undefined;
                    if (inputElement) {
                        const temp = {
                            objectId: item.objectId,
                            options: item.options,
                            question: item.question,
                            type: item.type,
                            response: inputElement.value
                        };
                        questionObjectiveSingle.push(temp);
                    }
                }
            });
            questionSubjective.forEach((item: any) => {
                const inputElement = form[item.objectId] as HTMLInputElement | undefined;
                if (inputElement) {
                    const temp = {
                        maxCharacterLimit: item.maxCharacterLimit,
                        minCharacterLimit: item.minCharacterLimit,
                        objectId: item.objectId,
                        question: item.question,
                        response: inputElement.value
                    };
                    questionSubjectiveAnswer.push(temp);
                }
            });
        }

        if (responseVideo) {
            const blob = new Blob(responseVideo, { type: isMacOs ? "video/mp4" : "video/webm" });
            const parseFile = new Parse.File(
                blob.type === "video/webm" ? "response.webm" : "response.mp4",
                blob,
                blob.type === "video/webm" ? "video/x-matroska" : "video/mp4"
            );
            try {
                await parseFile.save();
                videoQuestion[0].response = convertToHttps(parseFile.url());
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }

        answerData.name = name;
        answerData.questionObjective.push(...questionObjectiveSingle, ...questionObjectiveMultiple);
        answerData.questionSubjective.push(...questionSubjectiveAnswer);
        answerData.questionVideo = videoQuestion;

        await updateData({
            Parse: Parse,
            parseItem: data,
            data: {
                refereeImage: userDataManage.user.attributes.userImage,
                response: answerData
            },
            message: "Auto Saved!",
            autoSave: true
        });
    };

    return (
        <div>
            <form ref={formRef} onSubmit={handleSubmit}>
                <ObjectiveComponents
                    data={questionObjective}
                    handleAnswerUpdate={handleAnswerUpdate}
                    questionObjective={response?.questionObjective}
                />
                <SubjectiveComponents
                    data={questionSubjective}
                    handleAnswerUpdate={handleAnswerUpdate}
                    questionSubjective={response?.questionSubjective}
                />
                <VideoComponent
                    responseVideo={responseVideo}
                    data={questionVideo}
                    handleAnswerUpdate={handleAnswerUpdate}
                    questionVideo={response?.questionVideo}
                />

                <div className="flex justify-end items-center gap-4 pt-4">
                    <button
                        disabled={loading}
                        type="submit"
                        className={` btn bg-primary text-white hover:bg-success border-0 disabled:bg-primary/20 disabled:text-white/50`}
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        Submit
                    </button>
                </div>
            </form>
            <VideoModal
                responseVideo={responseVideo}
                setResponseVideo={setResponseVideo}
                handleVideoQuestion={handleVideoQuestion}
            />
        </div>
    );
};

export default RefereeQuestionForm;

import React, { useState, useContext } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import RefereeInfoBox from "./RefereeInfoBox";
import { useNavigate } from "react-router-dom";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { updateData } from "../../hooks/update";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";

const ReferenceRequestForm = ({ formRef, data }: any) => {
    const [loading, setLoading] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const navigate = useNavigate();
    const { minRefereeCount } = (data as any).attributes;
    const [reference, setReference] = useState(minRefereeCount);

    const removeBox = () => {
        setReference(reference - 1);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        const fingerPrintData = await getUserFingerPrint();
        const formData = [];
        if (form) {
            for (let index = 0; index < reference; index++) {
                formData.push({
                    refereeFirstname: form["refereeFirstname" + index].value,
                    refereeLastname: form["refereeLastname" + index].value,
                    refereeEmail: form["refereeEmail" + index].value,
                    refereeRelationship: form["refereeRelationship" + index].value,
                    refereeWorkplace: form["refereeWorkplace" + index].value,
                    refereePhoneNumber: form["refereePhoneNumber" + index].value,
                    refereeLinkedIn: form["refereeLinkedIn" + index].value,
                    refereeStartPeriod: form["refereeStartPeriod" + index].value,
                    refereeEndPeriod: form["refereeEndPeriod" + index].value
                });
            }
        }
        updateData({
            Parse: Parse,
            parseItem: data,
            data: {
                candidateImage: userDataManage.user.attributes.userImage,
                fingerPrint: fingerPrintData,
                refereeInformation: formData,
                status: "started"
            },
            message: "Reference Asked",
            navigate: navigate("/candidate"),
            loading: setLoading
        });
    };
    return (
        <div>
            <form ref={formRef} onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-4">
                    {[...Array(reference)].map((elementInArray, index) => {
                        return (
                            <RefereeInfoBox
                                item={index}
                                deleteButton={3 < reference}
                                removeBox={removeBox}
                                key={index}
                            />
                        );
                    })}
                </div>

                {reference < 5 && (
                    <button
                        type="button"
                        onClick={() => {
                            if (reference < 5) {
                                setReference(reference + 1);
                            }
                        }}
                        className={` border border-primary p-5 flex flex-col justify-center items-center text-primary text-lg w-full rounded-lg mt-6`}
                    >
                        <AiOutlinePlus />
                        <h1>Add New Referee</h1>
                    </button>
                )}

                <div className="flex justify-end items-center gap-4 pt-4">
                    <button
                        type="submit"
                        className="btn bg-primary text-white hover:bg-success border-0 flex justify-center items-center gap-x-2"
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ReferenceRequestForm;

export const statusColorCheck = (status: string): string => {
    if (status === "all") {
        return "bg-dark dark:bg-white !text-white dark:!text-dark";
    } else if (status === "started") {
        return "bg-yellow-500";
    } else if (status === "candidate") {
        return "bg-pink-500";
    } else if (status === "referee") {
        return "bg-purple-500";
    } else if (status === "analyzing") {
        return "bg-orange-500";
    } else if (status === "completed") {
        return "bg-green-500";
    } else if (status === "in-progress") {
        return "bg-orange-500";
    } else if (status === "pending") {
        return "bg-sky-500";
    } else {
        return "bg-red-500";
    }
};

export const statusColor = (status: string): string => {
    if (status === "started") {
        return "#eab308";
    } else if (status === "candidate") {
        return "#ec4899";
    } else if (status === "referee") {
        return "#a855f7";
    } else if (status === "analyzing") {
        return "#f97316";
    } else if (status === "completed") {
        return "#22c55e";
    } else if (status === "in-progress") {
        return "#f97316";
    } else if (status === "pending") {
        return "#0ea5e9";
    } else {
        return "#ef4444";
    }
};

import React, { useContext } from "react";
import ReactApexChart from "react-apexcharts";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";
import { statusColor } from "../../utils/statusColor";

const PieChart = ({ data }: any) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    data.labels.map((str: string) => str.charAt(0).toUpperCase() + str.slice(1));
    const colorData = data.labels.map((item: string) => statusColor(item));

    const options = {
        labels: data.labels.map((str: string) => str.charAt(0).toUpperCase() + str.slice(1)),

        responsive: [
            {
                breakpoint: 3000,
                options: {
                    legend: {
                        fontSize: "16px",
                        fontWeight: "600",
                        position: "bottom",
                        labels: {
                            colors: darkMode ? "white" : "black"
                        }
                    },
                    stroke: {
                        colors: darkMode ? "#1A1F25" : "white",
                        width: 5
                    },
                    plotOptions: {
                        pie: {
                            customScale: 1,
                            donut: {
                                size: "87%"
                            },
                            offsetY: 20
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    title: {
                        text: data.title,
                        style: {
                            fontSize: "24px",
                            fontWeight: "700",
                            fontFamily: "Public Sans",
                            color: darkMode ? "white" : "black"
                        }
                    }
                }
            },
            {
                breakpoint: 1000,
                options: {
                    legend: {
                        fontSize: "10px",
                        offsetY: 0
                    },
                    title: {
                        style: {
                            fontSize: "14px"
                        }
                    }
                }
            }
        ],
        colors: colorData
    };

    return (
        <div className="h-full lg:w-[82%] 2xl:w-[78.5%]">
            <ReactApexChart options={options} series={data.series} type="donut" />
        </div>
    );
};

export default PieChart;

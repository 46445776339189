import React from "react";
import { BsCircleFill } from "react-icons/bs";

import ActionMenu from "./ActionMenu";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";

const TableBody = ({
    data,
    refetch,
    filterRefetch,
    lastItem,
    action,
    button,
    dataClass
}: {
    data: any;
    refetch: () => void;
    filterRefetch?: () => void;
    lastItem: boolean;
    action?: boolean;
    button?: { title: string };
    dataClass: string;
}) => {
    const TableData = Object.keys(data).filter(
        (key) =>
            key !== "id" && key !== "action" && key !== "removeUpdate" && key !== "removeDelete" && key != "removeView"
    );

    const ModalButton = ({ button }: { button: { title: string } }) => {
        return (
            <td>
                <div className="px-4 w-fit">
                    {data.action && (
                        <Link
                            className="bg-primary text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-primary w-[9.6rem]"
                            to={data?.id}
                        >
                            <FaPlus /> {button.title}
                        </Link>
                    )}
                </div>
            </td>
        );
    };

    return (
        <tr className={` dark:text-white even:!bg-white dark:even:!bg-darkSecondary dark:border-white/5`}>
            <td></td>
            {TableData.map((item, index) => (
                <td key={index} className="!w-fit">
                    {data[item]}
                </td>
            ))}
            {action && (
                <td>
                    <ActionMenu
                        dataClass={dataClass}
                        refetch={[refetch, filterRefetch]}
                        id={data?.id}
                        lastItem={lastItem}
                        removeUpdate={data.removeUpdate}
                        removeDelete={data.removeDelete}
                        removeView={data.removeView}
                    />
                </td>
            )}
            {button && <ModalButton button={button} />}
        </tr>
    );
};

export default TableBody;

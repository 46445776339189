import React, { useRef, useState, useCallback } from "react";
import { isSafari } from "react-device-detect";
import { BiVideo, BiVideoOff } from "react-icons/bi";
import { BsFillRecordCircleFill } from "react-icons/bs";
import { FaStop } from "react-icons/fa";
import { MdDoneOutline, MdRefresh } from "react-icons/md";
import Webcam from "react-webcam";
import RecordingTimer from "./RecordingTimer";

const CameraRecorder = ({
    cameraOpen,
    setCameraOpen,
    handleVideoQuestion,
    responseVideo,
    setResponseVideo,
    setApproved
}: any) => {
    const webcamRef = useRef<Webcam | null>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [capturing, setCapturing] = useState<boolean>(responseVideo.length > 0 ? true : false);

    const [elapsedTime, setElapsedTime] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "user"
    };

    const handleDataAvailable = useCallback(({ data }: { data: any }) => {
        if (data.size > 0) {
            setResponseVideo((prev: any) => [...prev, data as Blob]);
        }
    }, []);
    const handleStartCaptureClick = useCallback(() => {
        setIsTimerRunning(true);
        setCapturing(true);
        if (webcamRef.current) {
            const stream = webcamRef.current.video?.srcObject as MediaStream;
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: isSafari ? "video/mp4" : "video/webm"
            });
            mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
            mediaRecorderRef.current.start();
        }
    }, []);

    const handleStopCaptureClick = useCallback(() => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        setIsTimerRunning(false);
        setCapturing(false);
    }, []);

    const closeModal = () => {
        const label = document.getElementById("videoModalClose");
        if (label) {
            label.click();
        }
    };

    return (
        <>
            <div className="flex justify-center py-2 text-xl fon-semibold">
                {capturing && (
                    <span className="flex justify-center gap-x-3">
                        Recording :
                        <RecordingTimer
                            elapsedTime={elapsedTime}
                            setElapsedTime={setElapsedTime}
                            isTimerRunning={isTimerRunning}
                        />
                    </span>
                )}
                {responseVideo.length > 0 && (
                    <span className="flex justify-center gap-x-3">
                        Duration :
                        <RecordingTimer
                            elapsedTime={elapsedTime}
                            setElapsedTime={setElapsedTime}
                            isTimerRunning={isTimerRunning}
                        />
                    </span>
                )}
            </div>
            <div className="h-[25rem] 2xl:h-[40rem] bg-black overflow-hidden rounded-xl">
                {responseVideo.length === 0 && !cameraOpen && (
                    <h2 className="text-white w-full h-full flex justify-center items-center lg:text-xl font-semibold">
                        Camera Off!
                    </h2>
                )}
                {responseVideo.length === 0 && cameraOpen && (
                    <Webcam
                        audio={true}
                        muted={true}
                        ref={webcamRef}
                        videoConstraints={videoConstraints}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                )}
                {responseVideo.length > 0 && (
                    <video className="w-full h-full" controls>
                        <source src={URL.createObjectURL(responseVideo[0])} type="video/mp4" />
                        <source src={URL.createObjectURL(responseVideo[0])} type="audio/mpeg" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>

            <div className="flex justify-center items-center pt-4 gap-x-4">
                {responseVideo.length === 0 && (
                    <>
                        {!capturing && (
                            <>
                                {!cameraOpen ? (
                                    <button
                                        onClick={() => setCameraOpen(true)}
                                        className={`text-3xl text-primary bg-gray-100 rounded-full p-2`}
                                    >
                                        <BiVideo />
                                    </button>
                                ) : (
                                    <button
                                        onClick={() => setCameraOpen(false)}
                                        className="text-3xl text-error bg-gray-100 rounded-full p-2"
                                    >
                                        <BiVideoOff />
                                    </button>
                                )}
                            </>
                        )}

                        {cameraOpen && (
                            <>
                                {/* Start Recording */}
                                {!capturing ? (
                                    <button
                                        onClick={handleStartCaptureClick}
                                        className={`text-3xl text-error bg-gray-100 rounded-full p-2`}
                                    >
                                        <BsFillRecordCircleFill />
                                    </button>
                                ) : (
                                    // Stop Recording
                                    <button
                                        onClick={handleStopCaptureClick}
                                        className="text-2xl text-error bg-gray-100 rounded-full p-3"
                                    >
                                        <FaStop />
                                    </button>
                                )}
                            </>
                        )}
                    </>
                )}

                {responseVideo.length > 0 && (
                    <button
                        onClick={() => {
                            handleVideoQuestion(responseVideo);
                            setElapsedTime(0);
                            setCameraOpen(false);
                            setApproved(true);
                            closeModal();
                        }}
                        className={` text-2xl text-success bg-gray-100 rounded-full p-3 `}
                    >
                        <MdDoneOutline />
                    </button>
                )}
                {responseVideo.length > 0 && (
                    <button
                        onClick={() => {
                            setResponseVideo([]);
                            setApproved(false);
                            setElapsedTime(0);
                        }}
                        className={` text-3xl text-blue-500 bg-gray-100 rounded-full p-2 `}
                    >
                        <MdRefresh />
                    </button>
                )}
            </div>
        </>
    );
};
export default CameraRecorder;

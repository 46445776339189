import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import { Link, useNavigate } from "react-router-dom";

import { signUp } from "../../hooks/signUp";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { PARSE_CONTEXT } from "../../provider/Provider";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import CountryDropdown from "../Common/CountryDropdown/CountryDropdown";
import { ToastContainer, toast } from "react-toastify";
import { getUserFingerPrint } from "../../utils/getUserFingerPrint";

const Register = ({ login, setLogin }: any) => {
    const [loading, setLoading] = useState(false);
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement | null>(null);

    interface FormData {
        // Define your form data fields here
        firstname: string;
        lastname: string;
        username: string;
        email: string;
        phoneNumber: string | undefined;
        password: string;
        confirmPassword: string;
        createdAtFingerPrint: any;
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const fingerPrintData = await getUserFingerPrint();

        if (formRef.current) {
            if (formRef.current.password.value === formRef.current.confirmPassword.value) {
                const data: FormData = {
                    firstname: formRef.current.firstname.value,
                    lastname: formRef.current.lastname.value,
                    username: formRef.current.email.value,
                    phoneNumber: formRef.current.phoneNumber.value,
                    password: formRef.current.password.value,
                    confirmPassword: formRef.current.confirmPassword.value,
                    email: formRef.current.email.value,
                    createdAtFingerPrint: fingerPrintData
                };
                signUp({
                    Parse: Parse,
                    data: data,
                    reFetch: userDataManage.userRefetch,
                    navigate: navigate,
                    loading: setLoading
                });
            } else {
                toast.error("Password and confirm password didn't matched!");
            }
        }
    };
    return (
        <div className="relative">
            <HelmetConfiguration seoData={SeoContent["registerSeo"] || SeoContent.baseSeo} />
            <div className="w-[20rem] sm:w-[22rem] 2xl:w-[25rem] mx-auto flex flex-col justify-center gap-y-4 ">
                <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-y-3 py-6">
                    <div className="flex gap-x-3">
                        <CustomInputField name="firstname" title="First Name" type="text" required />
                        <CustomInputField name="lastname" title="Last Name" type="text" required />
                    </div>

                    <CustomInputField name="email" title="Email Address" type="email" required />
                    <CountryDropdown name="phoneNumber" required />
                    <CustomInputField name="password" title="Password" type="password" required min={8} />
                    <CustomInputField
                        name="confirmPassword"
                        title="Confirm Password"
                        type="password"
                        required
                        min={8}
                    />
                    <p className="font-medium text-black dark:text-white  w-full text-sm text-center p-3 rounded bg-primary/5">
                        by joining us you agree to our{" "}
                        <a target="__blank" href="https://www.hrhouz.com/terms" className="text-primary">
                            terms
                        </a>{" "}
                        and{" "}
                        <a target="__blank" href="https://www.hrhouz.com/privacy" className="text-primary">
                            policy
                        </a>{" "}
                    </p>

                    <button
                        type="submit"
                        className="bg-primary/90 text-white uppercase font-medium py-4 hover:bg-primary  duration-300 rounded flex justify-center items-center gap-x-2"
                    >
                        {loading && <span className="loading loading-spinner"></span>}
                        Register
                    </button>

                    <p className="font-medium text-black dark:text-white text-start w-full">
                        already have an account?{" "}
                        <button onClick={() => setLogin(!login)} type="button" className="text-primary font-semibold">
                            Login
                        </button>
                    </p>
                </form>
            </div>
        </div>
    );
};

export default Register;

import React, { useState, useContext } from "react";
import { PARSE_CONTEXT } from "../../provider/Provider";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import Table from "../../components/Common/Table/Table";
import { TableData } from "../../type/Table";
import { FaPlus } from "react-icons/fa";
import RetryModal from "../../components/Common/RetryModal/RetryModal";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { ProfileComponent } from "../../components/Common/Table/UtilityComponent";
import { pageData } from "../../content/constantVariable";
import AddOrganization from "../../components/Organization/Modal/AddOrganization";
import { OrganizationType } from "../../type/OrganizationType";
import UpdateOrganization from "../../components/Organization/Modal/UpdateOrganization";
import OrganizationView from "../../components/Organization/Modal/OrganizationView";
import { AiOutlineMail } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
import { TfiMapAlt } from "react-icons/tfi";

interface OrganizationDataType {
    totalCount: number;
    result: OrganizationType[];
}

const OrganizationPage = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const [totalData, setTotalData] = useState(pageData.dataPerPage);
    const [page, setPage] = useState(pageData.initialPage);
    const [data, loading, refetch, error] = useFetch({
        Parse: Parse,
        dataClass: "Organization",
        total: { perPage: totalData, page: page },
        alert: true
    });

    if (loading) {
        return <Loading />;
    }

    const tableBody = (data as OrganizationDataType).result.map((item) => {
        return {
            id: item.id,
            candidate: (
                <ProfileComponent
                    id={item.id}
                    image={item.attributes.organizationImage}
                    name={item.attributes.organizationName}
                    emailDomain={item.attributes.organizationEmailDomain}
                    square
                />
            ),
            location: (
                <div className="flex  gap-x-1 dataHover ml-4 text-sm">
                    {item.attributes.organizationMapLocation ? (
                        <a
                            target="__blank"
                            href={item.attributes.organizationMapLocation}
                            className=" flex items-center gap-x-1 hover:!text-blue-500 duration-200 break-all leading-4 "
                        >
                            <span className="h-full mt-1 text-2xl ">
                                <TfiMapAlt />
                            </span>
                        </a>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
            website: (
                <div className="flex  gap-x-1 dataHover min-w-[15em] ">
                    {item.attributes.organizationWebsite ? (
                        <a
                            target="__blank"
                            href={item.attributes.organizationWebsite}
                            className="flex items-center gap-x-1 hover:!text-blue-500 duration-200 break-all leading-4 text-sm"
                        >
                            <span className="h-full mt-1 text-lg">
                                <GoBrowser />
                            </span>
                            <span>{item.attributes.organizationWebsite}</span>
                        </a>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
            companyAdmin: (
                <div className="flex  gap-x-1 dataHover min-w-[15em] ">
                    {item.attributes.organizationAdminEmail ? (
                        <a
                            href={`mailto: ${item.attributes.organizationAdminEmail}`}
                            className="flex items-center gap-x-1 hover:!text-blue-500 duration-200 break-all leading-4 text-sm"
                        >
                            <span className="h-full mt-1 text-lg">
                                <AiOutlineMail />
                            </span>
                            <span>{item.attributes.organizationAdminEmail}</span>
                        </a>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
            removeUpdate: true,
            removeDelete: true
        };
    });

    const tableData = {
        columnItems: ["Organization", "Location", "Website", "Admin", ""],
        tableBody: tableBody,
        pagination: {
            total: totalData,
            page: page,
            setTotal: setTotalData,
            setPage: setPage,
            totalItemCount: (data as OrganizationDataType).totalCount
        },
        refetch: refetch,
        dataClass: "Organization"
    };

    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["organizationSeo"] || SeoContent.baseSeo} />
            <section className="py-10 ">
                <div className="flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="addOrganization"
                    >
                        <FaPlus /> Add Organization
                    </label>
                </div>

                <Table action tableData={tableData as TableData} />

                <div>
                    {(data as OrganizationDataType).result.map((item: { id: string; attributes: any }, index: any) => (
                        <div key={index}>
                            <OrganizationView item={item} />
                            <UpdateOrganization refetch={refetch} item={item} />
                        </div>
                    ))}
                </div>
                <AddOrganization refetch={refetch} />
                {error && <RetryModal refetch={refetch} />}
            </section>
        </TableLayer>
    );
};

export default OrganizationPage;

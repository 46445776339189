import React, { useState, useEffect } from "react";

import { arrowSvg } from "../../../design/svgIcons";

const CustomSlider = ({ blogData }: { blogData: { id: number; title: string; url: string; image: string }[] }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const handlePrevClick = () => {
        setCurrentImage((prevImage) => (prevImage === 0 ? blogData.length - 1 : prevImage - 1));
    };

    const handleNextClick = () => {
        setCurrentImage((prevImage) => (prevImage === blogData.length - 1 ? 0 : prevImage + 1));
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    useEffect(() => {
        if (!isHovered) {
            const interval = setInterval(handleNextClick, 5000);
            return () => clearInterval(interval);
        }
    }, [isHovered]);

    return (
        <div
            className="w-full rounded-lg relative overflow-hidden"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div
                className="w-full h-full flex duration-300 "
                style={{ transform: `translateX(-${currentImage * 100}%)` }}
            >
                {blogData.map((item, index) => (
                    <div key={index} className="w-full h-full flex-shrink-0 relative">
                        <div className="backDrop">
                            <img
                                src={new URL(item.image, process.env.REACT_APP_PUBLIC_WEBSITE).href}
                                className="w-full object-cover h-[15rem]  xl:h-[25rem]"
                                alt="Tailwind CSS Carousel component "
                            />
                        </div>

                        <div className="absolute h-full w-full top-0  flex items-end ">
                            <div className="w-full p-4 xl:p-10 shadow-2xl ">
                                <h1 className="text-primary sm:text-2xl font-semibold ">Recent News</h1>
                                <a
                                    href={new URL(item.url, process.env.REACT_APP_PUBLIC_WEBSITE).href}
                                    target="__blank"
                                    className="text-white text-lg sm:text-3xl  font-bold "
                                >
                                    {item.title}
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="absolute top-5 right-5 flex justify-end w-full gap-x-4">
                <button onClick={handlePrevClick}>{arrowSvg}</button>
                <button className="text-white scale-x-[-1]" onClick={handleNextClick}>
                    {arrowSvg}
                </button>
            </div>
        </div>
    );
};

export default CustomSlider;

import React, { useContext, useState, useMemo } from "react";
import Toggler from "../Toggler/Toggler";
import User from "./User";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";
import AdminUserSwitch from "./AdminUserSwitch";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import SchedulerSwitch from "./SchedulerSwitch";
import { OrganizationType } from "../../type/OrganizationType";
import useFetch from "../../hooks/useFetch";
import { PARSE_CONTEXT } from "../../provider/Provider";

interface OrganizationTypeData {
    totalCount: number;
    result: OrganizationType[];
}

const Header = ({ sideBarOpen }: any) => {
    const { darkMode, setDarkMode } = useContext(DARKMODE_CONTEXT) || {};
    const { userDataManage, userListData, adminModeData } = useContext(DATA_CONTEXT) || {};
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const [organizationName, setOrganizationName] = useState<string | null>(null);
    const [organizationImage, setOrganizationImage] = useState<string | null>(null);
    const [organizationWebsite, setOrganizationWebsite] = useState<string | null>(null);
    const isAdmin = useMemo(() => userDataManage?.user?.attributes?.isAdmin, [userDataManage]);
    const isEmployer = useMemo(() => userDataManage?.user?.attributes?.isEmployer, [userDataManage]);

    const viewUser = userListData && userListData.usersList[0];

    const [organizationData, organizationLoading] = useFetch({
        Parse: Parse,
        dataClass: "Organization",
        dataQuery: [
            {
                key: "organizationEmailDomain",
                item:
                    adminModeData?.adminMode && isAdmin
                        ? viewUser?.email.split("@")[1]
                        : userDataManage.user?.attributes?.email.split("@")[1]
            }
        ]
    });

    useMemo(() => {
        if (!organizationLoading && (organizationData as OrganizationTypeData)?.totalCount === 1) {
            setOrganizationName((organizationData as OrganizationTypeData).result[0]?.attributes.organizationName);
            setOrganizationImage((organizationData as OrganizationTypeData).result[0]?.attributes.organizationImage);
            setOrganizationWebsite(
                (organizationData as OrganizationTypeData).result[0]?.attributes.organizationWebsite
            );
        }
    }, [organizationLoading, organizationData]);

    const handleSwitch = () => {
        setDarkMode(!darkMode);
    };

    const layoutClass = `${
        sideBarOpen
            ? "pl-[1rem] sm:pl-[10rem] md:pl-[6.4rem] lg:pl-[8.4rem] xl:pl-[8rem] 2xl:pl-[9.5rem] duration-300"
            : "pl-[2.3rem] sm:pl-[3rem] lg:pl-[3.5rem] 2xl:pl-[4rem] duration-300"
    }`;

    return (
        <div
            className={`${layoutClass} py-3 px-4 xl:px-16 fixed top-0  shadowBottom bg-primaryBG dark:bg-darkBG z-[500] w-full`}
        >
            <div className="flex justify-between items-center gap-x-2 sm:gap-x-4 xl:gap-x-4">
                <div className={`${layoutClass}`}>
                    {organizationLoading && <span className="loading loading-spinner text-success loading-lg"></span>}
                    {organizationImage && isEmployer && (
                        <>
                            {organizationWebsite ? (
                                <a
                                    target="__blank"
                                    href={organizationWebsite}
                                    data-tip={organizationName}
                                    className="tooltip tooltip-success  tooltip-bottom"
                                >
                                    <img
                                        src={organizationImage}
                                        className="w-full h-8 md:h-10 object-contain"
                                        alt={organizationName ? organizationName : ""}
                                    />
                                </a>
                            ) : (
                                <div data-tip={organizationName} className="tooltip tooltip-success  tooltip-bottom">
                                    <img
                                        src={organizationImage}
                                        className="w-full h-10 object-contain"
                                        alt={organizationName ? organizationName : ""}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="flex gap-x-2 items-center">
                    {isAdmin && (
                        <input
                            type="checkbox"
                            className={`${
                                adminModeData?.adminMode
                                    ? "toggle dark:[--tglbg:#161C24] bg-success hover:bg-primary border-success"
                                    : "toggle dark:[--tglbg:#161C24] bg-error hover:bg-error border-error"
                            } toggle ]`}
                            checked={adminModeData?.adminMode}
                            onChange={() => adminModeData?.setAdminMode(!adminModeData.adminMode)}
                        />
                    )}
                    {adminModeData?.adminMode && isAdmin && <AdminUserSwitch />}
                    {adminModeData?.adminMode && isAdmin && <SchedulerSwitch />}
                    <Toggler darkMode={darkMode} handleSwitch={handleSwitch} />
                    <User />
                </div>
            </div>
        </div>
    );
};

export default Header;

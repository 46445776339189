import React, { useContext } from "react";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { urlCheck } from "../../../utils/urlCheck";

const Links = () => {
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const allSocialLinks = userDataManage?.user?.attributes?.social_links || [];

    return (
        <section className="shadow-lg flex flex-col  gap-y-4 justify-center items-center p-4 rounded-md bg-white dark:bg-darkSecondary py-10 col-span-2 lg:w-[55%] mx-auto">
            <div className="flex flex-col gap-y-3 w-full">
                {allSocialLinks.map((item: any, index: number) => (
                    <div key={index} className="flex items-center border rounded-lg  w-full overflow-hidden">
                        <div className="h-full px-4 text-dark dark:text-white  flex justify-center text-xl scale-[1.2]">
                            {urlCheck(item.url).icon}
                        </div>
                        <input
                            type="text"
                            defaultValue={item.url}
                            className="py-3 bg-white dark:bg-darkSecondary w-full outline-none"
                            disabled={true}
                        />
                    </div>
                ))}
            </div>

            <div className="flex justify-end w-full">
                <label
                    htmlFor="linkModal"
                    className="w-fit p-2 dark:bg-white dark:text-dark rounded-lg bg-dark px-4 text-white cursor-pointer"
                >
                    Update
                </label>
            </div>
        </section>
    );
};

export default Links;

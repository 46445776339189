import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo/logo.svg";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import OTPInput from "react-otp-input";
import CountDownComponent from "../Common/CountDownComponent/CountDownComponent";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { IoLogOut } from "react-icons/io5";
import { RequestEmailVerificationOTP } from "../../utils/RequestEmailVerificationOTP";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const EmailVerificationUI = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userDataManage } = useContext(DATA_CONTEXT) || {};
    const navigate = useNavigate();
    const [resend, setResend] = useState(false);
    const [otp, setOtp] = useState("");
    const handleLogout = () => {
        Parse.User.logOut().then(async () => {
            await userDataManage.setUser(null);
            navigate("/login");
        });
    };
    const handleResend = async () => {
        setResend(false);
        await RequestEmailVerificationOTP({ Parse: Parse, email: userDataManage.user.attributes.email });
    };
    const handleSubmit = async () => {
        try {
            const result = await Parse.Cloud.run("VerifyEmailOTP", {
                email: userDataManage.user.attributes.email,
                emailVerificationOTP: otp
            });

            if (result.result) {
                toast.success(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
                await userDataManage.userRefetch();
                navigate("/");
            } else {
                toast.error(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error: any) {
            toast.error(error, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    };

    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["emailVerification"] || SeoContent.baseSeo} />
            <div className="w-full min-h-[90.4vh] md:min-h-[94vh] lg:min-h-[93.1vh] flex items-center justify-center dark:text-white">
                <div className=" px-4 md:px-8 rounded-lg flex flex-col gap-y-4 justify-center items-center max-w-[35rem] text-center  py-16 md:py-24 mx-1">
                    <div className="flex justify-center items-center gap-x-1 w-full">
                        <img src={logo} alt="" width="45rem" />
                        <span className="text-3xl font-extrabold dark:text-white">HRHouz</span>
                    </div>
                    <div className="flex flex-col gap-y-3 items-center justify-center w-full">
                        <h1
                            className={`text-primary font-semibold text-xl flex justify-center border-primary pb-2 border-b-2`}
                        >
                            Verify Email
                        </h1>
                    </div>
                    <p className="font-semibold">
                        OTP sent to{" "}
                        <span className="text-primary font-bold">{userDataManage?.user?.attributes?.email}</span>
                    </p>
                    <CountDownComponent resend={resend} setResend={setResend} />
                    <div>
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            inputType="text"
                            renderSeparator={<span className="md:text-2xl px-1 ">-</span>}
                            renderInput={(props) => (
                                <input
                                    {...props}
                                    className="text-4xl xl:text-5xl  md:p-2 py-2 bg-inherit border-2 border-primary text-primary  rounded-lg !px-0 "
                                />
                            )}
                        />
                    </div>
                    <div className="flex flex-col  w-full gap-y-4">
                        <button
                            onClick={handleSubmit}
                            disabled={resend || otp.toString().length != 6}
                            className={`bg-primary text-white uppercase font-medium py-4  duration-300 rounded flex items-center justify-center gap-x-2 ${
                                (otp.toString().length != 6 || resend) && "cursor-not-allowed !bg-opacity-30"
                            }`}
                        >
                            Submit
                        </button>
                        <div className=" w-full flex justify-between">
                            <button
                                onClick={handleLogout}
                                className="font-bold flex items-center gap-x-1 text-error text-sm"
                            >
                                <span className="text-xl">
                                    <IoLogOut />
                                </span>

                                <span> Log Out</span>
                            </button>
                            {resend && (
                                <button onClick={handleResend} className="font-bold text-primary text-sm">
                                    Resend OTP
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EmailVerificationUI;

import React from "react";
import { statusColorCheck } from "../../../utils/statusColor";
import { Link } from "react-router-dom";

const Filter = ({ data, currentStatus, statusUpdate, refetch }: any) => {
    return (
        <div className="w-full px-6 border-b-2 dark:border-white/10 flex gap-x-6">
            {Object.keys(data).map((item, index) => (
                <Link
                    to={`?status=${item}`}
                    onClick={() => {
                        refetch();
                        statusUpdate(item);
                    }}
                    key={index}
                    className={`flex items-center gap-x-2 ${
                        currentStatus === item && "border-b-2 mb-[-2px]"
                    } pt-5 pb-3 w-fit border-dark dark:border-white`}
                >
                    <h1
                        className={`text-base capitalize w-fit ${
                            currentStatus === item ? "font-bold whitespace-nowrap" : "whitespace-nowrap"
                        }`}
                    >
                        {item}
                    </h1>

                    <div
                        className={`p-1 px-2 bg-dark ${statusColorCheck(item)} ${
                            currentStatus === item && "!bg-opacity-100 "
                        } bg-opacity-50 font-bold text-white rounded-md h-fit w-fit text-xs`}
                    >
                        {data[item]}
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default Filter;

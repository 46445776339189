import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    fontSmall: {
        fontSize: 10,
        wordBreak: "break-all"
    },
    fontVerySmall: {
        fontSize: 6,
        wordBreak: "break-all"
    },
    icon: {
        width: 12,
        height: 12,
        objectFit: "fit"
    }
});

import React, { useState } from "react";

import RequestForm from "../EmployerForm";
import ResumeUpload from "../ResumeUpload";
import ResumeUploadForm from "../ResumeUploadForm";

const EmployerReferenceModal = ({ refetch, questionData }: any) => {
    const [upload, setUpload] = useState(true);
    const [file, setFile] = useState(null);

    return (
        <>
            <input type="checkbox" id="askReference" className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box rounded dark:bg-darkBG">
                    {upload ? (
                        file ? (
                            <ResumeUploadForm
                                setFile={setFile}
                                file={file}
                                refetch={refetch}
                                questionData={questionData}
                                setUpload={setUpload}
                            />
                        ) : (
                            <ResumeUpload setFile={setFile} setUpload={setUpload} />
                        )
                    ) : (
                        <RequestForm refetch={refetch} questionData={questionData} setUpload={setUpload} />
                    )}
                </div>
            </div>
        </>
    );
};

export default EmployerReferenceModal;

export const brandsData = [
    {
        id: 1,
        name: "Cataler",
        href: "https://www.cataler.co.jp/",
        image: "/images/brands/cataler.svg"
    },
    {
        id: 2,
        name: "Madras Engineering Industries",
        href: "https://madrasengineering.in",
        image: "/images/brands/mei.svg"
    },
    {
        id: 3,
        name: "Dgraph Labs",
        href: "https://dgraph.io/",
        image: "/images/brands/dgraph.svg"
    },
    {
        id: 4,
        name: "Liquidonate",
        href: "https://liquidonate.com/",
        image: "/images/brands/liquidonate.svg"
    },
    {
        id: 5,
        name: "Imversion",
        href: "https://imversion.com/",
        image: "/images/brands/imversion.svg"
    },
    {
        id: 6,
        name: "PlanetBio",
        href: "https://planet.bio",
        image: "/images/brands/planetbio.svg"
    },
    {
        id: 7,
        name: "OpenWeb",
        href: "https://www.openweb.com/",
        image: "/images/brands/openweb.svg"
    },
    {
        id: 8,
        name: "AnavahTalent",
        href: "https://www.anavahtalent.com/",
        image: "/images/brands/anavahtalent.svg"
    },
    {
        id: 9,
        name: "Trilitech",
        href: "https://www.trili.tech",
        image: "/images/brands/trilitech.svg"
    }
];

import React from "react";
import SingleChoiceComponent from "../Common/QuestionComponents/SingleChoiceComponent";
import MultipleChoiceComponent from "../Common/QuestionComponents/MultipleChoiceComponent";
import SingleBlockComponent from "../Common/QuestionComponents/SingleBlockComponent";

import MultipleBlockComponent from "../Common/QuestionComponents/MultipleBlockComponent";
import SingleSliderComponent from "../Common/QuestionComponents/SingleSliderComponent";

const ObjectiveComponents = ({ data, handleAnswerUpdate, questionObjective }: any) => {
    return (
        <div className="flex flex-col gap-y-3 ">
            {data.map(
                (item: any, index: number) =>
                    (item.type === "singleRegular" && (
                        <SingleChoiceComponent
                            key={item.objectId}
                            question={item}
                            handleAnswerUpdate={handleAnswerUpdate}
                            result={
                                questionObjective?.filter((question: any) => question.objectId === item.objectId)[0]
                                    ?.response
                            }
                        />
                    )) ||
                    (item.type === "singleBlock" && (
                        <SingleBlockComponent
                            key={item.objectId}
                            question={item}
                            handleAnswerUpdate={handleAnswerUpdate}
                            result={
                                questionObjective?.filter((question: any) => question.objectId === item.objectId)[0]
                                    ?.response
                            }
                        />
                    )) ||
                    (item.type === "singleSlider" && (
                        <SingleSliderComponent
                            key={item.objectId}
                            question={item}
                            handleAnswerUpdate={handleAnswerUpdate}
                            result={
                                questionObjective?.filter((question: any) => question.objectId === item.objectId)[0]
                                    ?.response
                            }
                        />
                    )) ||
                    (item.type === "multipleRegular" && (
                        <MultipleChoiceComponent
                            key={item.objectId}
                            question={item}
                            handleAnswerUpdate={handleAnswerUpdate}
                            result={
                                questionObjective?.filter((question: any) => question.objectId === item.objectId)[0]
                                    ?.response
                            }
                        />
                    )) ||
                    (item.type === "multipleBlock" && (
                        <MultipleBlockComponent
                            key={item.objectId}
                            question={item}
                            handleAnswerUpdate={handleAnswerUpdate}
                            result={
                                questionObjective?.filter((question: any) => question.objectId === item.objectId)[0]
                                    ?.response
                            }
                        />
                    ))
            )}
        </div>
    );
};

export default ObjectiveComponents;

import React, { useContext } from "react";
import UserSearchField from "./UserSearchField";
import { DATA_CONTEXT } from "../../../provider/DataProvider";

const AdminControl = () => {
    const { userListData } = useContext(DATA_CONTEXT) || {};
    return (
        <div className=" flex flex-col  gap-y-4 justify-center items-center p-4 rounded-md  dark:bg-darkSecondary pb-10 w-full dark:text-white">
            <h1 className="w-full text-lg font-semibold ">View As</h1>
            <div className="grid grid-cols-1  gap-4 w-full">
                <UserSearchField title="Select User" allUserData={userListData} />
            </div>
        </div>
    );
};

export default AdminControl;

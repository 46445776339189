import React from "react";

interface RadialProgressProps {
    value: number;
}

const RadialProgress: React.FC<RadialProgressProps> = ({ value }) => {
    let colorSelect = "";
    if (value >= 0 && value <= 33.33) {
        colorSelect = " text-error";
    } else if (value >= 33.34 && value <= 66.67) {
        colorSelect = "text-warning ";
    } else {
        colorSelect = " text-primary";
    }
    return (
        <div
            className={`radial-progress ${colorSelect} h-[2.4rem] sm:h-[2.8rem] w-[2.4rem] sm:w-[2.8rem] overflow-hidden`}
            style={{ "--value": 100, "--size": "2.8rem", "--thickness": ".3rem" } as any}
            role="progressbar"
        >
            <span className="flex flex-col">
                <p className="text-[10px] leading-tight border-b border-black dark:border-white text-black dark:text-white font-bold ">
                    {Math.round(value)}
                </p>
                <p className="text-[6px] leading-tight text-black dark:text-white">100</p>
            </span>
        </div>
    );
};

export default RadialProgress;

import React from "react";
import { View, Text } from "@react-pdf/renderer";

const MultipleRegularObjectivePDF = ({ data }: any) => {
    return (
        <View
            style={{
                backgroundColor: "#FAFAFA",
                padding: 10,
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                gap: 3
            }}
        >
            {Object.keys(data.options).map((key) => (
                <View key={key} style={{ display: "flex", flexDirection: "row", gap: "3", alignItems: "center" }}>
                    <View
                        style={{
                            width: 10,
                            height: 10,
                            padding: 4,
                            border: 1.5,
                            borderColor: data.response.includes(key) ? "#3B82F6" : "",
                            backgroundColor: data.response.includes(key) ? "#3B82F6" : "",
                            borderRadius: 2
                        }}
                    ></View>
                    <Text
                        style={{
                            fontSize: 10
                        }}
                    >
                        {data.options[key]}
                    </Text>
                </View>
            ))}
        </View>
    );
};

export default MultipleRegularObjectivePDF;

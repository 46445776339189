import React, { useState } from "react";
import CheckBlock from "./CheckBlock";
import AnalyticsSection from "../Analytics/AnalyticsSection";

const MultipleBlockComponent = ({
    question,
    result,
    analytics,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    result?: any;
    analytics?: {
        overall_score: number;
        reasoning: string;
    };
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [required, setRequired] = useState(result?.length > 0 ? false : true);
    return (
        <div className="overflow-hidden  shadow rounded-md">
            <div className="bg-white dark:bg-darkSecondary  px-6 py-4">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold">{question.question}</h2>
                <p className="text-error font-semibold mb-4">multiple choice*</p>
                <div className={`grid grid-cols-1 lg:grid-cols-5 gap-2`}>
                    {Object.keys(question.options).map((key) => (
                        <CheckBlock
                            question={question}
                            key={key}
                            value={key}
                            required={required}
                            setRequired={setRequired}
                            result={result?.length > 0 ? result : null}
                            handleAnswerUpdate={handleAnswerUpdate}
                            disabled={disabled}
                        />
                    ))}
                </div>

                {analytics && (
                    <div>
                        <AnalyticsSection analytics={analytics} defaultOpen />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MultipleBlockComponent;

import React, { useContext, useRef } from "react";
import logo from "../../assets/images/logo/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import CustomInputField from "../Common/CustomInputField/CustomInputField";
import { toast } from "react-toastify";
import { PARSE_CONTEXT } from "../../provider/Provider";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const ResetPasswordUI = ({ email, otp, setResetFlow }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const formRef = useRef<HTMLFormElement | null>(null);
    const navigate = useNavigate();
    const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formRef.current) {
            const data = {
                password: formRef.current.password.value,
                confirmPassword: formRef.current.confirmPassword.value
            };
            if (data.password === data.confirmPassword) {
                try {
                    const result = await Parse.Cloud.run("ResetPassword", {
                        email: email,
                        passwordResetOTP: otp,
                        password: data.password
                    });

                    if (result.result) {
                        navigate("/login");
                        setResetFlow();
                        toast.success(result.message, {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    } else {
                        toast.error(result.message, {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        });
                    }
                } catch (error: any) {
                    // Handle errors
                    toast.error(error, {
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                }
            } else {
                toast.error("Password didn't matched!", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        }
    };
    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["resetPassword"] || SeoContent.baseSeo} />
            <div className="flex flex-col gap-y-6 items-start dark:text-white duration-300 w-[20rem] sm:w-[22rem] 2xl:w-[25rem]">
                <div className="flex justify-center items-center gap-x-1 w-full">
                    <img src={logo} alt="" width="45rem" />
                    <span className="text-3xl font-extrabold dark:text-white">HRHouz</span>
                </div>
                <div className="flex flex-col gap-y-3 items-center justify-center w-full">
                    <h1
                        className={`text-primary font-semibold text-xl flex justify-center border-primary pb-2 border-b-2`}
                    >
                        Reset Password
                    </h1>
                </div>

                <form ref={formRef} onSubmit={handleResetPassword} className="flex flex-col gap-y-4 w-full">
                    <CustomInputField type="password" name="password" required title="New Password" />
                    <CustomInputField type="password" name="confirmPassword" required title="Confirm Password" />

                    <button
                        type="submit"
                        className="bg-primary text-white uppercase font-medium py-4 hover:bg-primary  duration-300 rounded flex items-center justify-center gap-x-2"
                    >
                        Confirm
                    </button>
                    <div>
                        <p className="font-semibold text-black dark:text-white">
                            already have access?{" "}
                            <Link to="/login" className="text-primary">
                                Login
                            </Link>{" "}
                        </p>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default ResetPasswordUI;

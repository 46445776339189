import React from "react";
import image from "../../../assets/images/header/person.jpg";
import { ProfileComponent } from "../../Common/Table/UtilityComponent";
import { BsPeopleFill } from "react-icons/bs";
import { ImOffice } from "react-icons/im";
import FingerPrintInformation from "./FingerPrintInformation";
import AnalyticsSection from "../../Common/Analytics/AnalyticsSection";

const ResponseHeader = ({ data }: any) => {
    const {
        refereeFirstname,
        refereeLastname,
        refereeEmail,
        refereePhoneNumber,
        refereeImage,
        refereeRelationship,
        refereeWorkplace,
        refereeFingerPrint
    } = data;
    const video: any[] = [];

    const videoLinks = data.response.questionVideo;
    videoLinks.map((item: any, index: number) => video.push({ name: "#" + (index + 1), video: item.response }));

    const { geoLocation, geoLocationByIP, ipAddressV4, ipAddressV6, deviceInfo, deviceScreenSize, timezone } =
        refereeFingerPrint;

    const location = {
        city: geoLocation?.city || geoLocationByIP?.city || null,
        region: geoLocation?.region || geoLocationByIP?.region || null,
        country: geoLocation?.country || geoLocationByIP?.country || null
    };

    return (
        <div className="max-w-[50rem]  mx-auto my-8">
            <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-6 rounded-lg border  gap-4  ">
                <div className="md:col-span-3">
                    <h1 className="font-semibold pb-3">Referee Information</h1>
                    <ProfileComponent
                        image={refereeImage || image}
                        name={refereeFirstname + " " + refereeLastname}
                        email={refereeEmail}
                        phone={refereePhoneNumber}
                    />
                </div>

                <div className="flex flex-col gap-y-3">
                    <div>
                        <h2 className="flex items-center gap-x-2">
                            <span className="-mt-1 tooltip" data-tip="Relationship">
                                <BsPeopleFill />
                            </span>
                            <span className="capitalize text-sm">{refereeRelationship}</span>
                        </h2>
                    </div>
                    <div>
                        <h2 className="flex items-center gap-x-2">
                            <span className="-mt-1 tooltip" data-tip="Workplace">
                                <ImOffice />
                            </span>
                            <span className="capitalize text-sm">{refereeWorkplace}</span>
                        </h2>
                    </div>
                </div>
                <div className="md:col-span-2">
                    <FingerPrintInformation
                        location={location}
                        ipAddress={{ ipAddressV4, ipAddressV6 }}
                        deviceInfo={deviceInfo}
                        deviceScreenSize={deviceScreenSize}
                        timezone={timezone}
                    />
                </div>
            </div>
            {data.analytics && (
                <div className="w-full">
                    <AnalyticsSection
                        analytics={data.analytics}
                        video={(video as any[]).length > 0 ? video : null}
                        subjectiveQuestionCount={data?.response?.questionSubjective.length}
                        defaultOpen
                    />
                </div>
            )}
        </div>
    );
};

export default ResponseHeader;

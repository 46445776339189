export const getUserFingerPrint = async () => {
    const TIMEOUT_DURATION = 5000; // Timeout duration in milliseconds (adjust as needed)

    try {
        const timeout = (ms: number) =>
            new Promise<never>((_, reject) => setTimeout(() => reject(new Error("Timeout")), ms));
        const timeoutPromise = timeout(TIMEOUT_DURATION);
        const controller = new AbortController();
        const signal = controller.signal;
        // Fetch IPv6 address
        let ipAddressResponse = null;
        let ipAddressData = null;
        let ipAddress = null;
        try {
            ipAddressResponse = (await Promise.race([
                fetch("https://api64.ipify.org?format=json", { signal }),
                timeoutPromise
            ])) as Response;
            if (ipAddressResponse.ok) {
                ipAddressData = await ipAddressResponse.json();
                ipAddress = ipAddressData.ip || null;
            }
        } catch (error) {}

        // Fetch IPv4 address
        let ipAddressV4 = null;
        try {
            const ipAddressResponseV4 = (await Promise.race([
                fetch("https://api.ipify.org?format=json", { signal }),
                timeoutPromise
            ])) as Response;
            if (ipAddressResponseV4.ok) {
                const ipAddressDataV4 = await ipAddressResponseV4.json();
                ipAddressV4 = ipAddressDataV4.ip || null;
            }
        } catch (error) {}

        // Fetch IPv6 address using a different service
        let ipAddressV6 = null;
        try {
            const ipAddressResponseV6 = (await Promise.race([
                fetch("https://api6.ipify.org?format=json", { signal }),
                timeoutPromise
            ])) as Response;

            if (ipAddressResponseV6.ok) {
                const ipAddressDataV6 = await ipAddressResponseV6.json();
                ipAddressV6 = ipAddressDataV6.ip || null;
            }
        } catch (error) {}

        // Fetch other properties...
        const macAddress: string | null = null;
        let webBrowser: string | null = null;
        try {
            webBrowser = navigator.userAgent || null;
        } catch (error) {}

        let deviceInfo: string | null = null;
        try {
            deviceInfo = navigator.platform || null;
        } catch (error) {}

        let deviceScreenSize: string | null = null;
        try {
            deviceScreenSize = window.screen ? `${window.screen.width}x${window.screen.height}` : null;
        } catch (error) {}

        let language: string | null = null;
        try {
            language = window.navigator.language || null;
        } catch (error) {}

        let timezone: string | null = null;
        try {
            timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || null;
        } catch (error) {}

        let colorDepth: number | null = null;
        try {
            colorDepth = window.screen.colorDepth || null;
        } catch (error) {}

        let plugins: string[] | null = null;
        try {
            plugins = window.navigator.plugins
                ? Array.from(window.navigator.plugins)
                      .map((plugin) => plugin.name)
                      .sort()
                : null;
        } catch (error) {}

        let thirdPartyCookiesEnabled: boolean | null = null;
        try {
            thirdPartyCookiesEnabled = navigator.cookieEnabled || null;
        } catch (error) {}

        let connectionSpeed: string | null = null;
        try {
            connectionSpeed = (navigator as any).connection ? (navigator as any).connection.effectiveType : null;
        } catch (error) {}

        let operatingSystem: string | null = null;
        try {
            operatingSystem = window.navigator.platform || null;
        } catch (error) {}

        let isTouchSupported: boolean | null = null;
        try {
            isTouchSupported =
                "ontouchstart" in window ||
                navigator.maxTouchPoints > 0 ||
                (navigator as any).msMaxTouchPoints > 0 ||
                null;
        } catch (error) {}

        let geoLocation: {
            latitude?: number;
            longitude?: number;
            city?: string | null;
            region?: string | null;
            country?: string | null;
        } | null = null;
        try {
            geoLocation = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        });
                    },
                    (error) => {
                        resolve(null);
                    }
                );
            });
        } catch (error) {}

        // Handle geolocation error
        if (geoLocation && geoLocation.latitude && geoLocation.longitude) {
            try {
                const response = (await Promise.race([
                    fetch(
                        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${geoLocation.latitude}&lon=${geoLocation.longitude}`,
                        { signal }
                    ),
                    timeoutPromise
                ])) as Response;

                if (response.ok) {
                    const address = await response.json();
                    geoLocation.city = address?.address?.city || null;
                    geoLocation.region = address?.address?.state || null;
                    geoLocation.country = address?.address?.country || null;
                }
            } catch (error) {}
        }

        let geoLocationResponse: any | null = null;
        try {
            geoLocationResponse = (await Promise.race([
                fetch(`https://ipapi.co/${ipAddress}/json/`, { signal }),
                timeoutPromise
            ])) as Response;
        } catch (error) {}

        let geoLocationData: {
            city?: string | null;
            region?: string | null;
            country?: string | null;
            latitude?: any | null;
            longitude?: any | null;
        } | null = null;

        try {
            geoLocationData = await geoLocationResponse.json();
        } catch (error) {}

        return {
            ipAddress,
            ipAddressV4,
            ipAddressV6,
            macAddress,
            webBrowser,
            deviceInfo,
            deviceScreenSize,
            geoLocation,
            geoLocationByIP: geoLocationData,
            operatingSystem,
            language,
            timezone,
            colorDepth,
            plugins,
            thirdPartyCookiesEnabled,
            connectionSpeed,
            isTouchSupported
        };
    } catch (error) {
        return {
            ipAddress: null,
            ipAddressV4: null,
            ipAddressV6: null,
            macAddress: null,
            webBrowser: null,
            deviceInfo: null,
            deviceScreenSize: null,
            geoLocation: null,
            geoLocationByIP: null,
            operatingSystem: null,
            language: null,
            timezone: null,
            colorDepth: null,
            plugins: null,
            thirdPartyCookiesEnabled: null,
            connectionSpeed: null,
            isTouchSupported: null
        };
    }
};

import React from "react";
import showdown from "showdown";
import { BsCalendar2Check } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { JobsType } from "../../type/JobsType";
import { dateFormatter } from "../../utils/dateFormat";

const ViewJob = ({ item }: { item: JobsType }) => {
    const {
        createdAt,
        updatedAt,
        companyLogo,
        positionName,
        jobCategory,
        jobDetails,
        jobType,
        workMode,
        jobSummary,
        location,
        deadline
    } = item;
    const converter = new showdown.Converter();

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={item.objectId + "view"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box w-11/12 max-w-5xl rounded dark:bg-darkBG ">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <label htmlFor={item.objectId + "view"} className="px-2 py-2 block cursor-pointer">
                            <IoCloseOutline />
                        </label>
                    </div>

                    <div className="flex justify-center items-center">
                        <img src={companyLogo} alt="" className="w-[5rem]" />
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pt-6">
                        <div className="col-span-2">
                            <h1 className="font-bold">Position Name</h1>
                            <h1>{positionName}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Job Category</h1>
                            <h1>{jobCategory}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Job Type</h1>
                            <h1>{jobType}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Work Mode</h1>
                            <h1>{workMode}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Deadline</h1>
                            <h1>{dateFormatter(deadline)}</h1>
                        </div>
                        <div className="lg:col-span-2">
                            <h1 className="font-bold">Location</h1>
                            <h1>{location}</h1>
                        </div>
                        <div>
                            <h1 className="font-bold">Created At </h1>

                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(createdAt)}
                            </div>
                        </div>
                        <div>
                            <h1 className="font-bold">Last Updated </h1>

                            <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full w-fit px-4 dark:text-black">
                                <span className="text-lg">
                                    <BsCalendar2Check />
                                </span>
                                {dateFormatter(updatedAt)}
                            </div>
                        </div>

                        <div className="col-span-2">
                            <h1 className="font-bold">Job Details</h1>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: converter.makeHtml(jobDetails)
                                }}
                            />
                        </div>
                        <div className="col-span-2">
                            <h1 className="font-bold">Job Summary</h1>
                            <p>{jobSummary}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewJob;

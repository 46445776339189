import React from "react";
const Loading = ({ title, full }: { title?: boolean; full?: boolean }) => {
    return (
        <div
            className={`flex ${title ? "min-h-[30rem] rounded-lg" : "min-h-[82vh]"} ${
                full && "!min-h-[100vh]"
            } w-full flex-col items-center justify-center gap-y-3 overflow-y-auto  dark:bg-dark p-6`}
        >
            <div className="flex gap-x-6">
                <span className="loading-xs h-2  rounded-full border-2 border-black bg-black dark:border-white dark:bg-white"></span>
                <span className="loading-sm  h-[.65rem]  rounded-full border-2 border-black bg-black dark:border-white dark:bg-white"></span>
                <span className="loading-md h-[.75rem] rounded-full border-2 border-black bg-black dark:border-white dark:bg-white"></span>
                <span className="loading-lg  h-[.85rem] rounded-full border-2 border-black bg-black dark:border-white dark:bg-white"></span>
            </div>
            {title && (
                <div className="text-center">
                    <h2 className="font-semibold pt-2">Processing </h2>
                    <p className="text-xs">don't close this tab</p>
                    <p className="text-xs">typically takes 5s-30s</p>
                </div>
            )}
        </div>
    );
};

export default Loading;

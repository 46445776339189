import React from "react";
import { TypeAnimation } from "react-type-animation";
import Testimonial from "./Testimonial";
import Brands from "./Brands";

const Marketing = ({ testimonials, brands }: any) => {
    const dynamicTexts = ["Easy", 1000, "Fast", 1000, "with ❤️", 1000];
    return (
        <section className="h-full bgImage ">
            <div className="backgroundLinearDark h-full lg:pt-8 2xl:pt-28 flex justify-between xl:justify-center flex-col">
                <div>
                    <div className="w-[40rem] mx-auto pb-6">
                        <h2 className="text-[2rem] font-bold text-white text-center   flex flex-col">
                            <span>Reference Checks</span>

                            <span className=" text-[1.5rem]">
                                <span>Made</span>{" "}
                                <TypeAnimation
                                    sequence={dynamicTexts}
                                    wrapper="span"
                                    speed={5}
                                    className="text-primary"
                                    repeat={Infinity}
                                />
                            </span>
                        </h2>
                    </div>
                    <div className="flex justify-center pb-6 2xl:pb-10 mb-2">
                        <a
                            target="__blank"
                            href="https://www.hrhouz.com/demo"
                            className="text-white font-bold bg-primary px-6 py-3 rounded"
                        >
                            Request a Demo
                        </a>
                    </div>

                    <div className="pb-6 2xl:pb-20">
                        <Testimonial testimonials={testimonials} />
                    </div>
                </div>
                <Brands brands={brands} />
            </div>
        </section>
    );
};

export default Marketing;

import React from "react";
import logo from "../../assets/images/pdf/logo.png";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import ProfileInformationPDF from "./ProfileInformationPDF";
import { dateFormatter } from "../../utils/dateFormat";
import SubjectiveQuestionPDF from "./SubjectiveQuestionPDF";
import ObjectiveQuestionPDF from "./ObjectiveQuestionPDF";
import AnalyticsPDF from "./AnalyticsPDF";
import PDFFooter from "./PDFFooter";

const PDFGenerator = ({ data }: any) => {
    const {
        requestorFirstname,
        requestorLastname,
        requestorEmail,
        candidateFirstname,
        candidateLastname,
        candidateEmail,
        candidatePhoneNumber,
        candidateFingerPrint,
        createdAt,
        status,
        analytics
    } = data;

    return (
        <Document>
            <Page
                size="A4"
                style={{
                    paddingTop: 35,
                    paddingBottom: 35,
                    paddingHorizontal: 15,
                    position: "relative"
                }}
            >
                <Text
                    style={{
                        position: "absolute",
                        fontSize: 9,
                        top: 10,
                        left: 45,
                        right: 0,
                        textAlign: "left",
                        color: "grey"
                    }}
                    fixed
                >
                    Initiated By {requestorFirstname + " " + requestorLastname + " (" + requestorEmail + " )"}
                </Text>

                <Image src={logo} style={{ width: 150, marginHorizontal: "auto", marginBottom: 20 }} />
                <Text
                    style={{
                        fontSize: 16,
                        textAlign: "center",
                        width: "100%",
                        margin: 6,
                        fontWeight: "bold"
                    }}
                >
                    Reference Results
                </Text>

                <ProfileInformationPDF
                    userInfo={{
                        heading: "Candidate Information",
                        name: candidateFirstname + " " + candidateLastname,
                        email: candidateEmail,
                        phone: candidatePhoneNumber
                    }}
                    otherInformation={{ createdDate: dateFormatter(createdAt), status: status }}
                    profileLocation={candidateFingerPrint}
                />
                {analytics && (
                    <AnalyticsPDF
                        analytics={analytics}
                        refereeCount={data?.response.length}
                        subjectiveQuestionLength={data?.response[0]?.questionSubjective?.length || null}
                    />
                )}

                {data?.response?.map((item: any, index: number) => (
                    <View key={index}>
                        <Text
                            style={{
                                textAlign: "center",
                                borderBottom: 2,
                                fontSize: 12,
                                width: 65,
                                marginHorizontal: "auto",
                                marginTop: 20,
                                marginBottom: 10
                            }}
                        >
                            Referee #{index + 1}
                        </Text>
                        <ProfileInformationPDF
                            userInfo={{
                                heading: "Referee Information",
                                name: item.refereeFirstname + " " + item.refereeLastname,
                                email: item.refereeEmail,
                                phone: item.refereePhoneNumber
                            }}
                            otherInformation={{ group: item.refereeRelationship, office: item.refereeWorkplace }}
                            profileLocation={item.refereeFingerPrint}
                        />
                        {item?.analytics && (
                            <AnalyticsPDF
                                analytics={item.analytics}
                                refereeCount={data.length}
                                subjectiveQuestionLength={item?.questionSubjective?.length}
                            />
                        )}
                        <View style={{ display: "flex", flexDirection: "column", gap: 16, marginTop: 8 }}>
                            {item?.response.questionObjective.map((subjective: any, index: number) => (
                                <ObjectiveQuestionPDF key={index} data={subjective} />
                            ))}
                        </View>
                        <View style={{ display: "flex", flexDirection: "column", gap: 16, marginTop: 8 }}>
                            {item?.response.questionSubjective.map((subjective: any, index: number) => (
                                <SubjectiveQuestionPDF key={index} data={subjective} />
                            ))}
                        </View>
                    </View>
                ))}

                <Text
                    style={{
                        position: "absolute",
                        fontSize: 12,
                        bottom: 10,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        color: "grey"
                    }}
                    render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    fixed
                />
                <PDFFooter data={data} />
            </Page>
        </Document>
    );
};

export default PDFGenerator;

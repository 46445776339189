import React from "react";
import check from "../../../assets/images/pdf/check.png";
import warning from "../../../assets/images/pdf/warning.png";
import { View, Text, Image } from "@react-pdf/renderer";

const FingerPrintPDF = ({ data }: { data: { name: string; resultBool: boolean }[] }) => {
    return (
        <View style={{ display: "flex", flexDirection: "column", gap: "3", width: "100%" }}>
            <Text style={{ fontSize: 14, fontWeight: "demibold", marginTop: 5 }}>Fingerprints</Text>

            <View style={{ display: "flex", flexDirection: "column", gap: 3, marginTop: 10 }}>
                {data.map((item, index) => (
                    <View key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "2" }}>
                        <View
                            style={{
                                height: 12,
                                width: 12,
                                backgroundColor: item.resultBool ? "#00A76F" : "#D32F2F",
                                borderRadius: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Image src={item.resultBool ? check : warning} style={{ width: 8 }}></Image>
                        </View>
                        <Text style={{ fontSize: 10 }}>{item.name}</Text>
                    </View>
                ))}
            </View>
        </View>
    );
};

export default FingerPrintPDF;

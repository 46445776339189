import React from "react";
import profileImage from "../../assets/images/header/person.jpg";
import emailLogo from "../../assets/images/pdf/email.png";
import phoneLogo from "../../assets/images/pdf/phone.png";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "./PDFStyle";

const UserProfile = ({
    heading,
    name,
    phone,
    email
}: {
    heading: string;
    name: string;
    phone: string;
    email: string;
}) => {
    return (
        <View style={{ width: "40%" }}>
            <Text style={styles.fontSmall}>{heading}</Text>
            <View style={{ display: "flex", flexDirection: "row", gap: 4, marginTop: 10, alignItems: "center" }}>
                <Image
                    src={profileImage}
                    style={{
                        width: 30,
                        height: 30,
                        borderRadius: "100%"
                    }}
                />
                <View style={{ display: "flex", flexDirection: "column", gap: 3 }}>
                    <Text style={{ ...styles.fontSmall, maxWidth: 140 }}>{name}</Text>

                    <View style={{ display: "flex", flexDirection: "row", gap: 2, opacity: 0.8 }}>
                        <Image src={emailLogo} style={styles.icon} />
                        <Text style={{ ...styles.fontSmall, maxWidth: 140 }}>{email}</Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", opacity: 0.8 }}>
                        <Image src={phoneLogo} style={styles.icon} />
                        <Text style={{ ...styles.fontSmall, maxWidth: 140 }}>{phone}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default UserProfile;

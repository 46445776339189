import React, { useContext, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { PARSE_CONTEXT } from "../../../provider/Provider";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import UserSearchField from "../../AdminUserSwitchModal/AdminControl/UserSearchField";
import { updateData } from "../../../hooks/update";
import { BiSolidPencil } from "react-icons/bi";
import PhotoUploader from "../../Common/PhotoUploader/PhotoUploader";
import { convertToHttps } from "../../../utils/convertUrl";

const UpdateOrganization = ({ refetch, item }: any) => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { userListData } = useContext(DATA_CONTEXT) || {};
    const [loading, setLoading] = useState(false);
    const [imageURL, setImageURL] = useState<null | string>(item.attributes.organizationImage);
    const [adminEdit, setAdminEdit] = useState(false);
    const [organizationAdmin, setOrganizationAdmin] = useState(item.attributes.organizationAdminEmail);

    const formRef = useRef<HTMLFormElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>(`[for="${item.id + "update"}"]`);
        if (form && label) {
            form.reset();
            label.click();
        }
        setAdminEdit(false);
    };
    const handleLabelClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (file) {
            const parseFile = new Parse.File(file.name, file);
            try {
                await parseFile.save();
                setImageURL(convertToHttps(parseFile.url()));
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setLoading(true);
        const form = formRef.current;
        if (form) {
            const data = {
                organizationImage: imageURL,
                organizationName: form.organizationName.value,
                organizationEmailDomain: form.organizationEmailDomain.value,
                organizationRoutePath: form.organizationRoutePath.value,
                organizationWebsite: form.organizationWebsite.value,
                organizationDescription: form.organizationDescription.value,
                organizationAddress: form.organizationAddress.value,
                organizationMapLocation: form.organizationMapLocation.value,
                organizationAdminEmail: organizationAdmin
            };

            updateData({
                Parse: Parse,
                parseItem: item,
                data: data,
                message: "Organization Updated!",
                refetch: refetch,
                form: form,
                closeModal: closeModal,
                loading: setLoading
            });
        }
    };
    return (
        <>
            <input type="checkbox" id={item.id + "update"} className="modal-toggle" />
            <div className="modal">
                <div className="modal-box rounded dark:bg-darkBG">
                    <div className="absolute top-0 right-0 bg-error  text-3xl text-white rounded-bl-lg">
                        <button type="button" onClick={closeModal} className="px-2 pt-1">
                            <IoCloseOutline />
                        </button>
                    </div>

                    <div>
                        <h1 className="font-medium text-xl pb-2">Update Organization.</h1>
                        <p>Please fill up the form and hit submit</p>
                    </div>

                    <form ref={formRef} onSubmit={handleSubmit}>
                        <div className="py-6 grid grid-cols-1 md:grid-cols-2 gap-4 ">
                            <PhotoUploader
                                fileInputRef={fileInputRef}
                                handleFileChange={handleFileChange}
                                handleLabelClick={handleLabelClick}
                                photoUrl={imageURL}
                            />
                            <CustomInputField
                                name="organizationName"
                                title="Name"
                                type="text"
                                required
                                value={item.attributes.organizationName}
                            />
                            <CustomInputField
                                name="organizationEmailDomain"
                                title="Email Domain"
                                type="text"
                                value={item.attributes.organizationEmailDomain}
                                required
                            />
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationRoutePath"
                                    title="Route Path"
                                    type="text"
                                    value={item.attributes.organizationRoutePath}
                                    required
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationDescription"
                                    title="Organization Description"
                                    type="url"
                                    textArea
                                    value={item.attributes.organizationDescription}
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationWebsite"
                                    title="Website"
                                    type="url"
                                    value={item.attributes.organizationWebsite}
                                />
                            </div>

                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationAddress"
                                    title="Address"
                                    value={item.attributes.organizationAddress}
                                    type="text"
                                />
                            </div>
                            <div className="md:col-span-2">
                                <CustomInputField
                                    name="organizationMapLocation"
                                    title="Map Location"
                                    value={item.attributes.organizationMapLocation}
                                    type="url"
                                />
                            </div>
                            <div className="md:col-span-2 relative  flex items-center">
                                {adminEdit ? (
                                    <UserSearchField
                                        title="Admin"
                                        allUserData={userListData}
                                        setValue={setOrganizationAdmin}
                                    />
                                ) : (
                                    <CustomInputField
                                        name="organizationAdminEmail"
                                        title="Admin"
                                        value={item.attributes.organizationAdminEmail}
                                        type="text"
                                    />
                                )}

                                <button
                                    type="button"
                                    onClick={() => setAdminEdit(!adminEdit)}
                                    className="absolute right-0 mt-3 bg-inherit px-3 text-lg"
                                >
                                    {adminEdit ? "x" : <BiSolidPencil />}
                                </button>
                            </div>
                        </div>

                        <div className="flex justify-end items-center gap-4">
                            <button
                                type="submit"
                                className="btn bg-primary text-white hover:bg-success border-0 flex justify-center items-center gap-x-2"
                            >
                                {loading && <span className="loading loading-spinner"></span>}
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UpdateOrganization;

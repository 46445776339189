import { toast } from "react-toastify";

interface data {
    firstname: string;
    lastname: string;
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    phoneNumber: string | undefined;
    createdAtFingerPrint: any;
}

interface NavigationFunction {
    (path: string): void;
}

export const signUp = async ({
    Parse,
    data,
    reFetch,
    navigate,
    loading
}: {
    Parse: any;
    data: data;
    reFetch: () => void;
    navigate: NavigationFunction;
    loading: any;
}): Promise<boolean> => {
    if (data.password !== data.confirmPassword) {
        toast.error("Password and Confirm Password Don't Match!");
        return false;
    } else {
        const user = new Parse.User();
        user.set("firstname", data.firstname);
        user.set("lastname", data.lastname);
        user.set("username", data.username);
        user.set("password", data.password);
        user.set("email", data.email);
        user.set("phoneNumber", data.phoneNumber);
        user.set("createdAtFingerPrint", data.createdAtFingerPrint);

        try {
            await user.signUp();
            toast.success("Welcome!");
            reFetch();
            navigate("/");
            loading(false);
            return true;
        } catch (error) {
            toast.error("Account Already Exists");
            loading(false);
            return false;
        }
    }
};

import React, { useEffect, useState } from "react";
import AnalyticsSection from "../Analytics/AnalyticsSection";

const SingleBlockComponent = ({
    question,
    result,
    analytics,
    handleAnswerUpdate,
    disabled
}: {
    question: any;
    result?: any;
    analytics?: {
        overall_score: number;
        reasoning: string;
    };
    handleAnswerUpdate?: any;
    disabled?: boolean;
}) => {
    const [select, setSelect] = useState({ key: result ? result : "", value: "" });

    useEffect(() => {
        if (result !== undefined) {
            setSelect((prevSelect) => ({ ...prevSelect, key: result }));
        }
    }, [result]);
    const handleCheckboxChange = async (e: any, key: string) => {
        setSelect((prevSelect) => ({ ...prevSelect, key: key, value: e.target.checked }));
        if (handleAnswerUpdate) {
            await handleAnswerUpdate();
        }
    };
    return (
        <div className="overflow-hidden  shadow rounded-md">
            <div className=" bg-white dark:bg-darkSecondary  px-6 py-4 ">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold ">{question.question}</h2>
                <p className="text-error font-semibold mb-4">single choice*</p>

                <div className={`grid grid-cols-1 lg:grid-cols-5 gap-2`}>
                    {Object.keys(question.options).map((key) => (
                        <label
                            key={key}
                            className={` rounded-md w-full bg-gray-100 dark:bg-gray-100/20 overflow-hidden flex items-center justify-center text-center  ${
                                select.key === key && "!bg-primary text-white"
                            } ${!disabled ? "cursor-pointer" : "cursor-not-allowed"} capitalize p-2`}
                        >
                            <input
                                type="radio"
                                name={question.objectId}
                                defaultChecked={key === select.key}
                                value={select.value ? select.value : key}
                                required
                                className="hidden "
                                disabled={disabled}
                                onChange={(e) => {
                                    handleCheckboxChange(e, key);
                                }}
                            />

                            <span
                                className={`text-sm lg:text-base ${!disabled ? "cursor-pointer" : "cursor-not-allowed"}`}
                            >
                                {question.options[key]}
                            </span>
                        </label>
                    ))}
                </div>
                {analytics && (
                    <div>
                        <AnalyticsSection analytics={analytics} defaultOpen />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SingleBlockComponent;

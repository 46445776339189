import React from "react";

import SupportForm from "../SupportForm";

const NewSupportModal = ({ refetch }: any) => {
    return (
        <>
            <input type="checkbox" id="newTicket" className="modal-toggle " />
            <div className="modal ">
                <div className="modal-box w-11/12 max-w-5xl rounded dark:bg-darkBG">
                    <SupportForm refetch={refetch} />
                </div>
            </div>
        </>
    );
};

export default NewSupportModal;

import React, { useContext, useState } from "react";
import logo from "../../assets/images/logo/logo.svg";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import CountDownComponent from "../Common/CountDownComponent/CountDownComponent";
import { toast } from "react-toastify";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { SeoContent } from "../../content/seoContent";
import HelmetConfiguration from "../../utils/helmetConfiguration";

const VerifyPasswordResetOTPUI = ({
    email,
    otp,
    setOtp,
    setResetFlow,
    handleResetPassword,
    passwordChange,
    otpReset,
    setOtpVerified
}: any) => {
    const [resend, setResend] = useState(false);
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            const result = await Parse.Cloud.run("VerifyPasswordResetOTP", {
                email: email,
                passwordResetOTP: otp
            });

            if (result.result) {
                if (setResetFlow) {
                    setResetFlow("reset");
                }
                if (setOtpVerified) {
                    setOtpVerified(true);
                }
                if (!passwordChange) {
                    navigate("/forgot-password?flow=reset");
                }
                toast.success(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            } else {
                toast.error(result.message, {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined
                });
            }
        } catch (error: any) {
            // Handle errors
            toast.error(error, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    };

    const handleResend = () => {
        setResend(false);
        if (handleResetPassword) {
            handleResetPassword();
        }
    };

    return (
        <section>
            {!passwordChange && <HelmetConfiguration seoData={SeoContent["OTPVerification"] || SeoContent.baseSeo} />}
            <div className="text-center flex flex-col gap-y-6 items-center dark:text-white duration-300">
                <div className="flex justify-center items-center gap-x-1 w-full">
                    <img src={logo} alt="" width="45rem" />
                    <span className="text-3xl font-extrabold dark:text-white">HRHouz</span>
                </div>
                <div className="flex flex-col gap-y-3 items-center justify-center w-full">
                    <h1
                        className={`text-primary font-semibold text-xl flex justify-center border-primary pb-2 border-b-2`}
                    >
                        OTP Verification
                    </h1>
                    <p className="text-base  text-black dark:text-white font-semibold text-center">
                        OTP sent to <span className="text-primary">{email}</span>
                    </p>
                </div>

                <CountDownComponent resend={resend} setResend={setResend} otpReset={otpReset} />

                <div className="w-full flex justify-center">
                    <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputType="text"
                        renderSeparator={<span className="md:text-2xl px-1 ">-</span>}
                        renderInput={(props) => (
                            <input
                                {...props}
                                className="text-4xl xl:text-5xl  md:p-2 py-2 bg-inherit border-2 border-primary text-primary  rounded-lg !px-0 "
                            />
                        )}
                    />
                </div>
                <div className="flex flex-col w-full gap-y-4">
                    <button
                        onClick={handleSubmit}
                        disabled={resend || otp.toString().length != 6}
                        className={`bg-primary text-white uppercase font-medium py-4  duration-300 rounded flex items-center justify-center gap-x-2 ${
                            (otp.toString().length != 6 || resend) && "cursor-not-allowed !bg-opacity-30"
                        }`}
                    >
                        Submit
                    </button>

                    <div className="flex justify-between items-center w-full text-sm">
                        {!passwordChange && (
                            <button
                                onClick={() => {
                                    setResetFlow();
                                    navigate("/forgot-password");
                                }}
                                className="font-bold "
                            >
                                Re-Enter Email
                            </button>
                        )}
                        {resend && (
                            <button onClick={handleResend} className="font-bold text-primary text-sm">
                                Resend OTP
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VerifyPasswordResetOTPUI;

import { toast } from "react-toastify";

export const createData = ({ Parse, dataClass, data, message, refetch, closeModal, loading }: any) => {
    const dataKeys = Object.keys(data);
    const Data = Parse.Object.extend(dataClass);
    const createData = new Data();
    dataKeys.map((item) => createData.set(item, data[item]));

    createData.save().then(
        () => {
            if (closeModal) {
                closeModal();
            }

            if (refetch) {
                refetch.map((item: () => void) => item());
            }
            if (loading) {
                loading(false);
            }

            toast.success(message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        },
        (error: any) => {
            if (loading) {
                loading(false);
            }

            toast.error(error.message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
            });
        }
    );
};

import React, { useContext } from "react";

import { SIDEBAR_CONTEXT } from "../../../provider/SideBarProvider";

const TableLayer = ({ children }: { children: React.ReactNode }) => {
    const { sideBarOpen } = useContext(SIDEBAR_CONTEXT) || {};

    return (
        <div
            className={`w-[82vw] mx-auto  ${sideBarOpen ? "sm:w-[74vw]" : " sm:w-[88vw]"} ${
                sideBarOpen ? "md:w-[74vw]" : " md:w-[88vw]"
            }  ${sideBarOpen ? "lg:w-[72vw]" : " lg:w-[88vw]"} ${sideBarOpen ? "xl:w-[79vw]" : " xl:w-[89vw]"} ${
                sideBarOpen ? "2xl:w-[80vw]" : " 2xl:w-[90vw]"
            }`}
        >
            {children}
        </div>
    );
};

export default TableLayer;

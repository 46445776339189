import { View, Text } from "@react-pdf/renderer";
import React from "react";

const WordCountPDF = ({
    wordCount,
    questions,
    refereeCount
}: {
    wordCount: number;
    questions: number;
    refereeCount: number;
}) => {
    const colorSelect = ({
        wordCount,
        questionCount = 1,
        refereeCount = 1
    }: {
        wordCount: number;
        questionCount?: number;
        refereeCount?: number;
    }) => {
        if (wordCount >= 0 * questionCount * refereeCount && wordCount <= 30 * questionCount * refereeCount) {
            return "#D32F2F";
        } else if (wordCount >= 31 * questionCount * refereeCount && wordCount <= 50 * questionCount * refereeCount) {
            return "#FABD23";
        } else {
            return "#00A76F";
        }
    };

    return (
        <View
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center"
            }}
        >
            <View
                style={{
                    width: 35,
                    height: 35,
                    borderRadius: "100%",
                    border: 3,
                    borderColor: colorSelect({
                        wordCount: wordCount,
                        questionCount: questions,
                        refereeCount: refereeCount
                    }),
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    backgroundColor: colorSelect({
                        wordCount: wordCount,
                        questionCount: questions,
                        refereeCount: refereeCount
                    })
                }}
            >
                <Text style={{ color: "white", fontSize: 12, fontWeight: "bold", textAlign: "center" }}>
                    {wordCount}
                </Text>
            </View>
            <Text style={{ fontSize: 10 }}>words</Text>
        </View>
    );
};

export default WordCountPDF;

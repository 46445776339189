import { View, Text } from "@react-pdf/renderer";
import React from "react";
import AnalyticsPDF from "./AnalyticsPDF";

const SubjectiveQuestionPDF = ({ data }: any) => {
    return (
        <View style={{ display: "flex", flexDirection: "column" }}>
            <View
                style={{
                    padding: 10,
                    backgroundColor: "#F9FAFB",
                    borderRadius: 5,
                    width: "90%",
                    marginHorizontal: "auto"
                }}
            >
                <Text style={{ fontSize: 12 }}>{data.question}</Text>

                <View style={{ backgroundColor: "#FAFAFA", padding: 10, border: 0.5, marginTop: 10 }}>
                    <Text style={{ fontSize: 10 }}>{data.response}</Text>
                </View>

                <View style={{ marginTop: 3, display: "flex", flexDirection: "row" }}>
                    <Text style={{ fontSize: 8, color: "green" }}>{data.response.length}</Text>
                    <Text style={{ fontSize: 8 }}> / {data.maxCharacterLimit}</Text>
                </View>
            </View>
            {data?.analytics && <AnalyticsPDF analytics={data.analytics} />}
        </View>
    );
};

export default SubjectiveQuestionPDF;

import React, { useContext, useState } from "react";
import { LuRepeat2 } from "react-icons/lu";
import Swal from "sweetalert2";
import { PARSE_CONTEXT } from "../../provider/Provider";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";

const SchedulerSwitch = () => {
    const { Parse } = useContext(PARSE_CONTEXT) || {};
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    const [error, setError] = useState<String | null>(null);

    const handleScheduler = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            customClass: {
                popup: darkMode ? "swal-dark-mode" : "swal-light-mode",
                confirmButton: darkMode ? "swal-dark-mode-confirm" : "swal-light-mode-confirm",
                cancelButton: darkMode ? "swal-dark-mode-cancel" : "swal-light-mode-cancel"
            }
        }).then(async (swlResult) => {
            if (swlResult.isConfirmed) {
                try {
                    const result = await Parse.Cloud.run("MoveEmployerToCandidate");
                    if (!result.result) {
                        setError(result.message);
                    }
                } catch (error: any) {
                    setError(error);
                }
                try {
                    const result = await Parse.Cloud.run("MoveCandidateToReferee");
                    if (!result.result) {
                        setError(result.message);
                    }
                } catch (error: any) {
                    setError(error);
                }
                try {
                    const result = await Parse.Cloud.run("MoveRefereeToEmployer");
                    if (!result.result) {
                        setError(result.message);
                    }
                } catch (error: any) {
                    setError(error);
                }

                try {
                    const result = await Parse.Cloud.run("MoveEmployerAnalyzingToComplete");
                    if (!result.result) {
                        setError(result.message);
                    }
                } catch (error: any) {
                    setError(error);
                }

                Swal.fire({
                    title: error ? "Scheduler Failed!" : "Scheduler Success!",
                    icon: error ? "error" : "success",
                    showCloseButton: true,
                    confirmButtonColor: error ? "#D32F2F" : "#00A76F",
                    confirmButtonText: error ? "Try Again!" : "Ok",
                    text: error ? (error as string) : "",
                    customClass: {
                        popup: darkMode ? "swal-dark-mode" : "swal-light-mode"
                    }
                });
            }
        });
    };
    return (
        <button
            onClick={handleScheduler}
            className={`text-2xl w-10 h-10 flex justify-center items-center rounded-full bg-secondary/10 hover:bg-secondary/20 duration-300 dark:text-white text-secondary cursor-pointer`}
        >
            <span className="text-blue-500">
                <LuRepeat2 />
            </span>
        </button>
    );
};

export default SchedulerSwitch;

import React, { createContext } from "react";
import Parse from "parse";
import { ChildrenProps } from "../type/Types";

export const PARSE_CONTEXT = createContext<{ Parse: any } | undefined>(undefined);

const ParseProvider = ({ children }: ChildrenProps) => {
    // initialize
    Parse.initialize(
        process.env.REACT_APP_PARSE_SERVER_APPLICATION_ID || "",
        process.env.REACT_APP_PARSE_SERVER_JAVASCRIPT_KEY || ""
    );
    // serverURL
    Parse.serverURL = `${new URL("/parse", process.env.REACT_APP_BACKEND_URL)}` || "";
    const value = { Parse };

    return <PARSE_CONTEXT.Provider value={value}>{children}</PARSE_CONTEXT.Provider>;
};

export default ParseProvider;

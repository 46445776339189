import { View, Text } from "@react-pdf/renderer";
import React from "react";

const SingleBlockObjectivePDF = ({ data }: any) => {
    return (
        <View
            style={{
                backgroundColor: "#FAFAFA",
                padding: 10,
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                gap: 3
            }}
        >
            {Object.keys(data.options).map((key) => (
                <Text
                    key={key}
                    style={{
                        fontSize: 10,
                        width: "25%",
                        backgroundColor: key !== data.response ? "#e2e8f0" : "#00A76F",
                        color: key === data.response ? "white" : "",
                        borderRadius: 5,
                        padding: 3,
                        paddingVertical: 8,
                        textAlign: "center"
                    }}
                >
                    {data.options[key]}
                </Text>
            ))}
        </View>
    );
};

export default SingleBlockObjectivePDF;

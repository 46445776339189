import { useContext, useEffect, useState } from "react";
import { PARSE_CONTEXT } from "../provider/Provider";
import { RequestEmailVerificationOTP } from "../utils/RequestEmailVerificationOTP";

const useSession = () => {
    const parseContext = useContext(PARSE_CONTEXT);
    const { Parse } = parseContext || {};
    const [user, setUser] = useState<any | null>(null);
    const [refetchData, setRefetchData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getSession = async () => {
            try {
                const currentUser = await Parse.User.currentAsync();
                const userQuery = new Parse.Query(Parse.User);
                userQuery.equalTo("objectId", currentUser?.id);
                const user = await userQuery.first();
                if (user && !user?.attributes?.emailVerified) {
                    await RequestEmailVerificationOTP({ Parse: Parse, email: user?.attributes?.email });
                }
                setUser(user);
                setLoading(false);
            } catch (error) {
                try {
                    await Parse.User.logOut();
                } catch (error) {}
                setUser(null);
                setLoading(false);
            }
        };
        getSession();
    }, [refetchData]);

    const refetch = () => setRefetchData((prevState) => !prevState);

    return [user, setUser, loading, refetch];
};

export default useSession;

import React from "react";

const VideoComponent = ({ responseVideo, data }: any) => {
    const { question } = data[0];
    return (
        <div className=" shadow rounded-md overflow-hidden mt-4">
            <div className="bg-white dark:bg-darkSecondary px-6 py-6 ">
                <h2 className="text-sm md:text-base lg:text-xl font-semibold pb-4  ">{question}</h2>
                <div className="flex justify-center gap-x-4 items-center">
                    {responseVideo.length > 0 && (
                        <div className=" bg-primary text-white  px-3 py-2 lg:px-5 lg:py-3 rounded-lg font-semibold text-xs md:text-base">
                            Recording Completed
                        </div>
                    )}

                    <label
                        htmlFor="videoModal"
                        className=" bg-white dark:bg-dark border border-dark dark:border-white text-dark dark:text-white px-3 py-2 lg:px-5 lg:py-3 rounded-lg font-semibold text-xs md:text-base text-center cursor-pointer"
                    >
                        {responseVideo.length > 0 ? "Preview" : "Click To Record Video"}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default VideoComponent;
